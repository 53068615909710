// =============================================================================
// Action Bar
// =============================================================================
/*
   The top bar that appears on some sections of the site. Used to display section
   headings, breadcrumbs, and (usually) a button or search input.
*/

.action-bar {
  position: relative;
  z-index: 1;
}

.action-bar__wrapper {
  background: $color-grey-4;
  height: 74px;
  margin-top: $spacing-xxs;
  display: flex;
  align-content: center;
  position: relative;

  @include mq(medium) {
    margin-top: $spacing-xs;
  }
}

.action-bar__inner {
  max-width: $site-max-width-inner;
  margin: auto;
  width: 85%;
  display: flex;
  justify-content: space-between;

  @include mq(medium) {
    width: 90%;
  }
}

.action-bar__left {
  display: flex;
  align-content: center;
  align-items: center;
  height: 72px;
}

.action-bar__right {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-end;
  height: 72px;
  max-width: 33.333%;

  @include mq(medium) {
    width: 340px;
  }
}

.action-bar__heading {
  font-size: 18px;

  @include mq(large) {
    font-size: 21px;
  }

  @include mq(xxlarge) {
    font-size: 24px;
  }
}

.action-bar__search {
  width: 100%;
  max-width: 340px;
}

.action-bar__dropdown {
  background: $color-white;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0; 
  box-shadow: $shadow-bottom-only;
  z-index: -1; // should sit behind the parent action-bar container
  transform: translateY(-3rem);
  opacity: 0;
  pointer-events: none;
  transition: transform 0.5s $ease-in-out-expo, opacity 0.5s $ease-in-out-expo;
}

.action-bar__dropdown-content {
  max-width: $site-max-width-inner;
  margin: auto;
  width: 90%;
  padding: 4rem 0;
  opacity: 0;
  transition: opacity 0.5s $ease-in-out-expo 0.3s;
}


// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------

.is-active {

  &.action-bar__dropdown {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;

    .action-bar__dropdown-content {
      opacity: 1;
    }
  }
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.action-bar--partners {

  .action-bar__right {
    max-width: 40%;
  }

  @include mq($until: large) {
    .action-bar__inner {
      position: relative;
    }
  }

  @include mq($until: medium) {

    .action-bar__wrapper {
      height: auto;
    }

    .action-bar__inner {
      flex-wrap: wrap;
      justify-content: center;
      padding-top: $spacing-xs;
      padding-bottom: $spacing-xs;
    }

    .action-bar__left, 
    .action-bar__right {
      justify-content: center;
      max-width: 100%;
      width: 100%;
      height: auto;
    }

    .action-bar__right {
      margin-top: $spacing-xxs;
    }
  }
}
