@use 'sass:math';

// =============================================================================
// In the Field
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.l-itf {
  display: flex;
  flex-wrap: wrap;
  margin: $spacing-m 0 0;

  @include mq(large) {
    margin-top: $spacing-l;
  }
}

.l-itf__aside {
  width: 100%;
  margin-top: $spacing-s;
  padding: 0 10%;

  @include mq(large) {
    width: 40%;
    order: 1;
    margin-top: 0;
    padding: 0;
  }
}

.l-itf__main {
  width: 100%;

  @include mq(large) {
    width: 60%;
    margin-top: -0.4em; // optical alignment for aligning to sidebar content.
    order: 2;
  }
}

.l-itf__related {
  width: 100%;
  order: 3;
  background-color: $color-grey-4;
  margin-top: $spacing-l;
  padding: $spacing-m 0;
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -50vw;
    width: 200vw;
    background-color: $color-grey-4;
    z-index: -1;
  }

  @include mq(medium) {
    margin-top: $spacing-xl;
    padding: $spacing-l 0;
  }
}

.l-itf__related-heading {
  width: 100%;
  text-align: center;
  margin-bottom: $spacing-s;
}

.l-itf__related-posts {
  display: flex;
  flex-wrap: wrap;
}

.l-itf__related-post {
  width: 100%;

  @include mq($until: medium) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &:nth-of-type(n+2) {
      margin-top: $spacing-xs;
    }
  }

  @include mq($from: medium, $until: large) {
    width: calc(50% - #{$gutter-l - math.div($gutter-l, 2)});
    margin-left: $gutter-l;

    &:nth-of-type(odd) {
      margin-left: 0;
    }

    &:nth-of-type(n+3) {
      margin-top: $gutter-l;
    }
  }

  @include mq(large) {
    width: calc(33.333% - #{$gutter-l - math.div($gutter-l, 3)});
    margin-left: $gutter-l;

    &:nth-of-type(3n+1) {
      margin-left: 0;
    }

    &:nth-of-type(n+4) {
      margin-top: $gutter-xl;
    }
  }
}
