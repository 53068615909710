// =============================================================================
// Page Alert
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.page-alert {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 80px;
  width: 100vw;
  background: $gradient-blue-to-purple;
  color: transparent;
  text-align: center;
  z-index: $z-index-header;
  transform: translateY(80px);
  transition: transform $ease-out-expo 0.5s;
  display: flex;
  align-items: center;
  align-content: center;

  > * {
    text-align: center;
    width: 90%;
    margin: auto;
  }
}

// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------

.page-alert.is-visible {
  transform: translateY(0);
  color: $color-white;
}