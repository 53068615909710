// =============================================================================
// Card G
// =============================================================================
//
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.card-g {

  @include mq(medium) {
    &:hover {

      .card-g__img > img.lazyloaded {
        transform: scale(1.05);

        & + .card-g__img-shadow {
          opacity: 1;
          transform: scale(1.05) translateY(1.5%);
        }
      }

      .card-g__link {
        transform: none;
        opacity: 1;
      }
    }
  }
}

.card-g__img {
  position: relative;
  margin-bottom: 30px;

  > img {
    position: absolute;
    top: 0;
    width: 100%;
    transition: transform 0.5s $ease-out-expo, opacity 0.5s $ease-out-expo;
    opacity: 0;

    &.lazyloaded {
      opacity: 1;

      & + .card-g__img-shadow {
        opacity: 0.5;
      }
    }
  }
}

.card-g__img-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left:  0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-g__img-shadow {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 10px;
  box-shadow: $shadow-l;
  opacity: 0;
  transition: transform 0.5s $ease-out-expo, opacity 0.5s $ease-out-expo;
  z-index: -1;
}

.card-g__body {
  margin-top: $spacing-xxxs;
}

.card-g__link {
  margin-top: $spacing-xxs;
  padding-bottom: 15px;

  @include mq(medium) {
    opacity: 0;
    transform: translateY(10px);
    transition: transform 0.5s $ease-out-expo, opacity 0.5s $ease-out-expo;
  }
}


// -----------------------------------------------------------------------------
// Meetup
// -----------------------------------------------------------------------------

.card-g--meetup {

  .card-g__link {
    margin-top: $spacing-xxxs;
  }
}