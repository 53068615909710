// =============================================================================
// Select Fields
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.select {
}

.select__label {
  display: block;
  @include eyebrow-l;
  color: $color-text-lighter;
  margin-bottom: $spacing-xxs;

  span {
    color: $color-red;
  }
}

.select__input {
  @include resetInputStyle;
  @include body-s;
  -webkit-appearance: none; 
  -moz-appearance: none;
  appearance: none; // remove arrow    
  padding: 12px;
  padding-right: 50px;
  border-radius: 4px;
  color: $color-black;
  border: 1px solid $color-grey-2;
  background-color: $color-white;   
  background-image: url('../images/icon-select-arrow-down.svg');
  background-repeat: no-repeat;
  background-position: calc(95% - 20px) 20px;
  background-size: 14px 12px;
  transition: background-color $ease-out-expo 0.5s, border $ease-out-expo 0.5s;
  cursor: pointer;

  &::-ms-expand {
    display: none; // hide the default arrow in ie10 and ie11
  }

  &:hover {
    background-color: $color-grey-4;
    border-color: $color-grey-4;
  }

  @include mq(medium) {
    min-width: 420px;
    padding: 16px;
    padding-right: 50px;
    background-position: 96% 24px;
    background-size: 16px 12px;
  }
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.select--s {

  .select__input {
    @include body-xs;
    min-width: 200px;
    padding: 12px 40px 12px 14px;
    background-repeat: no-repeat;
    background-position: calc(100% - 18px) 18px;
    background-size: 15px 15px;
  }
}


// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------

.select__label {

  &.is-hidden {
    @include hide-visually;
  }
}
