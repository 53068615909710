@use 'sass:math';

// =============================================================================
// About Page Layout
// =============================================================================


.l-about {}

.l-about__row {
  margin: $spacing-m 0;
  display: flex;
  flex-wrap: wrap;

  @include mq(aboutHeroL) {
    margin: $spacing-l 0;
  }

  @include mq(xlarge) {
    margin: $spacing-xl 0;
  }

  @include mq(xxlarge) {
    margin: $spacing-xxl 0;
  }
}

.l-about__half {
  width: 100%;

  @include mq($until: aboutGridL) {

    &:nth-of-type(n+2) {
      margin-top: $spacing-s;
    }
  }

  @include mq(aboutGridL) {
    width: calc(50% - #{$gutter-about - math.div($gutter-about, 2)});
    margin-left: $gutter-about;

    &:nth-of-type(odd) {
      margin-left: 0;
    }
  }
}

.l-about__third {
  width: 100%;

  @include mq($until: aboutGridL) {

    &:nth-of-type(n+2) {
      margin-top: $spacing-s;
    }
  }

  @include mq($from: aboutGridS, $until: aboutGridL) {
    width: calc(50% - #{$gutter-about - math.div($gutter-about, 2)});
    margin-left: $gutter-about;

    &:nth-of-type(odd) {
      margin-left: 0;
    }

    &:nth-of-type(n+3) {
      margin-top: $spacing-s;
    }
  }

  @include mq(aboutGridL) {
    width: calc(33.333% - #{$gutter-team-grid - math.div($gutter-team-grid, 3)});
    margin-left: $gutter-team-grid;

    &:nth-of-type(3n+1) {
      margin-left: 0;
    }

    &:nth-of-type(n+4) {
      margin-top: $spacing-s;
    }
  }
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.l-about__row--team {
  margin-top: $spacing-s;

  @include mq(xlarge) {
    margin-top: $spacing-m;
  }
}


.l-about__row--values {
  margin-top: $spacing-m;

  @include mq(xlarge) {
    margin-top: $spacing-m;
  }

  @include mq(xxlarge) {
    margin-top: $spacing-l;
  }
}
