// =============================================================================
// Alert
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.alert {
  padding: $spacing-xs;
  margin-top: $spacing-xs;
}

.alert__heading {
  @include heading-xs;
  margin-bottom: $spacing-xxxs;
}

.alert__body {
  @include body-xs;
}


// -----------------------------------------------------------------------------
// Success
// -----------------------------------------------------------------------------

.alert--success {
  background: linear-gradient(to bottom, rgba($color-success, 0.1) 0%, rgba($color-success, 0.15) 100%);
  border: 1px solid rgba($color-success, 0.3);

  .alert__heading {
    color: $color-success;
  }
}


.alert--error {
  background: linear-gradient(to bottom, rgba($color-error, 0.01) 0%, rgba($color-error, 0.05) 100%);
  border: 1px solid rgba($color-error, 0.5);

  .alert__heading {
    color: $color-error;
  }
}