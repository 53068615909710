// =============================================================================
// Partner Header
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.partner-header {}

.partner-header__logo {
  height: 120px;
  width: 120px;
  border-radius: 100%;
  border: 1px solid $color-border-2;
  overflow: hidden;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  @include mq($until: medium) {
    height: 100px;
    width: 100px;
  }

  @include mq($until: partner2col) {
    margin: auto;
  }
}

.partner-header__name {
  margin-top: $spacing-xxs;
}

.partner-header__verifications {
  display: flex;
  margin-top: $spacing-xxs;

  @include mq($until: partner2col) {
    justify-content: center;
  }
}

.partner-header__verification {
  @include body-xxs;
  display: flex;
  align-items: center;
  color: $color-text-light;
  margin-right: $spacing-xxs;

  @include mq($until: medium) {
    margin-right: 0;

    & + .partner-header__verification {
      margin-left: $spacing-xxxs;
    }
  }
}

.partner-header__verification-badge {
  margin-right: 6px;
  height: 30px;
  width: 30px;

  @include mq($until: medium) {
    margin-right: 0;

    & + .partner-header__verification-badge {
      margin-left: 6px
    }
  }
}

.partner-header__verification-label {

  @include mq($until: medium) {
    display: none;
  }
}
