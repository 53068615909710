// =============================================================================
// Card Wrapper
// =============================================================================
//
// Gives the appearance of being in a "card" module
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.card-wrapper {
  padding: $spacing-xs;
  border: 1px solid $color-border-1;
  border-radius: 8px;
  background-color: $color-white;

  @include mq(medium) {
    padding: $spacing-s;
  }
}


// -----------------------------------------------------------------------------
// Forms in Cards
// -----------------------------------------------------------------------------

.card-wrapper--form {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-top: $spacing-s;

  .l-form__field:first-of-type {
    margin-top: 0;
  }
}