// =============================================================================
// Inline Icons
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.inline-icon {
  display: inline-block;
}


// -----------------------------------------------------------------------------
// Small
// -----------------------------------------------------------------------------

.inline-icon--s {
  max-width: 14px;
  height: auto;
}