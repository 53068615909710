@use 'sass:math';

$events-videos-slides-horizontal: $spacing-xxs;
$events-videos-slides-vertical: $spacing-xs;

$events-videos-grid-horizontal: $spacing-xxs;
$events-videos-grid-vertical: $spacing-xxs;

$events-presenters-horizontal: $spacing-xs;

// =============================================================================
// Events Main Layout
// =============================================================================

.l-events-index {}

.l-events-entry {}

// =============================================================================
// Events Pricing Layout
// =============================================================================

.l-events-pricing {}

.l-events-pricing__tiers {
  margin-top: $spacing-m;
}

.l-events-pricing-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  @include mq(medium) {
    justify-content: space-evenly;
  }
}

.l-events-pricing-row__col {
  text-align: center;
  margin-top: $spacing-xs;

  @include mq(medium) {
    margin-top: 0;
    width: calc(50% - #{$spacing-xs});
  }

  &.l-events-pricing-row__col-3up {
    @include mq(medium) {
      margin-top: 0;
      width: calc(33% - #{$spacing-xxs});
    }
  }
}


// =============================================================================
// Events Videos (Slides)
// =============================================================================

.l-events__videos {
  width: 100%;
  max-width: unset;
  margin-left: 0;
  margin-right: 0;
  margin-top: $events-videos-slides-vertical;
  padding-bottom: $spacing-l;

  // Min and Max must be capitalized, so they export as CSS rather than Sass
  // Hopefully can be fixed by moving from Blendid to more recent Sass
  padding-left: #{"max(#{$events-videos-slides-horizontal}, calc(calc(100% - #{$site-max-width-inner}) / 2))"};

  &.flickity-enabled,
  .flickity-viewport {
    overflow: visible;
  }

  // https://github.com/metafizzy/flickity/issues/534#issuecomment-328859144
  &.flickity-resize .l-events__videos-slide {
    min-height: 100%;
    display: flex;
    align-items: stretch;
  }

  &.flickity-resize {
    .card-b--video {
      min-height: 100%;
    }
  
    .card-b__inner {
      height: 100%;
    }
    
    .card-b__content {
      height: 100%;
      display: flex;

      align-content: flex-start;
      // If we want to re-do everything to get bottom buttons in cards
      // align-content: space-between;
    }  
  }
}

.l-events__videos-slide {
  min-width: 300px;
  width: 28%;
  max-width: 450px;
  margin-right: $events-videos-slides-horizontal;
}

// =============================================================================
// Events Videos Grid
// =============================================================================

.l-events-videos-grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.l-events-videos-grid__block {
  width: 100%;

  .card-b.card-b--video .card-b__content {
    max-width: 100%;
  }

  @include mq($from: blogM, $until: xxlarge) {
    .card-b__heading .heading-xs {
      @include heading-xxs;
    }
    
    .card-b__body .body-xs {
      @include body-xxs;
    }
  }

  @include mq($until: blogM) {
    &:nth-of-type(n+2) {
      margin-top: $events-videos-grid-vertical;
    }
  }

  @include mq(blogM) {
    width: calc(50% - #{$events-videos-grid-horizontal - math.div($events-videos-grid-horizontal, 2)});
    margin-left: $events-videos-grid-horizontal;
    margin-top: $events-videos-grid-vertical;

    &:nth-of-type(odd) {
      margin-left: 0;
    }

    &:nth-of-type(n+3) {
      // border-top: 1px solid $color-border-2;
      padding-top: $events-videos-grid-vertical;
    }
  }
}

.l-events-videos-grid__block--video {
  @include mq(blogL) {
    width: calc(33.333% - #{$events-videos-grid-horizontal - math.div($events-videos-grid-horizontal, 3)});

    &:nth-of-type(odd) {
      margin-left: $events-videos-grid-horizontal;
    }

    margin-left: $events-videos-grid-horizontal;
    margin-top: $events-videos-grid-vertical;

    &:nth-of-type(n+3) {
      // border-top: 0;
      padding-top: 0;
    }
  
    &:nth-of-type(3n+1) {
      margin-left: 0;
    }
  
    &:nth-of-type(n+4) {
      // // border-top: 1px solid $color-border-2;
      padding-top: $events-videos-grid-vertical;
    }
  }
}

.l-events-videos-grid__block--session {
  
  .post-intro__byline {
    margin-top: 0;
    justify-content: flex-start;
    white-space: unset;

    // Account for no leading words, like “presented by”
    margin-left: -8px;

    @include mq($until: medium) {
      align-items: flex-start;
    }
  }

  h3 {
    margin-top: $spacing-xxxs;
    margin-bottom: $spacing-xxxs;
  }
}

// =============================================================================
// Events Presenters Grid
// =============================================================================

.l-events-presenters-grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0;
}

.l-events-presenters-grid__block,
.news-blurb__author.l-events-presenters-grid__block {
  display: block;
  margin-bottom: $spacing-xs;

  & + .l-events-presenters-grid__block.news-blurb__author {
    margin-top: 0;
  }

  .news-blurb__author-photo {
    width: auto;
    height: auto;
    display: block;
    margin: 0 auto;
  }

  .news-blurb__author-name {
    display: block;
    margin: $spacing-xxxs auto 0 auto;
    text-align: center;
  }

  .news-blurb__byline-text {
    display: none;
  }

  @include mq($until: small) {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  @include mq(small) {
    width: calc(50% - #{$events-presenters-horizontal - math.div($events-presenters-horizontal, 2)});
    margin-left: $events-presenters-horizontal;
    max-width: auto;

    &:nth-of-type(odd) {
      margin-left: 0;
    }
  }

  @include mq(blogL) {
    width: calc(33.333% - #{$events-presenters-horizontal - math.div($events-presenters-horizontal, 3)});

    &:nth-of-type(odd) {
      margin-left: $events-presenters-horizontal;
    }

    margin-left: $events-presenters-horizontal;
  
    &:nth-of-type(3n+1) {
      margin-left: 0;
    }
  }
}

// =============================================================================
// Events Locations
// =============================================================================

.l-events-locations-grid {
  margin-top: $spacing-xs;
  margin-bottom: $spacing-xs;
}

.l-events-locations-grid__block + .l-events-locations-grid__block {
  margin-top: $spacing-xs;
}

.l-events-locations-grid__block {
  @include mq(medium) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}

.l-events-locations-grid__block-image {
  @include mq(medium) {
    width: 100%;
    max-width: 55%;
  }
}

.l-events-locations-grid__block-details {
  padding: $spacing-xxs;
  @include mq(medium) {
    width: 100%;
    max-width: 45%;
  }
}
