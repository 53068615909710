// =============================================================================
// Disclosure
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.disclosure {
  @extend .select--s;
  position: relative;
  z-index: 1;
  width: 100%;
}

.disclosure__toggle {
  @extend .select__input;
  appearance: none;
  text-align: left;
  width: 100%;

  @media screen and (min-width: 850px) {
    min-width: auto !important;
  }
}

.disclosure__toggle[aria-expanded=true] {
  background-image: url('../images/icon-select-arrow-up.svg');
  border-bottom: none;
  border-radius: 4px 4px 0 0;

  @media screen and (min-width: 850px) {
    border-radius: 4px;
    border: 1px solid $color-grey-2;
  }
}

.disclosure__content {
  background-color: $color-white;
  border-radius: 0 0 4px 4px;
  width: 100%;
  transition: background-color $ease-out-expo 0.5s, border $ease-out-expo 0.5s;

  @media screen and (min-width: 850px) {
    position: absolute;
    z-index: 1;

    margin-top: 12px;

    // This is a magic number, but significantly less overhead than measuring the dynamic
    // width for the absolutely positioned dropdowns right now
    min-width: 200px;
  }
}

.disclosure__toggle:hover + .disclosure__content .ais-RefinementList-list {
  background-color: $color-grey-4;
  border-color: $color-grey-4;
}

.disclosure__content  {

  .ais-RefinementList-list {
    border: 1px solid $color-grey-2;
    border-width: 0 1px 1px 1px;
    border-radius: 0 0 4px 4px;
    transition: background-color $ease-out-expo 0.5s, border $ease-out-expo 0.5s;
  
    @media screen and (min-width: 850px) {
      border-width: 1px;
      border-radius: 4px;
    }
  }

  .ais-RefinementList--noRefinement {
    border-width: 0;
  }

  .ais-RefinementList-list {
    margin: 0;
    padding: 12px 14px;
  }

  .ais-RefinementList-list:empty {
    padding: 0;
    border: none !important;
    border-radius: 0;
    outline: none 
  }

  .ais-RefinementList-label {
    display: inline-block;
    white-space: nowrap;
  }

  .ais-RefinementList-list .ais-RefinementList-item {
    display: block;
    margin-bottom: 3px;
  }

  .ais-RefinementList-labelText {
    @include body-xxs;
    color: $color-black;
    white-space: nowrap;
    font-feature-settings: "tnum";
  }

  .ais-RefinementList-count {
    display: none;
    visibility: hidden;
    // @include body-xxs;
    // font-style: italic;
    // color: $color-grey-1;
    // border: 1px solid $color-grey-3;
    // padding: 4px 10px;
    // border-radius: 24px;
  }

  // .ais-RefinementList-checkbox:checked + .ais-RefinementList-labelText {  
  //   background: $gradient-blue-to-purple;
  // }

  .ais-RefinementList-checkbox {
    &::before {
      content: "";
      display: block;
    }
  }

}
