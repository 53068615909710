// =============================================================================
// Plugin Block
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------


.plugin-block {
  @include aspect-ratio(1,1);
  position: relative;
  transition: opacity 1s $ease-out-expo, transform 1s $ease-out-expo;
  will-change: transform;
  transform: translate3d(0,0,0);
  max-width: 110px;
  display: inline-block;
  width: 100%;

  @include mq(xlarge) {
    max-width: 140px;
  }
}

.plugin-block__shadow {
  opacity: 1;
  box-shadow: $shadow-l;
  z-index: 0;
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 0;
  transform: translate3d(0,0,0);
}

.plugin-block__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  padding: $spacing-xxs;

  > img {
    display: block;
    width: 100%;
    height: auto;
  }
}



// -----------------------------------------------------------------------------
// Photo Fill
// -----------------------------------------------------------------------------

.plugin-block--fill-photo {

 .plugin-block__inner {
  padding: 0;
 } 
}


// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------

.plugin-block.is-hidden {
  opacity: 0;
  transform: translateY(15px);
}


// -----------------------------------------------------------------------------
// Stagger Appearance
// -----------------------------------------------------------------------------

@for $i from 1 to 10 {
  .plugin-block--delay-#{$i} { transition-delay: $i * 0.05s; }
}