//===============================================================
// Reset Input Style
//===============================================================
/*
  Removes default input styling
*/

@mixin resetInputStyle() {
  border:none;
  background-image:none;
  background-color:transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  // &:focus {
  //   outline: 0;
  // }
}
