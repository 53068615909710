// =============================================================================
// A11yReport
// =============================================================================

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.a11y-report {
  @extend .body-xs;
  color: $color-black;

  // Use “bold” (ie. Medium weight) throughout the
  // report for contrast, similar to text colour override.
  [class^='heading-'],
  [class*=' heading-'] {
    font-weight: 500;
  }
}

.a11y-report__inner {
  padding-top: $spacing-xs;
  padding-bottom: $spacing-m;
  margin-bottom: $spacing-s;
}

.a11y-report--page {

  padding: 18px;
  .a11y-report__inner {
    box-shadow: $shadow-s;
  }


  @include mq(medium) {
    padding: 48px;
    .a11y-report__inner {
      box-shadow: $shadow-m;
    }  
  }
}

// Especially important to meet colour contrast guidelines on this page
.a11y-report a:not(.a11y-report__hidden-link),
.a11y-report .rich-text a,
.a11y-report .link,
.a11y-report .link.link--s {
  color: #2E66F5;
  border-bottom: 1px solid #2E66F5;
}

.a11y-report__title {
  margin-bottom: $spacing-xxxs;  
}

.a11y-report__header {
  margin-top: $spacing-xs;
}

.a11y-report__header,
.a11y-report__section {
  margin-bottom: $spacing-xs;
}

.a11y-report__section-grid {
  @include mq(medium) {
    display: flex;
    flex-wrap: wrap;
    margin-left: -1 * $spacing-s;
    margin-right: -1 * $spacing-s;

    .a11y-report__section {
      width: 50%;
      padding-left: $spacing-s;
      padding-right: $spacing-s;
    }
  }
}

.a11y-report__table-wrap {
  // max-width: 2000px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  position: relative;

  // This portion could also be an l-wrap option
  padding-left: 7.5%;
  padding-right: 7.5%;
  overflow: auto;
  @include mq(medium) {
    padding-left: 5%;
    padding-right: 5%;  
  }  
}

.a11y-report__table-wrap .a11y-report__table {
  min-width: 1000px;
}

.a11y-report__table {
  width: 100%;
  margin-top: $spacing-xxxs;
  margin-bottom: $spacing-xxxs;
  border-collapse: collapse;
  background: $color-grey-5;
  table-layout: fixed;
}

.a11y-report__table thead th {
  background: $color-grey-3;
  font-weight: 500;
}

.a11y-report__table tbody tr + tr td,
.a11y-report__table tbody tr + tr th {
  border-top: 1px solid $color-grey-3;
}

.a11y-report__table td,
.a11y-report__table th {
  vertical-align: top;
  text-align: left;
  padding: $spacing-xxxs $spacing-xxs;
}

.a11y-report__italic {
  font-style: italic;
}

.a11y-report__table tbody th {
  font-weight: normal;
}

.a11y-report__table-guideline {
  font-variant-numeric: tabular-nums;
}

.a11y-report__versions ul {
  padding-left: 0;
  padding-right: 0;
}

// Rich Text
// Ideally, .rich-text would be based on ems rather than
// fixed font sizes, and then we could just scale the parent
// size. In the meantime, we have to overwrite a lot.

.a11y-report .a11y-report__table-cell-remarks .rich-text {
  font-size: 16px;
  line-height: 1.5;
}

.a11y-report .rich-text {
  font-size: 1em;
  color: $color-black;

  * + p,
  ul,
  ol {
    margin-top: 0.75em;
  }

  p, li {
    color: $color-black;
  }

  p,
  code,
  p code,
  li code,
  ul,
  ol,
  li,
  blockquote {
    font-size: 1em;
  }
}
