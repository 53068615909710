// =============================================================================
// Loading Spinner
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.loading {
  width: 30px;
  height: 30px;
  transition: opacity 0.3s linear;
  animation: loadingSpin 1.5s linear infinite;
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.loading--centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}


// -----------------------------------------------------------------------------
// Spinning Animation
// -----------------------------------------------------------------------------

@keyframes loadingSpin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}