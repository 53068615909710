// =============================================================================
// Home Layout
// =============================================================================

.l-home {
  position: relative;
  margin-bottom: $spacing-xxs;

  @include mq(medium) {
    margin-bottom: $spacing-s;
  }
}

.l-home__intro {
  padding: 0 10%;
  position: relative;
  z-index: 1;
  transition: transform 0.75s $ease-out-expo, opacity 0.75s $ease-out-expo;

  @include mq($until: medium) {
    padding-bottom: 20px;
  }
}
