// =============================================================================
// Tip
// =============================================================================
//
// Tips and Warning Objects
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.tip {
  background-color: $color-grey-5;
  padding: $spacing-xxs;
  border: 1px solid $color-border-3;
  border-bottom: 1px solid $color-border-2;
  border-radius: 3px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  @include basicLinks;
}

.tip__icon {
  width: 30px;
}

.tip__content {
  @include body-xs;
  width: calc(100% - 30px);
  padding-left: $spacing-xxs;
  color: $color-black;
  padding-top: 2px; // First line alignment with icon

  code {
    font-family: $font-mono;
    font-size: 0.85em;
  }

  :not(a) > code {
    color: $color-text-lighter;
  }

  @include mq($until: small) {
    width: 100%;
    margin-top: $spacing-xxs;
    padding-left: 0;
  }
}
