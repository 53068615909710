// =============================================================================
// Card B
// =============================================================================
//
// Cards used mainly for linking to feature pages.
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.card-b {
  @include card-outer();
  display: inline-block;
}

.card-b__inner {
  @include card-inner();
}

.card-b__content {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding: $spacing-xxs;
  max-width: 450px;

  &:hover {

    .card-b__link .link {

      &:after {
        transform: scaleX(1);
      }
    }
  }

  @include mq(medium) {
    padding: $spacing-xs;
  }
}

.card-b__icon {
  height: 50px;
  text-align: center;

  svg,
  img {
    display: inline-block;
    height: 100%;
    width: auto;
  }
}

.card-b__img {
  @include aspect-ratio(1.68,1);
  width: 100%;
  transition: transform $ease-out-expo 0.5s;
  transform-origin: 50% 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  > img {
    position: absolute;
    top: -$spacing-xxs !important;
    left: -$spacing-xxs !important;
    right: -$spacing-xxs !important;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border: 1px solid $color-grey-3;
    transition: border $ease-out-expo 0.5s, opacity $ease-out-expo 0.5s;
    bottom: 0;
    width: calc(100% + #{2 * $spacing-xxs});
    background-color: $color-white !important;
    opacity: 0;

    @include mq(medium) {
      top: -$spacing-xs !important;
      left: -$spacing-xs !important;
      right: -$spacing-xs !important;
      max-width: calc(100% + #{2 * $spacing-xs});
    }
  }
}

.card-b__img-zone {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
}

.card-b__heading {
  margin-top: $spacing-xxs;
  width: 100%;
}

.card-b__body {
  margin-top: $spacing-xxxs;
  width: 100%;
}


.card-b__link {
  margin-top: $spacing-xxxs;
  width: 100%;
  position: relative;
  z-index: 2;
}


// -----------------------------------------------------------------------------
// Border
// -----------------------------------------------------------------------------

.card-b--border {

  .card-b__inner {

    &:before {
      border: 1px solid $color-grey-3;
      transition: transform $ease-out-expo 0.5s, border $ease-out-expo 0.5s;
    }
  }

  &:hover {

    .card-b__inner {

      &:before {
        border-color: $color-grey-4;
      }
    }
  }
}



.card-b--portfolio {
  width: 100%;

  .card-b__img {
    @include aspect-ratio(12, 7);

    > img {
      position: absolute;
      max-width: 9999px;
      top: -$spacing-xxs !important;
      left: -$spacing-xxs !important;
      right: -$spacing-xxs !important;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid $color-grey-3;
      transition: border $ease-out-expo 0.5s, opacity $ease-out-expo 0.5s;
      bottom: 0;
      width: calc(100% + #{2 * $spacing-xxs});
      background-color: $color-white !important;
      opacity: 0;

      @include mq(medium) {
        top: -$spacing-xs !important;
        left: -$spacing-xs !important;
        right: -$spacing-xs !important;
        width: calc(100% + #{2 * $spacing-xs});
      }
    }
  }

  .card-b__heading {
    margin-top: $spacing-xxs;

    @include mq(medium) {
      margin-top: $spacing-xs;
    }
  }

  &:hover {

    @include mq(medium) {
      .card-b__img {
        transform: scale(1.05) translateY(-6%);

        > img {
          border-color: $color-grey-4;
          z-index: 0;
        }
      }
    }
  }
}


// -----------------------------------------------------------------------------
// Video
// -----------------------------------------------------------------------------

.card-b--video {
  width: 100%;

  .card-b__content {
    padding: $spacing-xxs;
    max-width: 420px;
  }

  .card-b__img {
    @include aspect-ratio(16, 9);

    > img {
      position: absolute;
      max-width: 9999px;
      top: -$spacing-xxs !important;
      left: -$spacing-xxs !important;
      right: -$spacing-xxs !important;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border: 1px solid $color-grey-3;
      transition: border $ease-out-expo 0.5s, opacity $ease-out-expo 0.5s;
      bottom: 0;
      width: calc(100% + #{2 * $spacing-xxs});
      background-color: $color-white !important;
      opacity: 0;
    }
  }

  .card-b__heading {
    margin-top: $spacing-xxs;
  }

  .card-b__link {
    margin-top: $spacing-xxs;
  }
}


// -----------------------------------------------------------------------------
// Link Bottom
// -----------------------------------------------------------------------------

.card-b.card-b--link-bottom {
  .card-b__content {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;  
  }

  .card-b__content-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .card-b__content-bottom {
  }  
}


// -----------------------------------------------------------------------------
// Size: Small
// -----------------------------------------------------------------------------

.card-b--s {
  width: 100%;
  max-width: 420px;
  margin: auto;

  .card-b__content {
    display: flex;
    align-content: center;
    align-items: center;
    padding: $spacing-xxs;
    flex-wrap: nowrap;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: $spacing-xxs;
      top: 42px;
      width: 15px;
      height: 15px;
      border-top: 2px solid $color-grey-2;
      border-right: 2px solid $color-grey-2;
      transform: rotate(45deg);
      transition: border 0.5s $ease-out-expo, transform 0.5s $ease-out-expo;
    }
  }

  &:hover {

    @include mq(medium) {

      .card-b__content {

        &:after {
          border-color: $color-blue;
          transform: rotate(45deg) translate3d(8px, -8px, 0);
        }
      }
    }
  }

  .card-b__icon {
    margin-right: 12px;
  }

  .card-b__heading {
    width: auto;
    margin-top: 0;
    white-space: nowrap;
    padding-right: 35px;
  }
}

// Full height

.card-b.card-b--h-full,
.card-b.card-b--h-full .card-b__inner,
.card-b.card-b--h-full.card-b__content {
  height: 100%;
}


// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------

.card-b__img {

  img.is-visible {
    opacity: 1;
  }
}
