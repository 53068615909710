@use 'sass:math';

$pgl-border-radius: 6px;
$pgl-slides-horizontal: $spacing-xxs;

// A magic number to make mobile slide height match the desktop
// slide height, relative to the desktop slide
$pgl-mobile-divisor: 3.875;
$pgl-mobile-divisor-frameless: 3.475;

// Note “desktop” is for the screenshot size, not the viewport size
$pgl-slides-min-height: 200px;
$pgl-desktop-min-width: 300px;
$pgl-desktop-ideal-width: 28vw;


// =============================================================================
// Projects Gallery Lite
// =============================================================================

.projects-gallery-lite {
  margin-top: $spacing-s;

  .mockup-gallery__desktop {
    // Aspect Ratio of desktop 8:5 screenshot + magic number (~11px) for "browser top"
    @include aspect-ratio(2500, (1600 + 11));
    width: 100%;
  }

  .mockup-gallery__desktop--frameless {
    // Aspect Ratio of desktop 8:5 screenshot"
    @include aspect-ratio(2500, 1600);
  }

  .mockup-gallery__mobile {

    // Aspect Ratio of mobile screenshots + "phone" chrome
    width: 100%;
    @include aspect-ratio(1, 2.256);
    // margin: 0 3%;
    margin: 0;
    max-width: unset;

    // Default has animation we don’t use here
    transform: translateY(0);
  }

  .mockup-gallery__mobile--frameless {
    // Aspect Ratio of mobile screenshots + "phone" chrome
    @include aspect-ratio(1125, 2436);
  }
}

.projects-gallery-lite__row {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding-bottom: $spacing-xs;

  // Min and Max must be capitalized, so they export as CSS rather than Sass
  // Hopefully can be fixed by moving from Blendid to more recent Sass
  padding-left: #{"max(#{$pgl-slides-horizontal}, calc(calc(100% - #{$site-max-width-inner}) / 2))"};

  &.flickity-enabled,
  .flickity-viewport {
    overflow: visible;
  }

  // https://github.com/metafizzy/flickity/issues/534#issuecomment-328859144
  &.flickity-resize .projects-gallery-lite__slide {
    min-height: 100%;
  }
}

.projects-gallery-lite__slide {
  margin: 0;
  margin-right: $pgl-slides-horizontal;
  display: block;

  img {
    border-radius: $pgl-border-radius;
  }

  &:hover,
  &:focus {
    z-index: 10;
  }
}

.projects-gallery-lite__slide--desktop {
  width: max($pgl-desktop-min-width, $pgl-desktop-ideal-width);
  min-height: $pgl-slides-min-height;
}

// This is all done via variables, relative to the desktop slide
.projects-gallery-lite__slide--mobile {
  width: max(
    math.div($pgl-desktop-min-width, $pgl-mobile-divisor),
    math.div($pgl-desktop-ideal-width, $pgl-mobile-divisor)
  );
  min-height: $pgl-slides-min-height;
}

// This is all done via variables, relative to the desktop slide
.projects-gallery-lite__slide--mobile.projects-gallery-lite__slide--frameless {
  width: max(
    math.div($pgl-desktop-min-width, $pgl-mobile-divisor-frameless),
    math.div($pgl-desktop-ideal-width, $pgl-mobile-divisor-frameless)
  );
  min-height: $pgl-slides-min-height;
}

.projects-gallery-lite__slide-container {
  appearance: none;
  display: block;
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  background: transparent;

  // Effectively the same as `.mockup-gallery__slide.is-selected`, but applied for hover and focus too
  will-change: transform;
  transform: scale(1);
  transition: transform 0.5s $ease-out-expo;

  &:active { 
    cursor: grabbing;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    transform: scale(1.1);

    .mockup-gallery__mobile,
    .mockup-gallery__desktop {
      transform: translateY(0);
    }
  
    .mockup-gallery__desktop-mobile-combo {
  
      .mockup-mobile {
        transform: translateY(11%);
      }
    }
  }
}