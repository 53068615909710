@use 'sass:math';

// =============================================================================
// Flexbox Row
// =============================================================================
//
// Flexbox equal width column rows
//
//

.l-flex-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  & + .l-flex-row {
    margin-top: $gutter-l;
  }
}

.l-flex-row__quarter {
  width: calc(25% - #{$gutter-l - math.div($gutter-l, 4)});
  margin-left: $gutter-l;

  &:nth-of-type(4n+1) {
    margin-left: 0;
  }
}

.l-flex-row__third {
  width: 100%;

  @include mq($until: medium) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &:nth-of-type(n+2) {
      margin-top: $spacing-xs;
    }
  }

  @include mq($from: medium, $until: large) {
    width: calc(50% - #{$gutter-l - math.div($gutter-l, 2)});
    margin-left: $gutter-l;

    &:nth-of-type(odd) {
      margin-left: 0;
    }

    &:nth-of-type(n+3) {
      margin-top: $gutter-l;
    }
  }

  @include mq(large) {
    width: calc(33.333% - #{$gutter-l - math.div($gutter-l, 3)});
    margin-left: $gutter-l;

    &:nth-of-type(3n+1) {
      margin-left: 0;
    }

    &:nth-of-type(n+4) {
      margin-top: $gutter-xl;
    }
  }
}

.l-flex-row__half {
  width: 100%;

  @include mq($until: flexRowHalf) {
    
    &:nth-of-type(n+2) {
      margin-top: $spacing-xs;
    }
  }

  @include mq(flexRowHalf) {
    width: calc(50% - #{$gutter-xl - math.div($gutter-xl, 2)});
    margin-left: $gutter-xl;

    &:nth-of-type(odd) {
      margin-left: 0;
    }

    &:nth-of-type(n+3) {
      margin-top: $gutter-xl;
    }
  }
}

.l-flex-row__footer {
  width: 100%;
  margin-top: $spacing-xs;
  text-align: center;

  @include mq(medium) {
    margin-top: $spacing-s;
  }
}


// -----------------------------------------------------------------------------
// Match Heights
// -----------------------------------------------------------------------------

.l-flex-row--match-heights {
  align-items: stretch;

  .card-b,
  .card-b__inner,
  .card-a,
  .card-a__inner {
    height: 100%;
  }
}


// -----------------------------------------------------------------------------
// Vertically Center
// -----------------------------------------------------------------------------

.l-flex-row--vertically-center {
  align-items: center;
}


// -----------------------------------------------------------------------------
// W/ Spacing
// -----------------------------------------------------------------------------

.l-flex-row--spacing {
  margin: $spacing-xs 0 $spacing-s;
}


// -----------------------------------------------------------------------------
// Adjust gutter when in narrow wrapper
// -----------------------------------------------------------------------------

.l-wrap--narrow {

  @include mq(large) {
    .l-flex-row__third {
      width: calc(33.333% - #{$gutter-xl - math.div($gutter-xl, 3)});
      margin-left: $gutter-xl;

      &:nth-of-type(3n+1) {
        margin-left: 0;
      }
    }
  }
}
