// =============================================================================
// Mobile Nav
// =============================================================================
/*
   Main header for the site that contains the primary navigation.
*/

.mobile-nav {
  position: fixed;
  z-index: $z-index-mobile-nav;
  height: 100vh;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100vw;
  right: -100vw;
  top: 0;
  bottom: 0;
  opacity: 0;
  background-color: $color-white;
  transition: opacity 0.1s linear, right 0s linear 0.2s;

  @include mq(navFull) {
    display: none;
  }
}

.mobile-nav__close {
  height: 56px;
  width: 56px;
  position: absolute;
  top: 16px;
  right: 20px;
  border-radius: 100%;
  cursor: pointer;
  z-index: 1;
  border: none;
  background-color: transparent;

  &:after,
  &:before {
    content: "";
    height: 21px;
    width: 2px;
    background-color: $color-black;
    display: block;
    position: absolute;
    left: 26px;
    top: 16px;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }
}

.mobile-nav__inner {
  padding: $spacing-s 0 $spacing-s;
  width: 100%;
  background-color: $color-white;
  display: block;
  position: relative;
}

.mobile-nav__nav-item,
.mobile-nav__subnav-item,
.mobile-nav__subnav,
.mobile-nav__subnav__inner {
  width: 100%;
  display: block;
  text-align: center;
}

.mobile-nav__nav-item {
  opacity: 0;
  transform: translateY(25px);
  transition: opacity 0.3s linear, transform 1s $ease-out-expo;
}

.mobile-nav__nav-item,
.mobile-nav__subnav-item {
  padding: 21px $spacing-xs;
  cursor: pointer;
  font-size: 28px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(mobileNavSearch) {
    font-size: 36px;
  }
}

.mobile-nav__subnav-icon {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-right: 2px solid $color-black;
  border-bottom: 2px solid $color-black;
  transform: translateY(-3px) rotate(45deg);
  margin-left: 8px;
  margin-right: -12px; // Better optical alignment

  @include mq(mobileNavSearch) {
    width: 18px;
    height: 18px;
    margin-left: 12px;
    transform: translateY(-5px) rotate(45deg);
    margin-right: -18px; // Better optical alignment
  }
}

.mobile-nav__subnav {
  display: none;
  background-color: $color-grey-4;
  width: calc(100% + 2 * #{$spacing-xs});
  margin-left: -#{$spacing-xs};
  padding: 21px 0;
  margin-top: 21px;
}


// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------

.mobile-nav.is-visible {
  right: 0;
  opacity: 1;
  transition: opacity 0.1s linear;

  .mobile-nav__nav-item {
    opacity: 1;
    transform: translateY(0px);
  }
}

.mobile-nav__subnav.is-open {
  display: block;
}

// -----------------------------------------------------------------------------
// Stagger Appearance
// -----------------------------------------------------------------------------

@for $i from 1 to 7 {
  .mobile-nav__nav-item:nth-of-type(#{$i}) { transition-delay: $i * 0.05s; }
}
