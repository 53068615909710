// =============================================================================
// Site Search
// =============================================================================

.l-site-search {
  position: absolute;
  top: 0;
  width: 100vw;
  bottom: 0;
  background: rgba($color-white, 0.95);
  z-index: $z-index-overlay;
  left: -100vw;
  opacity: 0;
  transition: opacity $ease-out-expo 0.5s;
  overflow: auto;

  @include mq($until: mobileNavSearch) {
    background: rgba($color-white, 0.95);
  }
}

.l-site-search__overlay {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100vh;
  right: 0;
}

.l-site-search__inner {
  max-width: $site-max-width-inner;
  width: 90%;
  margin: auto;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.l-site-search__close {
  height: 56px;
  width: 56px;
  position: absolute;
  top: 60px;
  background-color: $color-white;
  right: 0;
  border: 1px solid $color-grey-2;
  border-radius: 100%;
  cursor: pointer;
  transition: transform $ease-out-expo 0.5s;
  transform: scale(0.8);
  box-shadow: 0 0 25px 5px $color-white;

  &:after,
  &:before {
    content: "";
    height: 21px;
    width: 2px;
    background-color: $color-black;
    display: block;
    position: absolute;
    left: 26px;
    top: 16px;
  }

  &:after {
    transform: rotate(45deg);
  }

  &:before {
    transform: rotate(-45deg);
  }

  @include mq($until: mobileNavSearch) {
    top: 20px;
    border: 0px;
  }
}

.l-site-search__form-container {
  width: 100%;
  opacity: 0;
  margin-top: 50vh;
  transform: translateY(10px);
  text-align: center;
  transition: transform $ease-out-expo 0.5s, opacity $ease-out-expo 0.5s;
  z-index: 1;
  position: absolute;
  left: 0;

  @include mq($until: medium) {
    margin-top: 30vh;
  }
}

.l-site-search__dropdown {
  padding-top: 30vh;
  padding-bottom: $spacing-l;
  width: 100%;
}

.l-site-search__dropdown-content {
  width: 90%;
  max-width: $site-max-width-inner;
  margin: auto;
}


// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------

.l-site-search.is-visible {
  position: fixed;
  left: 0;
  opacity: 1;

  .l-site-search__close {
    transform: scale(1);
  }

  .l-site-search__form-container {
    opacity: 1;
    transform: translateY(-50%);
    transition: transform $ease-out-expo 0.5s 0.2s, opacity $ease-out-expo 0.5s 0.2s;
  }
}

.l-site-search__form-container.is-searched {

  @include mq(medium) {
    transform: translateY(-30vh) !important;
  }
}

// Change top position if body is scrolled to account for sticky nav
body.is-scrolled .l-site-search__close {
  top: 15px;
}
