// =============================================================================
// Mockup Gallery
// =============================================================================
//
//


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------


.mockup-gallery {
  margin-top: $spacing-s;
  outline: none;
  opacity: 0;
  min-height: 20vh;

  &.flickity-enabled {
    opacity: 1;
  }

  .flickity-page-dots {
    margin-bottom: 0;
  }

  &.flickity-enabled,
  .flickity-viewport {
    overflow: visible;
  }
}

.mockup-gallery__slide {
  width: 65%;
  padding: 0 2%; 
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  margin: auto;
  flex-wrap: wrap;
  transform: scale(0.9);
  transition: transform 0.5s $ease-out-expo;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active { 
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.mockup-gallery__loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left:  0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mockup-gallery__mobile {
  @include aspect-ratio(1,2.256); // Aspect Ratio of mobile screenshots + "phones"
  width: 27%;
  margin: 0 3%;
  max-width: 270px;
  transform: translateY(30px);
  transition: transform 1s $ease-out-expo;
}

.mockup-gallery__desktop {
  @include aspect-ratio(1.635,1); // Aspect Ratio of desktop screenshot + "browser top"
  width: 100%;
}

.mockup-gallery__desktop-mobile-combo {
  @include aspect-ratio(1.635,1); // Aspect Ratio of desktop screenshot + "browser top"
  width: 100%;

  .mockup-desktop {
    width: 95%;
  }

  .mockup-mobile {
    width: 25%;
    max-width: 270px;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(15%);
    transition: transform 1s $ease-out-expo 0.2s;
  }
}


.mockup-gallery__details {
  width: 100%;
  text-align: center;
  margin-top: $spacing-s;
}

.mockup-gallery__details-description {
  margin-top: $spacing-xxxs;
}

.mockup-gallery__details-link {
  margin-top: $spacing-xxs;

  a {
    color: $color-blue-dark;
  }
}


// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------

.mockup-gallery__slide.is-selected {
  transform: scale(1);

  .mockup-gallery__mobile,
  .mockup-gallery__desktop {
    transform: translateY(0);
  }

  .mockup-gallery__desktop-mobile-combo {

    .mockup-mobile {
      transform: translateY(11%);
    }
  }
}



// -----------------------------------------------------------------------------
// Stagger Appearance
// -----------------------------------------------------------------------------

@for $i from 1 to 20 {
  .mockup-gallery__mobile:nth-child(#{$i}) { transition-delay: $i * 0.04s + 0.1s; }
}
