// =============================================================================
// KB Layout
// =============================================================================


.l-kb {
  display: flex;
  flex-wrap: wrap;
  margin-top: $spacing-s;

  @include mq(large) {
    margin-top: $spacing-m;
  }
}

.l-kb__title {
  width: 100%;
  margin: auto;

  @include mq(medium) {
    width: 80%;
    max-width: 640px;
  }

  @include mq(large) {
    width: 80%;
    margin-left: 0;
  }

  @include mq(xlarge) {
    max-width: 720px;
  }
}

.l-kb__main {
  width: 100%;

  @include mq(large) {
    width: 66.666%;
  }
}

.l-kb__aside {
  width: 100%;
  max-width: 640px;
  margin: auto;
  margin-top: $spacing-xs;

  @include mq(large) {
    width: 33.333%;
    padding-left: $spacing-m;
    margin-top: 0;
  }
}

.l-kb__meta {
  width: 100%;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 9rem;
  margin-bottom: -2rem;

  .relevant-products {
    color: $color-text-light;
    font-size: 16px;
  }

  @include mq(large) {
    margin-bottom: -4rem;
    margin-left: 0;
  }
}
