// =============================================================================
// Still Have Questions
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.shq {
  margin: 0;
}

.shq__item {
  @include body-s;
  margin-top: $spacing-xxs;
  color: $color-blue;
  display: flex;
  width: auto;
  align-content: center;
  align-items: center;

  &:after {
    content: "";
    height: 10px;
    width: 10px;
    display: inline-block;
    margin-left: 2px;
    border-right: 1px solid $color-blue;
    border-bottom: 1px solid $color-blue;
    transform: rotate(-45deg);
    transition: transform 0.5s $ease-out-expo;
  }

  &:hover {
    
    &:after {
      transform: rotate(-45deg) translate3d(4px, 4px, 0);
    }
  }
}

.shq__icon {
  display: inline-block;
  height: 24px;
  width: 24px;
  margin-right: 12px;

  img {
    height: 100%;
    width: auto;
  }
}