// =============================================================================
// Banner A
// =============================================================================
//
// Horizontal CTA Banner
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------


.banner-a {
  background: $color-grey-4;
  width: 100%;
  padding: $spacing-s 0 ($spacing-s + 10px); // Accounts for extra spacing needed for baseline alignment
}

.banner-a__inner {
  max-width: $site-max-width-inner;
  margin: auto;
  width: 90%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}

.banner-a__text {
  @include heading-m-alt;
  margin: $spacing-xxxs;
}

.banner-a__btn {
  transform: translateY(10px); // Baseline alignment
  margin: $spacing-xxxs $spacing-xxs;
}


// -----------------------------------------------------------------------------
// Small
// -----------------------------------------------------------------------------

.banner-a--s {
  padding: $spacing-s 0 ($spacing-s + 3px); // Accounts for extra spacing needed for baseline alignment

  .banner-a__text {
    @include heading-s;
    margin-right: $spacing-xxs;
  }

  .banner-a__btn {
    transform: translateY(3px);
  }
}


// -----------------------------------------------------------------------------
// Darker BG
// -----------------------------------------------------------------------------

.banner-a--dark {
  background-color: $color-grey-3;
}