// =============================================================================
// Disclosure B
// =============================================================================
//
// Content disclousre pattern, which can also be used with details/summary. 
// @see
// The other disclousre partial covers the input-like disclosure input in use
// for the partners section only.

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.disclosure-b {
  box-sizing: border-box;
}

summary.disclosure-b__button {
  list-style: none;
}

summary.disclosure-b__button::-webkit-details-marker,
summary.disclosure-b__button::marker {
  display: none;
}

.disclosure-b__button {
  color: $color-black;
  padding-left: 24px;
  background-color: transparent;
  background-image: url('../images/icon-select-arrow-right.svg');
  background-repeat: no-repeat;
  background-position: 4px center;
  background-size: 14px 12px;
  transition: background-color $ease-out-expo 0.5s, border $ease-out-expo 0.5s;
  cursor: pointer;

  &:hover {
    background-color: $color-grey-4;
    // border-color: $color-grey-4;
  }

  @include mq(medium) {
    padding-left: 28px;
    background-position: 4px center;
    background-size: 16px 12px;
  }
}

details.disclosure-b[open] > .disclosure-b__button {
  background-image: url('../images/icon-select-arrow-down.svg');
}

// Button as button

.disclosure-b--button {
  // @include resetInputStyle;
  @include body-s;

  .disclosure-b__button {
    padding: 12px;
    padding-left: 50px;
    border-radius: 4px;
    border: 1px solid $color-grey-2;
    background-position: 20px center;

    &:hover,
    &:focus {
      background-color: $color-grey-4;
      border-color: $color-grey-4;
    }

    @include mq(medium) {
      min-width: 420px;
      padding: 16px;
      padding-left: 50px;
      background-position: 24px center;
    }
  }
}

.disclosure-b__visually-hidden {
  @include hide-visually;
}
