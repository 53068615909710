// =============================================================================
// Card F
// =============================================================================
//
// Cards that are used for pricing options
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.card-f {
  @include card-outer();
  display: inline-block;
  z-index: 0;
  width: 100%;
  height: 100%;

  @include mq(medium) {
    &:hover {
      .card-f__btn {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }
}

.card-f__inner {
  @include card-inner();
  height: 100%;
}

.card-f__ir {
  @include mq(large) {
    overflow: hidden;
    @include aspect-ratio(1, 1.1);
  }
}

.card-f__content {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 60px $spacing-xs 80px;
  margin: auto;
  transition: transform 0.5s $ease-out-expo;
  position: relative;
  z-index: 2;

  @include mq(large) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.card-f__icon {
  width: 100%;
  text-align: center;
  height: 60px;

  > img {
    display: inline-block;
    height: 100%;
    width: auto;
    max-width: 160px;
  }
}

.card-f__icon-zone {
  position: absolute;
  top: 0;
  height: 100%;
  z-index: 1;
}

.card-f__heading {
  margin-top: $spacing-xxs;
  width: 100%;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}

.card-f__subheading {
  color: $color-text-light;
  margin-top: 6px;
}

.card-f__body {
  margin: $spacing-xxs auto 0;
  width: 100%;

  p {
    max-width: 380px;
    margin: auto;
  }
}

.card-f__btn {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-top: $spacing-xxs;
  will-change: opacity, transform;

  @include mq(large) {
    height: 60px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s $ease-out-expo, transform 0.5s $ease-out-expo;
  }
}

// -----------------------------------------------------------------------------
// Border
// -----------------------------------------------------------------------------

.card-f--border {
  .card-f__inner {
    &:before {
      border: 1px solid $color-grey-3;
      transition: transform $ease-out-expo 0.5s, border $ease-out-expo 0.5s;
    }
  }

  &:hover {
    .card-f__inner {
      &:before {
        border-color: $color-grey-4;
      }
    }
  }
}

// -----------------------------------------------------------------------------
// Background
// -----------------------------------------------------------------------------

.card-f--bg {
  .card-f__inner {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-size: cover;
      z-index: 1;
      border-radius: 4px;
      opacity: 0;
      transition: transform 0.5s $ease-out-expo, opacity 0.5s $ease-out-expo;
    }

    @include mq($until: large) {
      &:after {
        opacity: 1;
      }
    }
  }

  &:hover {
    @include mq(large) {
      .card-f__inner {
        &:after {
          transform: scale(1.05);
          opacity: 1;
        }
      }
    }
  }
}

// -----------------------------------------------------------------------------
// Smaller Btn
// -----------------------------------------------------------------------------

.card-f--small-btn {
  .card-f__btn {
    height: 80px;
  }
}

// -----------------------------------------------------------------------------
// Dotall
// -----------------------------------------------------------------------------

.card-f--dotall {
  .card-f__inner {
    &:after {
      background-image: url("../images/dotall-bg.png");
    }
  }
}

// -----------------------------------------------------------------------------
// CraftQuest
// -----------------------------------------------------------------------------

.card-f--craftquest {
  .card-f__icon {
    img,
    svg {
      max-width: 200px;
    }
  }

  .card-f__ir {
    @include mq(large) {
      @include aspect-ratio(1, 0.8);
    }
  }
}

// -----------------------------------------------------------------------------
// Hosting
// -----------------------------------------------------------------------------

.card-f--hosting {
  .card-f__ir {
    @include mq(large) {
      @include aspect-ratio(1, 1);
    }
  }
}

// -----------------------------------------------------------------------------
// Demos
// -----------------------------------------------------------------------------

.card-f--demos {
  &:before {
    display: none;
  }

  // Prevent flicker
  // 36 = 16 (card-outer Y) + 20 (button Y)
  &:hover {
    &:after {
      bottom: -36px;
    }
  }

  .card-f__inner {
    height: auto;

    &:before {
      display: none;
    }
  }

  .card-f__ir {
    overflow: visible;

    &:before {
      display: none;
    }
  }

  .card-f__content {
    position: relative;
    padding-top: 0;
    padding-bottom: 0;

    @include mq($until: medium) {
      padding: 0 0 60px;
    }
  }

  .card-f__icon {
    @include aspect-ratio(1.68, 1);
    height: auto;
    margin: $spacing-xxs;

    > img {
      box-shadow: $shadow-s;
      width: 100%;
      max-width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;

      &.is-visible {
        opacity: 1;
      }
    }

    img.is-visible {
      opacity: 1;
    }
  }

  .card-f__btn {
    z-index: 2;
  }
}

// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------
