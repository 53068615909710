// =============================================================================
// Search Bar
// =============================================================================
//
//
$search-bar-border-radius: 50px;

.search-bar {
  background-color: $color-white;
  border: 1px solid $color-border-2;
  border-radius: $search-bar-border-radius;
  position: relative;
  width: 100%;

  // Magnifying Glass
  svg {
    position: absolute;
    top: 16px;
    left: 24px;
    height: 18px;
    width: 18px;
    pointer-events: none;

    * {
      stroke: $color-grey-1 !important;
      stroke-width: 2;
    }
  }

  .search-bar__input {
    @include resetInputStyle();
    padding: 16px 24px 16px 50px;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    width: 100%;
    border-radius: $search-bar-border-radius;

    &:focus {

      & + svg * {
        stroke: $color-blue !important;
      }
    }
  }
}

.search-bar__inner {
  background-color: $color-white;
  position: relative;
  border-radius: $search-bar-border-radius;
}

.search-bar__shadow {
  box-shadow: $shadow-basic;
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 0;
  border-radius: 36px;
}


// -----------------------------------------------------------------------------
// Large
// -----------------------------------------------------------------------------

.search-bar--l {
  border: 1px solid $color-border-1;


  @include mq(mobileNavSearch) {
    .search-bar__input {
      padding: 24px 36px 24px 64px;
      font-size: 21px;
    }

    svg {
      width: 24px;
      height: 24px;
      top: 24px;
      left: 28px;
    }
  }
}


// -----------------------------------------------------------------------------
// Site Search
// -----------------------------------------------------------------------------

.search-bar--site-search {
  display: inline-block;
  width: 100%;
  max-width: 720px;
  position: relative;
  border: 0;
  z-index: 2;

  @include mq(mobileNavSearch) {
    width: 80%;
  }
}


// -----------------------------------------------------------------------------
// KB Homepage Search
// -----------------------------------------------------------------------------

.search-bar--kb-home {
  margin-top: $spacing-xs;
  width: 100%;
  max-width: 420px;
  margin-left: auto;
  margin-right: auto;
}


// -----------------------------------------------------------------------------
// Action Bar Search
// -----------------------------------------------------------------------------

.search-bar--action-bar {

  @include mq($until: medium) {
    position: absolute;
    right: 5vw;
    top: 12px;
    width: auto;

    svg {
      top: 16px;
      left: 16px;
    }

    .search-bar__input {
      width: 50px;
      height: 50px;
      border-radius: 99px;
      padding: 12px 12px 12px 40px;

      &:focus {
        width: 90vw;
      }
    }
  }
}

.search-bar--partners {
  @include resetInputStyle;
  border: 1px solid $color-grey-2;
  margin-left: 4px;
  margin-right: 4px;
  padding: 0;
  width: auto;
  height: 49px;

  @media (min-width: 850px) {
    .search-bar__input {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;  
    }
  }

  @include mq(xlarge) {
    height: 52px;
  }
}

.search-bar--partners .search-bar__input {
  padding: 14px 24px 14px 50px;
}
