@use 'sass:math';

// =============================================================================
// Commerce Features Layout
// =============================================================================
//
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.l-commerce-features {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 720px;
  margin: $spacing-s auto 0;
}

.l-commerce-features__feature {
  
  @include mq($until: small) {

    &:nth-of-type(n+2) {
      margin-top: $spacing-xs;
    }
  }

  @include mq(small) {
    width: calc(50% - #{$spacing-xs - math.div($spacing-xs, 2)});
    margin-left: $spacing-xs;

    &:nth-of-type(odd) {
      margin-left: 0;
    }

    &:nth-of-type(n+3) {
      margin-top: $spacing-xs;
    }
  }

  @include mq(large) {
    width: calc(50% - #{$spacing-m - math.div($spacing-m, 2)});
    margin-left: $spacing-m;
  }
}

.l-commerce-features__footer {
  text-align: center;
  width: 100%;
  margin-top: $spacing-xxs;
}
