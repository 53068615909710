@use 'sass:math';

// =============================================================================
// Pricing Layout
// =============================================================================

$l-pricing-gutter: math.div($spacing-xxs, 2);

@include mq(medium) {
  .l-pricing .card-e {
    max-width: 355px;
    display: block;
    margin: 0 auto;
  }

  .l-pricing .card-e .card-e__btn {
    width: auto;
  }

  .l-pricing .card-e--border .card-e__inner::before {
    border-color: $color-grey-2;
  }

  .l-pricing .card-e .body-s {
    font-size: 17px;
    line-height: 1.3;
  }
}

.l-pricing__tiers {
  margin-top: $spacing-m;
}

.l-pricing__commerce {
  margin-bottom: $spacing-m;

  @include mq(large) {
    margin-bottom: $spacing-l;
  }

  @include mq(xxlarge) {
    margin-bottom: $spacing-xl;
  }
}

.l-pricing__faq {
  margin-top: $spacing-m;
  padding-bottom: $spacing-m;

  @include mq(large) {
    margin-top: $spacing-l;
    padding-bottom: $spacing-l;
  }

  @include mq(xxlarge) {
    margin-top: $spacing-xl;
    padding-bottom: $spacing-xl;
  }

  .blurb--xs { // maintains left-alignment at smaller-sizes
    text-align: left;
    margin-top: 0 !important;
    padding-bottom: $spacing-xs;
  }
}

.l-pricing__faq-heading {
  margin-bottom: $spacing-xs;

  @include mq(large) {
    margin-bottom: $spacing-s;
  }
}

.l-pricing__faq-questions {
  width: 100%;
  max-width: 960px;
  margin: auto;
}

.l-pricing__tiers {
  padding-left: $l-pricing-gutter;
  padding-right: $l-pricing-gutter;

  @include mq(medium) {
    padding-left: $l-pricing-gutter;
    padding-right: $l-pricing-gutter;
  }

  @include mq(xlarge) {
    padding-left: $spacing-xs;
    padding-right: $spacing-xs;
  }
}

.l-pricing-row {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  @include mq(medium) {
    margin-left: -1 * math.div($l-pricing-gutter, 2);
    margin-right: -1 * math.div($l-pricing-gutter, 2);
  }

  @include mq(xlarge) {
    margin-left: -1 * $l-pricing-gutter;
    margin-right: -1 * $l-pricing-gutter;
  }
}

.l-pricing-row__col {
  text-align: center;
  margin-bottom: $spacing-s;

  @include mq(medium) {
    padding-left: ($l-pricing-gutter/2);
    padding-right: ($l-pricing-gutter/2);
  }

  @include mq(xlarge) {
    padding-left: $l-pricing-gutter;
    padding-right: $l-pricing-gutter;
  }

  @include mq($from: medium, $until: pricingFull) {
    width: 50%;
  }
  

  // Hacks on otherwise discrete for 4-col layout on still not overly wide screens
  @include mq($from: pricingFull, $until: xlarge) {
    .card-e__content {
      padding: 40px $spacing-xxs 60px;
    }

    .btn--l {
      padding: 15px 25px;
      font-size: 16px;
    }
  }

  @include mq(pricingFull) { 
    width: 25%;
  }
}

.l-pricing .card-e__promo {
  @include mq(pricingFull) {
    margin-left: -$spacing-xxs;
    margin-right: -$spacing-xxs;  
  }
  @include mq(xlarge) {
    margin-left: -$spacing-xs;
    margin-right: -$spacing-xs;  
  }
}

.l-pricing-row--auto {
  .l-pricing-row__col {
    width: auto;
  }
}
