// =============================================================================
// Icon lists
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.icon-list {
  display: block;
  margin: 0;
  padding: 0;
}

.icon-list__item {
  @include body-xs;
  display: block;
  padding:  8px 0px 8px 35px;
  position: relative;
}

.icon-list__icon {
  display: block;
  height: 15px;
  width: 25px;
  position: absolute;
  left: 0;
  top: 7px;
  text-align: center;

  > img {
    display: inline-block;
  }
}

.icon-list__item-link {
  position: relative;
  color: $color-black;
}


// -----------------------------------------------------------------------------
// Large
// -----------------------------------------------------------------------------

.icon-list--l {

  .icon-list__item {
    @include body-s;
  }
}