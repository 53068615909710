// =============================================================================
// Card E
// =============================================================================
//
// Cards that are used for pricing options
//


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.card-e {
  @include card-outer();
  display: inline-block;
  max-width: 420px;
  z-index: 0;
}

.card-e__inner {
  @include card-inner();
}

.card-e__content {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 60px $spacing-xs 80px;
  min-width: 20vw;

  @include mq(xlarge) {
    min-width: initial; 
  }
}

.card-e__label {
  width: 100%;
}

.card-e__label-qualifier {
  margin-top: $spacing-xxxs;
}

.card-e__details {
  margin: $spacing-xxxs 0 0;
  width: 100%;

  @include mq(medium) {
    height: 90px
  }

  & > p {
    max-width: 320px;
  }
}

.card-e__price {
  font-size: 36px;
  color: $color-black;
  position: relative;
  margin-top: 30px;
  line-height: 1;
  display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 36px;
  min-width: 1px;

  @include mq(large) {
    font-size: 42px;
    min-height: 42px;
  }

  
  // Use tabular numbers if the price can be toggled,
  // and track in a bit because they are spaced out so
  // much in GT Eesti
  [data-checked] {
    font-feature-settings: "lnum", "tnum";
    letter-spacing: -0.05em;
  }
}

// If Dollar Sign
.card-e__price-sign {
  font-size: 0.6em;
  display: block;
  position: absolute;
  top: 0.3em;
  left: -0.6em;
}

// If Asterisk
.card-e__price-asterisk {
  font-size: 0.6em;
  display: block;
  position: absolute;
  top: 0.3em;
  right: -0.5em;
}

.card-e__price-per {
  display: block;
  width: 100%;
  font-size: 32px;
  color: $color-text-light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  line-height: 1;
  width: 100%;
  min-height: 64px;

  @media (max-width: 768px) {
    min-height: 185px;
  }
}

.card-e__price-per-inline {
  font-size: 0.6em;
  display: inline-block;
  transform: translate(0, 25%);
  color: $color-text;
  line-height: 1;
  margin-bottom: 0.3em;
}

.card-e__price-details {
  display: block;
  font-size: 15px;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 21px;
  top: 50px;
  width: 100%;
  margin-top: $spacing-xxs;

  @include mq(medium) {
    position: absolute;
    margin-top: 0;
  }
}

.card-e__btn {
  position: relative;
  width: 100%;
  margin-top: $spacing-xxs;
  z-index: 4;

  @include mq(medium) {
    margin-top: 110px;
  }
}

.card-e__below-btn {
  position: absolute;
  top: 80px;
  width: 100%;
  font-size: 15px;
  color: $color-text;
  opacity: 0.8;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Price switch and spacer
.card-e__price-switch {
  top: 65px;
  position: relative;
  height: 30px;
}

.card-e__link-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;

  // Lower faux button z-index, because it’s under the
  // the overlay with the link in this scenario.
  & ~ .card-e__btn {
    z-index: 2;
  }
}

.card-e__promo {
  background: $color-grey-3;
  color: #184cef;
  display: block;
  width: calc(100% + #{$spacing-xs} + #{$spacing-xs});
  margin-left: -$spacing-xs;
  margin-right: -$spacing-xs;
  padding: $spacing-xxxs $spacing-xxxs;
  transition: background-color ease-in-out 0.2s, color ease-in-out 0.1s;
  z-index: 4;
  transform: translateY(-50%);

  @include mq(medium) {
    transform: translateY(60px);
  }
}

a.card-e__promo:hover,
a.card-e__promo:focus {
  background: #184cef;
  color: $color-white;
}

.card-e__promo:empty,
.card-e__promo--empty {
  background: transparent;
}


// -----------------------------------------------------------------------------
// Border
// -----------------------------------------------------------------------------

.card-e--border {

  .card-e__inner {
    
    &:before {
      border: 1px solid $color-grey-3;
      transition: transform $ease-out-expo 0.5s, border $ease-out-expo 0.5s;
    }
  }

  &:hover {

    .card-e__inner {
      
      &:before {
        border-color: $color-grey-4;
      }
    }
  }
}


// -----------------------------------------------------------------------------
// Smaller "Price" (For Business Tier)
// -----------------------------------------------------------------------------

.card-e--smaller-price {

  .card-e__price {
    font-size: 32px;
    line-height: 37px;
  }
}

// -----------------------------------------------------------------------------
// Card with heading (for Events pricing)
// -----------------------------------------------------------------------------
