// =============================================================================
// Features Hero
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------


.features-hero {
  display: flex;
  height: 70vh;
  position: relative;
  overflow: hidden;
  margin-top: -116px;
}

.features-hero__bg-container {
  position: absolute;
  left: -100px;
  right: -100px;
  bottom: -100px;
  top: -100px;
  transition: transform 20s $ease-out-expo;
}

.features-hero__bg {
  position: absolute;
  top: 0;
  left: -140vh;
  width: 350vh;
  height: 350vh;
  background-image: url('../images/features-bg.png');
  background-size: 40% 40%;
  z-index: -1;
  animation-name: moveBg;
  animation-duration: 30s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.features-hero__text {
  transform: translateZ(0);
  z-index: 1;
  padding-top: 8vh; // optically center
}


@keyframes moveBg {
   0% {
     transform: translateX(0) translateY(0);
   }
   100% { 
     transform: translateX(140vh) translateY(-140vh);
   }
}