.sponsor-tiers {}


.sponsor-tiers__tier {
  & + .sponsor-tiers__tier {
    margin-top: $spacing-s;
    @include mq(medium) {
      margin-top: $spacing-m;
    }
    @include mq(large) {
      margin-top: $spacing-l;
    }    
  }
}

.sponsor-tiers__tier-heading {
  text-align: center;
  color: $color-grey-1;
  & + .sponsor-tiers__grid {
    margin-top: $spacing-xs;
  }
}


.sponsor-tiers__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-left: -#{$gutter-l};
  margin-right: -#{$gutter-l};
  .sponsor-mark {
    margin: $gutter-l;
  }
}

.sponsor-mark {
  width: 100%;
}

.sponsor-mark__link,
.sponsor-mark__image {
  display: block;
  margin: 0;
  width: 100%;
}

.sponsor-mark__no-image {
  font-size: 21px;
  line-height: 1.1;
  text-align: center;
  padding-top: $spacing-xs;
  padding-bottom: $spacing-xs;
}

.sponsor-mark__label {
  margin-top: $spacing-xxs;
}

.sponsor-deck {
  margin-top: $spacing-s;
  text-align: center;
}
