// =============================================================================
// Buttons
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.btn {
  @include unbutton;
  font-family: $font-primary;
	display: inline-block;
  font-size: 15px;
  padding: 18px 24px;
  color: $color-white;
  background: none;
  position: relative;
  overflow: hidden;
  border-radius: 40px;
  backface-visibility: hidden;
  transition: transform $ease-out-expo 0.5s;
  cursor: pointer;
  will-change: transform;
  transform: translateZ(0);
  white-space: nowrap;
  --btn-hover-x: 0;

  @include mq(xlarge) {
    font-size: 16px;
    padding: 18px 30px;
  }

  @include mq(xxlarge) {
    font-size: 18px;
    padding: 18px 40px;
  }

  &:not(.btn--disabled):not(.btn--newsletter) {
    ::selection {
      background: rgba($color-white, 0.1); /* WebKit/Blink Browsers */
      color: $color-white;
    }

    ::-moz-selection {
      background: rgba($color-white, 0.1); /* Gecko Browsers */
      color: $color-white;
    }
  }

  // Gradient Background
  &:before {
    content: " ";
    background: $gradient-blue-to-red;
    position: absolute;
    width: calc(200% + 50px);
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
    transition: left $ease-out-expo 0.5s;
    will-change: left;
    transform: translateZ(0);
  }

  @supports(--css: variables) {

    &:hover, 
    &:focus {

      &:before {
        left: var(--btn-hover-x, -50px);
      }
    }
  }

  &:active {
    transform: scale(0.97);
  }
}

.btn__content {
  display: inline-block;
  position: relative;
  z-index: 2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  pointer-events: none;
}

.btn__icon {
  display: inline-block;
  height: 18px;
  width: 18px;
  position: relative;
  vertical-align: middle;
  margin-left: 4px;
  z-index: 1;

  > svg {
    width: 100%;
    height: auto;
  }
}


// -----------------------------------------------------------------------------
// "Rectangle" Shape
// -----------------------------------------------------------------------------

.btn--rect {
  border-radius: 4px;

  // Gradient Background Mask (If Combined)
  &:after {
    border-radius: 3px !important;
  }
}


// -----------------------------------------------------------------------------
// Dropdown Button
// -----------------------------------------------------------------------------

.btn--dropdown {

  .btn__icon {
    height: 12px;
    width: 12px;
    vertical-align: bottom;
    margin-left: 4px;
    margin-bottom: 1px;
  }
}


// -----------------------------------------------------------------------------
// Large
// -----------------------------------------------------------------------------

.btn--l {
  font-size: 18px;
  padding: 18px 50px;

  @include mq(large) {
    font-size: 21px;
    white-space: nowrap;
  }

  @include mq(xxlarge) {
    font-size: 23px;
  }
}

// -----------------------------------------------------------------------------
// XS
// -----------------------------------------------------------------------------

.btn--xs {
  font-size: 15px;
  padding: 15px 25px;

  @include mq(xxlarge) {
    font-size: 16px;
  }
}


// -----------------------------------------------------------------------------
// Ghost
// -----------------------------------------------------------------------------

.btn--ghost {
  color: $color-blue-electric;

  &:before {
    width: 300%;
  }
  
  // Gradient Background Mask
  &:after {
    content: " ";
    background: $color-white;
    position: absolute;
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    z-index: 0;
    border-radius: 40px;
    transition: background $ease-out-expo 0.5s;
  }

  &:hover, 
  &:focus {

    &:after {
      background: rgba($color-white, 0.75);
    }
  }
}

.btn--ghost-on-grey {

  &:after {
    background: $color-grey-4;
  }
}

// XS Ghost Button
.btn--ghost.btn--xs {

  &:after {
    top: 1px;
    left: 1px;
    bottom: 1px;
    right: 1px;
    z-index: 0;
  }
}


// -----------------------------------------------------------------------------
// Full Width 
// -----------------------------------------------------------------------------

.btn--full {
  width: 100%;
  text-align: center;
}


// -----------------------------------------------------------------------------
// Newsletter
// -----------------------------------------------------------------------------

.btn--newsletter {
  @include body-s;
  background-color: $color-grey-4;
  width: 100%;
  padding: 18px 45px 18px 18px;
  text-align: left;
  position: relative;
  border-radius: 4px;

  svg {
    position: absolute;
    right: 18px;
    top: 24px;
    transition: transform $ease-out-expo 0.3s;
  }

  &:before {
    content: none;
  }

  &:hover,
  &:focus {

    svg {
      transform: translateX(3px);
    }
  }
}


// -----------------------------------------------------------------------------
// Disabled
// -----------------------------------------------------------------------------

.btn--disabled,
.btn:disabled {
  color: $color-text-light;
  border: 2px solid $color-grey-3;
  cursor: not-allowed;
  margin-bottom: -4px; // offsets height for alignment
  user-select: none;

  &:before {
    display: none;
  }
}


// -----------------------------------------------------------------------------
// Adjacent Spacing
// -----------------------------------------------------------------------------

.btn + .btn {
  margin-left: $spacing-xxs;
}