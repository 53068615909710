// =============================================================================
// Card C
// =============================================================================

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.card-c {
  @include card-outer();
  display: inline-block;
  position: relative;
  z-index: 0;
  height: 100%;
}

.card-c__inner {
  @include card-inner();
  height: 100%;
}

.card-c__content {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  flex-wrap: wrap;
  max-width: 480px;
  height: 100%;

  @include mq(medium) {
    &:hover {

      .card-c__description,
      .card-c__bottom-bar {

        // Border must extend because of hoverstate
        &:before {
          transform: scaleX(1.05) scaleY(0.6) translateZ(0);
        }
      }
    }
  }
}

.card-c__top-bar {
  padding: 24px;
  display: flex;
  width: 100%;

  @include mq(partnersGridM) {
    padding: 28px;
  }
}

.card-c__top-bar-left {
  width: 33.333%;
  display: flex;
  max-width: 80px;
  align-items: center;
}

.card-c__top-bar-right {
  width: 66.666%;
  padding-left: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-c__logo {
  transform: translateY(-2px); // optical alignment
  height: 80px;
  width: 80px;
  border: 1px solid $color-border-2;
  border-radius: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;

  > img {
    max-height: 100%;
    width: auto;
  }
}

.card-c__location {
  white-space: normal;
}

.card-c__description {
  width: 100%;
  padding: 24px;
  position: relative;

  @include mq(partnersGridM) {
    padding: 28px;
  }

  // top border
  &:before {
    transition: transform $ease-out-expo 0.5s;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0px;
    right: 0px;
    height: 1px;
    background-color: $color-border-2;
    backface-visibility: hidden;
    transform: translateZ(0);
  }
}

.card-c__bottom-bar {
  padding: $spacing-xxs $spacing-xxs;
  width: 100%;
  position: relative;

  // top border
  &:before {
    transition: transform $ease-out-expo 0.5s;
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0px;
    right: 0px;
    height: 1px;
    background-color: $color-border-2;
    backface-visibility: hidden;
    transform: translateZ(0);
  }
}

.card-c__badges {
  // display: flex;
  display: block;
}

.card-c__badge {
  position: relative;
  display: flex;
  align-items: center;
}

.card-c__badge + .card-c__badge {
  margin-top: 8px;
}

.card-c__badge-icon {
  height: 18px;
  width: 18px;
  margin-right: 8px;
}

.card-c__badge-text {
  font-size: 10px;
  color: gray;
  transform: translateY(1px);
}

.card-c__badge-text {
  font-size: 14px;
  color: $color-text;
  margin: 0;
}


// -----------------------------------------------------------------------------
// Border
// -----------------------------------------------------------------------------

.card-c--border {

  .card-c__inner {
    
    &:before {
      border: 1px solid $color-grey-3;
      transition: transform $ease-out-expo 0.5s, border $ease-out-expo 0.5s;
    }
  }

  &:hover {

    .card-c__inner {
      
      &:before {
        border-color: $color-grey-4;
      }
    }
  }
}

// -----------------------------------------------------------------------------
// Space Between
// -----------------------------------------------------------------------------
/*
   Modifications for cards that have a top and bottom bar, specifically for the Partners grid.
*/

.card-c__content--space-between {
  align-content: stretch;

  .card-c__description::before {
    display: none;
    visibility: hidden;
  }

  .card-c__top-bar {
    align-self: flex-start;
    border: 1px solid $color-border-2;
    border-radius: 4px 4px 0 0;
  }

  .card-c__content {
    border-top: none;
  }

  .card-c__bottom-bar {
    align-self: flex-end;
  }
}

// -----------------------------------------------------------------------------
// Small
// -----------------------------------------------------------------------------
