// =============================================================================
// Feature Slideshow
// =============================================================================
//
// Feauture Slideshow
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.feature-slideshow {
  position: relative;

  * {
    outline: none;
  }

  .flickity-viewport,
  .flickity-slider {
    width: 100%;
    height: 100%;
  }

  .flickity-page-dots {
    margin: 0;
    padding: $spacing-xxxs 0;
    bottom: 0;
  }
}

.feature-slideshow__slide {
  width: 65%;
  padding: 0 2%;
  margin: 0 3%;
  text-align: center;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  @include mq($until: medium) {
    width: 75%;
    margin: 0 1%;
  }

  img {
    box-shadow: $shadow-screenshot;
    border-radius: 8px;
    transition: transform 0.5s $ease-out-expo;
    transform-origin: center;

    @include mq($until: homeFeatureSlideshowNav) {
      box-shadow: $shadow-s;
    }
  }

  &.is-selected {
    .feature-slideshow__slide-intro {
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.5s $ease-out-expo 0.35s, opacity 0.5s $ease-out-expo 0.35s;
    }
  }

  &:not(.is-selected) {
    img {
      transform: scale(0.9);
    }
  }

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.feature-slideshow__slide-intro {
  margin: $spacing-xxs auto 0;
  max-width: 540px;
  opacity: 0;
  transform: translateY(10px);
  transition: transform 0.5s $ease-out-expo, opacity 0.5s $ease-out-expo;
}

.feature-slideshow__btns {
  @include mq($until: homeFeatureSlideshowNav) {
    display: none;
  }
}

.feauture-slideshow__slide-title-mobile {
  margin-bottom: $spacing-xxxs;

  @include mq(homeFeatureSlideshowNav) {
    display: none;
  }
}

.feauture-slideshow__slide-cta {
  margin-top: $spacing-xxs;
}

.feature-slideshow__slide-visual {
  margin: $spacing-s auto;
  @include aspect-ratio(2880, 1642);

  @include mq($until: homeFeatureSlideshowNav) {
    margin: 0 auto;
  }
}

.feature-slideshow__slide-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left:  0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature-slideshow__slide-image {
  position: absolute;
  width: 100%;
  top: 0;
}
