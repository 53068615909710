// =============================================================================
// Demos Index Page Layouts
// =============================================================================

.l-demos-index {
  .l-page-intro {
    margin-top: 50px;
    padding-top: $spacing-m;
    padding-bottom: $spacing-m;
    border-bottom: 1px solid $color-border-2;

    @include mq($until: medium) {
      margin-top: 30px;
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  .l-section {
    margin-top: 100px;

    @include mq($until: medium) {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }

  .l-form-container {
    border-top: 1px solid $color-border-2;

    @include mq(medium) {
      margin-top: $spacing-m;
      padding-top: $spacing-m;
    }
  }
}

// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.l-demos-index {
  .l-form-container.is-visually-hidden {
    display: block;
    @include hide-visually;
  }

  .l-form {
    @include mq($until: medium) {
      padding-top: 30px;
    }
  }

  .l-form__header {
    .body-s {
      margin-top: $spacing-xxs;
    }
  }
}
