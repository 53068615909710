// =============================================================================
// Post Layout
// =============================================================================

.l-post {
  margin-top: $spacing-s;
}

.l-post__intro,
.l-post__text,
.l-post__tip,
.l-post__article-list,
.l-post__blockquote-list-slide {
  max-width: 640px;
  margin: auto;
  width: 100%;

  @include mq(medium) {
    width: 80%;
  }

  @include mq(xlarge) {
    max-width: 780px;
  }
}

.l-post__blockquote-list-slide {
  width: 100%;
  padding-left: 7.5%;

  @include mq(medium) {
    width: 80%;
    padding-left: 5%;
  }

  @include mq(large) {
    padding-left: 0;
  }

  @include mq(xlarge) {
    max-width: 780px;
  }
}

.l-post--no-footer {
  margin-bottom: $spacing-l;
}

.l-post__media,
.l-post__embed,
.l-post__img {
  margin-top: $spacing-xxs;

  @include mq(medium) {
    margin-top: $spacing-xs;
  }

  @include mq(xlarge) {
    margin-top: $spacing-s;
  }
}

.l-post__tip {
  margin: $spacing-xs auto;

  @include mq(large) {
    margin: $spacing-xs auto;
    width: 100%;
  }
}

.l-post__embed {

  twitterWidget,
  iframe {
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.l-post__article-list {
  border-top: 1px solid $color-border-2;
  margin-top: $spacing-xxs;
  margin-bottom: $spacing-xxs;
}

.l-post__footer {
  padding: $spacing-s;
  margin-top: $spacing-l;
  position: relative;
  border-top: 1px solid $color-border-2;
  position: relative;

  @include mq($until: medium) {
    padding-left: 0;
    padding-right: 0;
    margin-top: $spacing-m;
  }

  & + .l-post__prev-next {
    margin-top: 0;
  }
}

.l-post__bio {
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;

  @include mq($until: medium) {
    text-align: center;
  }
}

.l-post__bio-photo {
  display: flex;
  align-items: center;
  align-content: center;
  width: 120px;
  height: 120px;
  margin-right: $spacing-xxs;
  background-color: $color-border-3;
  border-radius: 100%;

  > img {
    border-radius: 100%;
    width: 120px;
    height: 120px;
  }

  @include mq($until: medium) {
    margin: auto;
    margin-bottom: $spacing-xxs;
  }
}

.l-post__bio-text {
  @include body-s;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;

  p {
    width: 100%;
  }

  strong {
    color: $color-black;
  }

  @include mq(medium) {
    width: calc(100% - 145px);
  }
}

.l-post__bio-twitter {
  @include body-xxs;
  color: $color-blue;
  margin-top: $spacing-xxxs;
  display: flex;
  align-items: center;
  align-content: center;

  @include mq($until: medium) {
    justify-content: center;
    width: 100%;
  }

  img {
    display: inline-block;
    width: 30px;
    height: auto;
    vertical-align: middle;
  }
}

.l-post__prev-next {
  @include heading-xs;
  color: $color-blue;
  position: relative;
  width: 100%;
  padding: $spacing-s 0;
  display: flex;
  align-items: center;
  align-content: center;
  margin-top: $spacing-l;

  // Stretch background
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 200vw;
    background-color: $color-grey-4;
    bottom: 0;
    top: 0;
    z-index: -1;
    left: -50vw;
  }

  a {
    position: relative;
    display: inline-block;
  }

  svg {
    display: inline-block;
  }
}

.l-post__prev {
  width: 33.333%;

  svg {
    transform: rotate(180deg);
  }
}

.l-post__prev-next-home {
  width: 33.333%;
  text-align: center;
  color: $color-blue-dark;
}

.l-post__next {
  width: 33.333%;
  text-align: right;
}



// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.l-post__img--2-up:not(.l-post__block--w-background),
.l-post__img--wide {
  width: 120%;
  margin-left: -10%;
}

.l-post__img--2-up,
.l-post__img--wide {
  @include mq($until: xlarge) {
    padding-left: 7.5vw;
    padding-right: 7.5vw;
  }
}

.l-post__block--w-background {
  background-color: $color-grey-4;
  padding: $spacing-s 0 $spacing-s;
  position: relative;

  // Stretch background
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 200vw;
    background-color: $color-grey-4;
    bottom: 0;
    top: 0;
    z-index: -1;
    left: -50vw;
  }
}

.l-post--itf {
  margin-top: 0;

  @include mq(large) {

    .l-post__intro,
    .l-post__text,
    .l-post__code {
      max-width: 720px;
      margin-right: 0;
      width: 90%;

      @include mq(xlarge) {
        max-width: 840px;
      }
    }

    .l-post__img--2-up {
      width: 166.666%;
      margin-left: -66.666%;
    }
  }
}

.l-post--kb {
  margin-top: $spacing-xxs;

  @include mq(medium) {
    margin-top: $spacing-xs;
  }

  .l-post__intro,
  .l-post__text,
  .l-post__code,
  .l-post__tip {

    @include mq(large) {
      max-width: 640px;
      margin-left: 0;
      width: 100%;
    }

    @include mq(xlarge) {
      max-width: 780px;
    }
  }
}

.l-post--features,
.l-post--kb,
.l-post--itf {

  .l-post__block--w-background {
    background-color: $color-grey-4;
    padding: $spacing-xxs 0;
    position: relative;

    @include mq(medium) {
      padding: $spacing-s 0;
    }

    @include mq(large) {
      padding: $spacing-s;

      &:after {
        display: none;
      }
    }
  }
}

.l-post--itf {

  .l-post__block--w-background {

    @include mq(large) {

      &:after {
        display: block;
      }
    }
  }
}

// -----------------------------------------------------------------------------
// Adjacent Spacing
// -----------------------------------------------------------------------------


* + .l-post__text {
  margin-top: $spacing-xxs;

  @include mq(medium) {
    margin-top: $spacing-xs;
  }

  @include mq(xlarge) {
    margin-top: $spacing-s;
  }

  h1, h2 {

    &:first-of-type {
      margin-top: $spacing-s;
    }
  }
}

.l-post + .l-post {
  margin-top: $spacing-m;
  padding-top: $spacing-m;
  border-top: 2px solid $color-border-2;

  @include mq(large) {
    margin-top: $spacing-l;
    padding-top: $spacing-l;
  }
}

.l-post__intro + * {
  margin-top: $spacing-s;
}

.l-post__tip + * {
  margin-top: $spacing-xs;

  @include mq(large) {
    margin-top: $spacing-xs;
  }
}

.blurb + .l-post__text {
  margin-top: $spacing-xs;

  @include mq(large) {
    margin-top: $spacing-s;
  }
}
