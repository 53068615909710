@use 'sass:math';

// =============================================================================
// Meetups Grid
// =============================================================================

.l-meetups-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin-top: $spacing-m;

  .card-a,
  .card-a__inner {
    height: 100%;
  }
}

.l-meetups-grid__block {
  width: 100%;

  @include mq($until: small) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    &:nth-of-type(n+2) {
      margin-top: $spacing-xs;
    }
  }

  @include mq($from: small, $until: medium) {
    width: calc(50% - #{$gutter-l - math.div($gutter-l, 2)});
    margin-left: $gutter-l;

    &:nth-of-type(odd) {
      margin-left: 0;
    }

    &:nth-of-type(n+3) {
      margin-top: $gutter-l;
    }
  }

  @include mq(medium) {
    width: calc(33.333% - #{$gutter-l - math.div($gutter-l, 3)});
    margin-left: $gutter-l;

    &:nth-of-type(3n+1) {
      margin-left: 0;
    }

    &:nth-of-type(n+4) {
      margin-top: $gutter-l;
    }
  }
}
