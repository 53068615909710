// =============================================================================
// Card A
// =============================================================================
//
// CTA cards with a single centered stacking column of information.
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.card-a {
  @include card-outer();
  display: block;
  width: 100%;
  max-width: 380px;
  margin: auto;

  @include mq(large) {
    max-width: 540px;
  }
}

.card-a__inner {
  @include card-inner();
}

.card-a__content {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 100px $spacing-xxs;

  @include mq(large) {
    padding: 120px $spacing-xs;
  }
}

.card-a__icon {
  width: 50%;
  height: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  svg,
  img {
    display: inline-block;
    width: auto;
    height: 100%; 
    width: auto;
  }
}

.card-a__link {
  margin-top: $spacing-xs;
  width: 100%;
}

.card-a__heading {
  margin-top: 15px;
  width: 100%;
}


// -----------------------------------------------------------------------------
// Border
// -----------------------------------------------------------------------------

.card-a--border {

  .card-a__inner {
    
    &:before {
      border: 1px solid $color-grey-3;
      transition: transform $ease-out-expo 0.5s, border $ease-out-expo 0.5s;
    }
  }

  &:hover {

    .card-a__inner {
      
      &:before {
        border-color: $color-grey-4;
      }
    }
  }
}


// -----------------------------------------------------------------------------
// Small
// -----------------------------------------------------------------------------

.card-a--s {

  .card-a__content {
    padding: $spacing-s $spacing-xxs;
    min-width: 18vw;

    @include mq(xxlarge) {
      min-width: initial;
    }
  }

  .card-a__icon {
    width: 100px;
    height: 80px;
    text-align: center;

    svg,
    img {
      display: inline-block;
    }
  }

  .card-a__link {
    margin-top: $spacing-xxs;
    width: 100%;
  }
}


// -----------------------------------------------------------------------------
// XSmall
// -----------------------------------------------------------------------------

.card-a--xs {

  .card-a__content {
    padding: $spacing-s $spacing-xs;
    min-width: 15vw;

    @include mq(xxlarge) {
      min-width: initial;
    }
  }

  .card-a__icon {
    width: 160px;
    height: 60px;
    text-align: center;

    svg,
    img {
      display: inline-block;
    }
  }

  .card-a__link {
    margin-top: $spacing-xxs;
    width: 100%;
  }
}



// -----------------------------------------------------------------------------
// XXSmall
// -----------------------------------------------------------------------------

.card-a--xxs {

  .card-a__content {
    padding: $spacing-xs $spacing-xxxs;
    min-width: 15vw;

    @include mq(xxlarge) {
      min-width: initial;
    }
  }

  .card-a__icon {
    width: 80px;
    height: 60px;
    text-align: center;

    svg,
    img {
      display: inline-block;
    }
  }

  .card-a__link {
    margin-top: $spacing-xxxs;
    width: 100%;
  }
}

.card-a__footer {
  @include body-s;
  color: $color-blue;
  margin-top: $spacing-xxs;

  @include mq(medium) {
    position: absolute;
    margin-top: 0;
    bottom: 30px;
    opacity: 0;
    transition: opacity 0.5s $ease-out-expo, transform 0.5s $ease-out-expo;
  }
}


// -----------------------------------------------------------------------------
// Craft 3 Card Special
// -----------------------------------------------------------------------------

.card-a--craft-3 {

  .card-a__icon {
    height: 100px;
    width: 100px;
    overflow: hidden;
    position: relative;
    
    // Covers an exposed line in IE Edge
    &:before {
      content: "";
      position: absolute;
      right: -2px;
      width: 4px;
      top: 0;
      bottom: 0;
      background-color: white;
      z-index: 1;
    }

    &:after {
      content: "";
      position: absolute;
      top: 1px;
      left: -170px;
      right: 1px;
      bottom: 1px;
      background: $gradient-blue-to-red;
      z-index: 0;
      transition: transform $ease-out-expo 0.5s;
    }

    svg,
    img {
      position: relative;
      z-index: 1;
    }
  }

  &:hover {

    .card-a__icon::after {
      transform: translateX(70px);
    }
  }
}


// -----------------------------------------------------------------------------
// W/ Footer
// -----------------------------------------------------------------------------

.card-a--w-footer {

  .card-a__icon,
  .card-a__link {
    transition: transform 0.5s $ease-out-expo, width 0.5s $ease-out-expo;
  }

  @include mq(medium) {
    &:hover {

      .card-a__icon,
      .card-a__link,
      .card-a__footer {
        transform: translateY(-25px);
        opacity: 1;
      }

      .card-a__footer {
        transition: opacity 0.3s $ease-out-expo 0.1s, transform 0.3s $ease-out-expo 0.1s;
      }
    }
  }
}


// -----------------------------------------------------------------------------
// Contained Objects
// -----------------------------------------------------------------------------

.card-a {
  &:hover {
    z-index: 1;

    .card-a__link .link {

      // Special Case for Showing Link within Link Hover State
      &:before {
        width: 100%;
      }
    }
  }
}
