// =============================================================================
// Breadcrumbs
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.breadcrumbs {
  display: flex;
  padding: 0;
}

.breadcrumbs__crumb {
  @include heading-xs;
  margin-right: 16px;
  
  &:before {
    content: "";
    display: inline-block;
    margin-right: 6px;
    height: 12px;
    width: 12px;
    border: 2px solid $color-text-light;
    transform: rotate(135deg);
    border-top: 0px;
    border-left: 0px;
  }

  &:after {
    content: "";
    display: inline-block;
    margin-left: 12px;
    height: 12px;
    width: 12px;
    border: 2px solid $color-text-light;
    transform: rotate(-45deg);
    border-top: 0px;
    border-left: 0px;
  }

  &:last-of-type {
    color: $color-text;

    &:after {
      display: none;
    }
  }

  &:first-of-type {
    color: $color-black;
  }
  
  @include mq(small) {
    &:before {
      display: none;
    }
  }

  @include mq($until: small) {

    &:nth-of-type(n+2) {
      display: none;
    }

    &:after {
      display: none;
    }
  }
}