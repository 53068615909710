// =============================================================================
// Accessibility Entry & Index Page Layouts
// =============================================================================



// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.l-accessibility-index {

	.l-section {
		margin-top: 100px;

		@include mq($until: medium) {
			margin-top: 30px;
			margin-bottom: 50px;
		}
	}

	.card-b {
		width: 100%;
		max-width: 840px;

		&:hover,
		&:focus {
			z-index: 1;

			.news-blurb__link {
				transform: translateY(0);
				opacity: 1;
			}
		}

		@include mq($until: medium) {
			&:not(:last-of-type):after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 20px;
				z-index: 2;
				width: calc(100% - 40px);
				height: 1px;
				background-color: $color-border-2;
			}
		}
	}

	.card-b__content {
		align-items: flex-start;
		max-width: 100%;

		@include mq(medium) {
			flex-wrap: nowrap;
		}
	}

	.article__date {
		width: 200px;
		padding-top: 3px;
		font-size: 19px;
		color: $color-text;

		@include mq($until: medium) {
			width: 100%;
			padding-bottom: 15px;
			font-size: 16px;
		}
	}

	.article__info {
		width: 100%;
	}

	.news-blurb__author-name {
		color: $color-grey-1;
	}
	
}
