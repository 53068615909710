@use 'sass:math';

// =============================================================================
// Partners Grid
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.l-partners-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: $spacing-l;
  transition: opacity 0.5s $ease-out-expo;
  margin-left: auto;
  margin-right: auto;

  &.is-loading {
    opacity: 0.5;
  }

  @include mq($until: partnersGridM) {
    max-width: 480px;
  }

  @include mq($from: partnersGridM, $until: partnersGridL) {
    max-width: 960px;
  }
}

.l-partners-grid__block {

  @include mq($until: partnersGridM) {
    width: 100%;

    & + .l-partners-grid__block {
      margin-top: $gutter-partners;
    }
  }

  @include mq($from: partnersGridM, $until: partnersGridL) {
    width: calc(50% - #{$gutter-partners - math.div($gutter-partners, 2)});
    margin-left: $gutter-partners;

    &:nth-of-type(2n+1) {
      margin-left: 0;
    }

    &:nth-of-type(n+3) {
      margin-top: $gutter-partners;
    }
  }

  @include mq(partnersGridL) {
    width: calc(33.333% - #{$gutter-partners - math.div($gutter-partners, 3)});
    margin-left: $gutter-partners;

    &:nth-of-type(3n+1) {
      margin-left: 0;
    }

    &:nth-of-type(n+4) {
      margin-top: $gutter-partners;
    }
  }
}

.l-partners-grid__msg {
  @include heading-xs;
  color: $color-text-light;
  margin-top: $spacing-s;
  width: 100%;
  text-align: center;
}

.l-partners-grid__placeholder {
  min-height: 100vh;
}
