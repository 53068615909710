// =============================================================================
// Styleguide
// =============================================================================
/*
   Styling for the site's styleguide (located at http://craftcms.com/styleguide)
*/

.styleguide {}

.styleguide__section {

  & + .styleguide__section {
    margin-top: $spacing-xxl;
    border-top: 4px solid $color-border-3;
    padding-top: $spacing-xxl;
  }
}

.styleguide__section-heading {
  margin-bottom: $spacing-m;
  border-bottom: 1px solid $color-border-1;
  padding-bottom: $spacing-xs;
  color: $color-grey-1;
}

.styleguide__subsection {

  & + .styleguide__subsection {
    margin-top: $spacing-l;
  }
}

.styleguide__subsection-heading {
  margin-bottom: $spacing-s;
  color: $color-grey-1;
}

.styleguide__item {
  border-left: 4px solid $color-border-3;
  padding: $spacing-xs 0 $spacing-xs $spacing-s;

  & + .styleguide__item {
    margin-top: $spacing-m;
  }
}

.styleguide__item-name {
  font-size: 18px;
  color: $color-grey-1;
  margin-bottom: $spacing-xs;
}

.styleguide__item-description {
  font-size: 18px;
  color: $color-grey-1;
  margin-top: $spacing-xs;
}

.styleguide__item-render {
  display: inline-block;
}

.styleguide__item-code-wrapper {
  margin-top: $spacing-xs;
  position: relative;
}

.styleguide__clipboard-btn {
  @include unbutton;
  background: $color-white;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  position: absolute;
  right: 15px;
  bottom: -30px;
  text-align: center;
  transition: transform $ease-out-expo 0.5s;
  cursor: pointer;

  &:before {
    content: "";
    position: absolute;
    top: 13px;
    left: 8px;
    right: 8px;
    bottom: 0px;
    height: 47px;
    width: 44px;
    box-shadow: $shadow-basic;
    border-radius: 100%;
    transition: transform $ease-out-expo 0.5s;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 60px;
    width: 60px;
    background-color: $color-white; 
    border-radius: 100%;
  }

  svg {
    width: 25px;
    height: auto;
    display: inline-block;
    vertical-align: middle;
  }

  &:hover {
    transform: translateY(-5px) scale(1.1);

    &:before {
      transform: scale(0.9);
    }
  }

  &:focus {
    transform: translateY(0px) scale(1);
  }
}

.styleguide__clipboard-btn-inner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 60px;
  width: 60px;
  z-index: 1;
  text-align: center;
  line-height: 60px;
}


// -----------------------------------------------------------------------------
// Output Element on Grey Background
// -----------------------------------------------------------------------------

.styleguide__item-render--on-grey {
  padding: $spacing-s;
  background-color: $color-grey-4;
}


// -----------------------------------------------------------------------------
// Full-Width
// -----------------------------------------------------------------------------

.styleguide__item-render--full-width {
  width: 100%;
}