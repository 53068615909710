// =============================================================================
// Quotes
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.quote {
  margin: $spacing-xs 0 0;
  text-align: center;
}

.quote__logo {
  max-width: 140px;
  margin: auto;
  height: 90px;
  display: flex;
  align-content: center;
  align-items: center;

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
    margin: auto;
    align-content: flex-end;
    align-items: flex-end;
  }
}

.quote__quote {
  margin-top: $spacing-xs;
  margin-bottom: $spacing-xs;
}

.quote__author-photo {
  height: 80px;
  width: 80px;
  border-radius: 100%;
  overflow: hidden;
  margin: auto;
  margin-bottom: $spacing-xxxs;
}

.quote__author {

  > p {
    color: $color-black;
  }
}

.quote__author-title {

  @include mq(medium) {
    margin-top: 6px;
  }
}


// -----------------------------------------------------------------------------
// When contained in a slideshow
// -----------------------------------------------------------------------------

.basic-slideshow__slide {

  .quote {
    opacity: 0.5;
    transform: translateY(20px);
    transition: transform 0.5s $ease-out-expo, opacity 0.5s $ease-out-expo;
  }

  &.is-selected {

    .quote {
      opacity: 1;
      transform: translateY(0px);
    }

  }
}
