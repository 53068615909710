// =============================================================================
// Team Member Block
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.team-block {
  text-align: center;
}

.team-block__name {
  @include heading-xs;
  margin-top: $spacing-xs;
}

.team-block__title {
  @include heading-xs;
  margin-top: 8px;
  color: $color-text-light;
}

.team-block__bio {
  @include body-xs;
  margin-top: $spacing-xxs;
}