// =============================================================================
// Card D
// =============================================================================
//
// Cards that contain an image, logo, and some text below. Used for case studies.
//


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.card-d {
  display: flex;
  flex-wrap: wrap;
  max-width: 460px;
  width: 100%;
  position: relative;
  z-index: 0;

  @include mq($until: itfGridL) {
    margin: auto;
  }

  @include mq(large) {
    &:hover {
      z-index: 3;
      position: relative;

      .card-d__link {
        transform: translateY(0);
        opacity: 1;
        transition: transform $ease-out-expo 0.8s 0.15s, opacity $ease-out-expo 0.8s 0.15s;
      }

      .card-d__img {
        transform: translateZ(0) scale(1.08) translateY(-10px);

        > img {
          transform: translateZ(0) scale(0.8);
        }
      }

      .card-d__img-and-logo {

        &:after {
          opacity: 1;
          transform: scale(1.08) translateY(-10px);
        }
      }
    }
  }
}

.card-d__img-and-logo {
  position: relative;
  width: 100%;

  &:after {
    content: "";
    position: absolute;
    top: 25px;
    left: 25px;
    right: 25px;
    bottom: 0px;
    box-shadow: $shadow-xl;
    pointer-events: none;
    z-index: 0;
    opacity: 0;
    transition: all $ease-out-expo 0.8s;
  }
}

.card-d__loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left:  0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-d__img {
  @include aspect-ratio(1,1.4);
  transition: transform $ease-out-expo 0.8s;
  overflow: hidden;
  z-index: 2;

  > img {
    position: absolute;
    width: 130%;
    max-width: 200%;
    top: 0;
    left: -15%;
    top: -15%;
    transform: translateZ(0);
    transition: transform $ease-out-expo 1s, opacity linear 0.3s;
    opacity: 0;

    &.lazyloaded {
      opacity: 1;
    }
  }
}

.card-d__logo {
  position: absolute;
  bottom: 8%;
  left: 12%;
  width: 60%;
  max-width: 200px;
  
  img {
    max-height: 100%;
  }
}

.card-d__title {
  margin-top: $spacing-xxxs;
  font-size: 24px;
  line-height: 1.14;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(medium) {
    font-size: 28px;
  }
}

.card-d__byline {
  margin-top: $spacing-xxs;
  font-size: 18px;
  line-height: 1.14;
  color: $color-text-light;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(medium) {
    font-size: 21px;
    margin-top: $spacing-xs;
  }
}

.card-d__link {
  margin-top: 18px;

  @include mq(large) {
    transform: translateY(10px);
    opacity: 0;
    transition: transform $ease-out-expo 0.8s 0s, opacity $ease-out-expo 0.8s 0s;
    margin-top: $spacing-xxs;
  }
}
