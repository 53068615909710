// =============================================================================
// Radio Fields
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.radio {
  margin-top: $spacing-xxs;
}

.radio__label {
  @include body-s;
  color: $color-black;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
}

.radio > input {
  @include hide-visually;

  &:checked {

    & + label {
      background: $gradient-blue-to-purple;
    }
  }

  &:focus + label {
    outline: 2px solid $color-blue;
  }
}

.radio__styled-label {
  display: inline-block;
  cursor: pointer;
  margin-right: $spacing-xxxs;
  height: 40px;
  width: 40px;
  border-radius: 40px;
  border: 1px solid $color-grey-2;
  background-color: $color-white;
  transition: background-color $ease-out-expo 0.5s, border $ease-out-expo 0.5s;
  vertical-align: middle;
  position: relative;

  &:after {
    content: "";
    background: $color-white;
    border-radius: 18px;
    height: 18px;
    width: 18px;
    top: 10px;
    left: 10px;
    position: absolute;
  }

  &:hover {
    background-color: $color-grey-3;
    border-color: $color-grey-3;
  }
}

.radio__input {
  margin-top: $spacing-xxxs;

  > input,
  > textarea {
    @include resetInputStyle;
    @include body-s;
    padding: 18px;
    border-radius: 4px;
    color: $color-black;
    min-width: 420px;
    border: 1px solid $color-grey-2;
    background-color: $color-white;
    transition: background-color $ease-out-expo 0.5s, border $ease-out-expo 0.5s;
    resize: none;

    &:focus {
      background-color: $color-grey-4;
      border-color: $color-grey-4;
    }
  }
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------
