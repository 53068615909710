// =============================================================================
// Edition Logo
// =============================================================================
//
// Edition Logo, intended to roughly match the edition logo in Craft itself.
// Used for Card E pricing tables.
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------
.edition-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
}

.edition-logo--highlight {
  .edition-logo__label, 
  .edition-logo__label-qualifier {
    color: $color-red;
    border-color: $color-red;
    svg {
      color: $color-red;
    }
  }
}

.edition-logo--small {
  .edition-logo__label-qualifier {
    width: 26px;
    transform: translateY(-1px);
  }

  .edition-logo__label,
  .edition-logo__label-qualifier,
  .edition-logo__label-qualifier > svg {
    height: 26px;
  }

  .edition-logo__label-qualifier > svg > path {
    stroke-width: 2.7;
  }


  .edition-logo__label {
    letter-spacing: 0.1275em;
    font-size: 12px;
    line-height: 1.1;
  
    // Slightly more padding on top and left to account for typeface metrics,
    // letter spacing.
    padding: 0.375em 0.45em 0.3375em 0.5775em;
  }
}

.edition-logo__label,
.edition-logo__label-qualifier {
  border: 2px solid;
  color: $color-grey-1;
  border-radius: 0.27em;
  height: 34px;
  transform: translateY(-0.1em);
}

.edition-logo__label {
  display: inline-block;
  padding: 0;
  text-transform: uppercase;
  letter-spacing: 0.17em;
  font-weight: 600;
  line-height: 0.95;

  font-size: 16px;

  // Slightly more padding on left to account for typeface metrics.
  // Remove all padding to see there is always more space on the right.
  padding: 0.5em 0.6em 0.5em 0.77em;
}

// Cloud icon that can sit alongside the icon
.edition-logo__label-qualifier {
  padding: 0;
  margin-left: 5px;

  svg {
    transform: translateY(-2px);
    color: $color-grey-1;
    width: 33px;
    height: 33px;  
    stroke-width: 2px;
  }
}
