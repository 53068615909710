// =============================================================================
// Blurb
// =============================================================================
//
// The common type lockups used throughout the site
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.blurb {
  text-align: center;
  break-inside: avoid;

  & + .blurb {
    margin-top: 2em;
  }
}

.blurb__icon {
  height: 60px;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: $spacing-xxs;

  > svg,
  > img {
    height: 100%;
    width: auto;
    display: inline-block;
  }
}

.blurb__subheading {
  @include heading-s;
  color: $color-text-light;
  margin-bottom: $spacing-xxxs;
}

.blurb__body {
  @include formatting;
  @include basicLinks;
  margin-top: 0.5em;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;

  p + p {
    margin-top: $spacing-xs;
  }
}

.blurb__link {
  margin-top: $spacing-xs;

  @include mq($until: small) {
    .btn + .btn {
      margin-top: $spacing-xxxs;
      margin-left: 0;
    }
  }
}

.blurb__img {
  margin-bottom: $spacing-xs;
}


// -----------------------------------------------------------------------------
// XS
// -----------------------------------------------------------------------------

.blurb--xs {

  @include mq($until: medium) {
    max-width: 420px;
    margin: auto;
  }

  .blurb__icon {
    height: 48px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $spacing-xxs;

    > svg,
    > img {
      height: 100%;
      width: auto;
    }
  }

  .blurb__heading {
    @include heading-xxs;
  }

  .blurb__body {
    @include body-xs;
  }
}


// -----------------------------------------------------------------------------
// S
// -----------------------------------------------------------------------------

.blurb--s {
  max-width: 450px;
  margin: auto;

  .blurb__img {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $spacing-xxs;
  }

  .blurb__heading {
    @include heading-s;
  }

  .blurb__body {
    @include body-s;
    margin-top: $spacing-xxxs;
  }
}

.blurb--s-img {

  .blurb__img {
    width: 60%;
    max-width: 210px;
  }
}


// -----------------------------------------------------------------------------
// M
// -----------------------------------------------------------------------------

.blurb--m {

  .blurb__icon {
    height: 60px;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $spacing-xxs;

    > svg,
    > img {
      height: 100%;
      width: auto;
    }
  }

  .blurb__heading {
    @include heading-m;
  }

  .blurb__body {
    @include body-s;
  }
}


// -----------------------------------------------------------------------------
// L
// -----------------------------------------------------------------------------

.blurb--l {
  max-width: 720px;
  margin: auto;

  @include mq(xlarge) {
    max-width: 860px;
  }

  .blurb__heading {
    @include heading-l;
  }

  .blurb__body {
    @include body-m;
    margin: $spacing-xxxs auto 0;
    max-width: 640px;

    @include mq(medium) {
      margin-top: $spacing-xxs;
    }
  }
}


// -----------------------------------------------------------------------------
// XL
// -----------------------------------------------------------------------------

.blurb--xl {
  max-width: 960px;
  margin: auto;

  .blurb__heading {
    @include heading-xl;
  }

  .blurb__body {
    @include body-l;
    margin-top: $spacing-xs;
  }
}

// -----------------------------------------------------------------------------
// XL Alt (Heading XL - Body M)
// -----------------------------------------------------------------------------

.blurb--xl-alt {
  max-width: 720px;
  margin: auto;

  .blurb__heading {
    @include heading-xl;
  }

  .blurb__intro {
    @include heading-m;
    margin: $spacing-xs auto 0;
  }

  .blurb__body {
    @include body-m;
    margin: $spacing-xs auto 0;
  }
}


// -----------------------------------------------------------------------------
// Alignment Helpers
// -----------------------------------------------------------------------------

.blurb--align-left {
  text-align: left;
  margin-left: 0;

  .blurb__heading {
    margin-left: 0;
  }

  .blurb__body {
    margin-left: 0;
  }
}

.blurb--align-center-mobile {

  @include mq($until: medium) {
    text-align: center;

    .blurb__heading {
      margin-left: auto;
      margin-right: auto;
    }

    .blurb__body {
      margin-left: auto;
      margin-right: auto;
    }
  }

}


// -----------------------------------------------------------------------------
// Space Below
// -----------------------------------------------------------------------------

.blurb--space-below {
  margin-bottom: $spacing-s;
}


// -----------------------------------------------------------------------------
// Micro Size Icon
// -----------------------------------------------------------------------------

.blurb--micro-icon {

  .blurb__icon {
    height: 30px;
  }
}


// -----------------------------------------------------------------------------
// Logo List
// -----------------------------------------------------------------------------

.blurb--logo-list {
  max-width: none;

  .blurb__heading {
    max-width: 720px;
    margin-left: auto;
    margin-right: auto;
  }

  .blurb__logos,
  .blurb__body {
    margin-top: 30px;

    @include mq(medium) {
      margin-top: 44px;
    }
  }
}


// -----------------------------------------------------------------------------
// Alt Links (underline vs. color)
// -----------------------------------------------------------------------------

.blurb--alt-links {

  .blurb__body a {
    color: $color-text;
    border-bottom: 1px solid $color-text;

    &:hover {
      color: $color-blue;
      border-color: $color-blue;
    }
  }
}


// -----------------------------------------------------------------------------
// Contained in Modal
// -----------------------------------------------------------------------------

.modal {

  .blurb--xs {

    @include mq($until: medium) {
      max-width: 100%;
    }
  }
}

