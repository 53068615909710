// =============================================================================
// Two Up Blocks
// =============================================================================

.l-two-up {
  width: 100%;
  margin: auto;
}

.l-two-up__inner {
  max-width: $site-max-width-outer;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.l-two-up__content {
  margin: auto;
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;

  @include mq(medium) {
    width: 90%;
  }
}

.l-two-up__col {
  width: 100%;
  padding: $spacing-m 0;

  @include mq(small) {
    padding: $spacing-m $spacing-xs;
  }

  @include mq(medium) {
    padding: $spacing-m $spacing-m;
  }

  @include mq(large) {
    padding: $spacing-l $spacing-m;  
    width: 50%;
    position: relative;
  }

  @include mq(xxlarge) {
    padding: $spacing-xl $spacing-m;  
  }
}


// -----------------------------------------------------------------------------
// Bordered
// -----------------------------------------------------------------------------

.l-two-up--bordered {
  border-top: 2px solid $color-grey-4;
  border-bottom: 2px solid $color-grey-4;

  .l-two-up__col {
    position: relative;

    &:nth-of-type(even) {

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: -10vw;
        top: -1px;
        height: 2px;
        width: 120vw;
        background-color: $color-grey-4;
      }
    }

    @include mq(large) {

      &:nth-of-type(even) {

        &:after {
          left: -1px;
          top: 0;
          bottom: 0;
          width: 2px;
          height: 100%;
        }
      }
    }
  }
}


// -----------------------------------------------------------------------------
// Filled
// -----------------------------------------------------------------------------

.l-two-up--filled {
  background-color: $color-grey-4;
  position: relative;

  .l-two-up__col {

    &:nth-of-type(even) {
      position: relative;

      &:after {
        content: "";
        display: block;
        position: absolute;
        left: -10vw;
        top: 0;
        height: 4px;
        width: 110vw;
        background-color: $color-white;

        @include mq(large) {
          left: 0;
          margin-left: -2px;
          bottom: 0;
          height: 120%;
          top: -10%;
          width: 4px;
        }
      }
    }
  }
}

.l-two-up--filled-right {

  .l-two-up__col {

    &:nth-of-type(even) {
      background-color: $color-grey-4;
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: -10vw;
        width: 110vw;
        background-color: $color-grey-4;
        z-index: -1;

        @include mq(large) {
          left: 100%;
          width: 100vw;
        }
      }
    }
  }
}


// -----------------------------------------------------------------------------
// Vertically Centered
// -----------------------------------------------------------------------------

.l-two-up--vertically-centered {

 .l-two-up__content {
    align-items: center;
 } 
}


// -----------------------------------------------------------------------------
// Less Padding
// -----------------------------------------------------------------------------

.l-two-up--less-padding {

  .l-two-up__col {
    // width: 50%;
    position: relative;

    @include mq(medium) {
      padding: $spacing-m $spacing-s;
    }

    @include mq(large) {
      padding: $spacing-l $spacing-s;  
    }

    @include mq(xxlarge) {
      padding: $spacing-xl $spacing-s;  
    }
  }
}


// -----------------------------------------------------------------------------
// Contained in Modal
// -----------------------------------------------------------------------------

.modal {

  .l-two-up__col {
    padding: $spacing-m $spacing-xs;
  }

  .l-two-up__content {
    width: 100%;
  }
}