.animation-wrapper {
    box-shadow: none;
    display: block;
    overflow: hidden;
    line-height: 0;
    position: relative;
    transition: box-shadow $ease-out-expo 0.5s, transform $ease-out-quart 0.25s;
    transform: scale(0.97);
    margin: 0 -1.5%;

    &::before {
        display: block;
        content: "";
        padding-top: 56.25%;
    }

    &.stopped {
        .replay-icon {
            opacity: 1;
        }

        .play-icon {
            opacity: 0;
        }
    }

    &.playing {
        .play-icon {
            opacity: 0;
        }
    }

    .play-replay {
        display: block;
        z-index: 10;
        position: absolute;
        width: 100%;
        height: 100%;
        border: none;
        line-height: normal;
        padding: 0;
        background: transparent;
        opacity: 0;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        cursor: pointer;
    }

    .replay-icon,
    .play-icon {
        z-index: 11;
        cursor: default;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        transition: opacity 750ms;
        opacity: 0;
        pointer-events: none;
    }

    .play-icon {
        opacity: 1;
    }

    iframe {
        z-index: 5;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}
