// =============================================================================
// Main Site Wrappers
// =============================================================================

.l-wrap {
  width: 100%;
  max-width: $site-max-width-outer;
  margin: auto;
}

.l-wrap-unset {
  // margin-left: calc(-56.25vw - 720px);
  // margin-right: calc(-56.25vw - 720px);

  // outline: 1px dashed green;

  @include mq(medium) {
    margin-left: calc(-52.5vw + 720px);
    margin-right: calc(-52.5vw + 720px);
    outline: 1px dashed blue;
  }
  
}

.l-wrap-unset__inner {
  /* 7.5/(85/100) */
  margin-left: -8.82352941%;
  margin-right: -8.82352941%;
  @include mq(medium) {
    /* 5/(90/100) */
    margin-left: -5.55555556%;
    margin-right: -5.55555556%;  
  }
  /* 1440/0.9 */
  // @media (min-width: 1600px) {
  //   margin-left: 0;
  //   margin-right: 0;
  // }
}

.l-wrap__inner {
  max-width: $site-max-width-inner;
  margin: auto;
  width: 85%;

  @include mq(medium) {
    width: 90%;
  }
}


// -----------------------------------------------------------------------------
// Narrow
// -----------------------------------------------------------------------------

.l-wrap--narrow {
  
  .l-wrap__inner {
    max-width: $site-max-width-narrow;
  }
}


// -----------------------------------------------------------------------------
// Extra Narrow
// -----------------------------------------------------------------------------

.l-wrap--extra-narrow {
  
  .l-wrap__inner {
    max-width: $site-max-width-narrower;
  }
}


// -----------------------------------------------------------------------------
// Most Narrow
// -----------------------------------------------------------------------------

.l-wrap--most-narrow {
  
  .l-wrap__inner {
    max-width: $site-max-width-narrowest;
  }
}


// -----------------------------------------------------------------------------
// W/ Spacing
// -----------------------------------------------------------------------------

.l-wrap--w-spacing {
  margin-top: $spacing-xl;
  margin-bottom: $spacing-xl;
}


// -----------------------------------------------------------------------------
// Contained in Modal
// -----------------------------------------------------------------------------

.modal {

  .l-wrap__inner {

    max-width: 100%;
    width: 100%;
    padding: 0 $spacing-xs;

    @include mq(large) {
      max-width: $site-max-width-inner;
      width: 90%;
      padding: 0 $spacing-s;
    }
  }
}
