// =============================================================================
// Post Intro
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.post-intro {
  text-align: center;
}

.post-intro__cat {
  @include eyebrow-l;
  color: $color-grey-1;
  display: inline-block;
  margin-bottom: $spacing-xxs;
  border-bottom: 1px solid transparent;
  transition: color 0.5s $ease-out-expo;

  &:hover {
    color: $color-blue;
  }
}

.post-intro__byline {

  display: flex;
  align-items: center;
  margin: $spacing-xxs auto;
  justify-content: center;
  white-space: nowrap;
  @include heading-xxs;

  @include mq(medium) {
    flex-wrap: wrap;
    @include heading-xs;
    font-weight: 400;
  }

  @include mq($until: medium) {
    flex-direction: column;
  }
}

.post-intro__author {
  display: flex;
  align-items: center;
}

.post-intro__byline-text,
.post-intro__author-photo {
  color: $color-grey-1;
}

.post-intro__author-photo {
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-top: 3px;
  margin-bottom: 3px;

  > img {
    height: 50px;
    width: 50px;
    max-width: unset;
    border-radius: 100%;
    display: inline-block;

    @include mq($until: medium) {
      height: 30px;
      width: 30px;
    }
  }

  & + .post-intro__author-name {
    margin-left: 8px;
  }
}

.post-intro__date {
  margin-left: 7px;
  color: $color-grey-1;

  @include mq($until: medium) {
    margin-left: 0;
    margin-top: 3px;
  }
}

.post-intro__thumb {
  margin-top: $spacing-s;
  width: 100%;
  position: relative;

  img {
    margin: auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.post-intro__thumb-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left:  0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}
