// =============================================================================
// Switch Fields (Really just glorified checkboxes)
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.switch {
  user-select: none;
  font-size: 16px;

  input {
    display: none;

    &:checked {

      & + .switch__toggle {
        background-color: $color-blue;
        
        &:after {
          transform: translateX(1.125em);
        }
      }
    }
  }
}

.switch__toggle { // This must be the checkbox <label>
  display: inline-block;
  height: 1.25em;
  width: 2.50em;
  border-radius: 1.125em;
  background-color: $color-grey-2;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  transition: background-color 0.5s $ease-out-expo 0.2s;

  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 1.375em;
    width: 1.375em;
    left: 0;
    top: -0.0625em;
    border-radius: 100%;
    background-color: $color-white;
    box-shadow: 0 2px 8px rgba($color-shadow, 0.3);
    transition: transform 0.3s $ease-out-expo;
  }
}

.switch__label {
  @include eyebrow-s;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.375em;
  user-select: none;
  cursor: pointer;
}

// A switch where the checked state isn’t necessarily “on,” ie. there
// should be no highlight colour, there are just two options.
.switch.switch--neutral {
  input {
    &:checked {
      & + .switch__toggle {
        background-color: $color-grey-2;
      }
    }
  }
}
