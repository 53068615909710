// =============================================================================
// Categories List
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.categories-list {}

.categories-list__category {
  @include eyebrow-s;
  display: inline-block;
  padding: 2px 18px 0;
  height: 74px;
  line-height: 74px;
  color: $color-text;
  position: relative;

  > a {
    height: 74px;
    line-height: 74px;
    display: inline-block;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: 100%;
    background: transparent;
    display: block;
  }
}

// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------

.categories-list__category.is-active {
  color: $color-black;

  &:after {
    background: $gradient-blue-to-purple;
  }
}