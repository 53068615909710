// =============================================================================
// Background Color Wrap
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.bg-wrap {
  background-color: $color-grey-4;
  overflow: hidden;
}
