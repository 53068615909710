// =============================================================================
// Tag
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.inline-tag {
  display: inline-block;
  padding: 4px 5px 3px;
  border: 1px solid $color-red;
  transform: translateY(-3px);
  border-radius: 4px;
  color: $color-red;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.04em;
  line-height: 1;
  vertical-align: middle;
  transition: background-color 0.5s $ease-out-expo;
}

*:hover > .inline-tag {
  background-color: rgba($color-red, 0.1);
}