// =============================================================================
// Rich Text
// =============================================================================
//
// Rich Text Formatting
//
// TODO This is effectively our “prose” class, so even if we fully move Craftcom over
// to Tailwind, this might be one of the last things to get ported. If it does
// get ported, the intent would be to turn this CSS into config for
// the @tailwind/typography plugin in the `tailwind.config.js` file, ex:
// https://github.com/tailwindlabs/tailwindcss-typography?tab=readme-ov-file#customizing-the-css//
//
// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.rich-text {
  @include formatting;
  @include basicLinks;

  mark {
    // background-color: rgb(211, 220, 255);
    // color: rgb(56, 58, 112);
    background-color: #fbe8e8;
    color: #310909;
  }

  strong {
    color: $color-black;
  }

  p {
    @include body-s;
  }

  p code,
  li code {
    background-color: transparent;
    font-size: 14px;
    font-family: $font-mono;
    color: $color-grey-1;
    display: inline-block;
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: initial;
  }

  p a code,
  li a code {
    color: $color-blue;
  }

  h1 {
    @include heading-m;
  }

  h2 {
    @include heading-s;
  }

  h3 {
    @include heading-xs;
  }

  h4, h5, h6 {
    @include heading-xxs;
  }

  h1, h2, h3, h4, h5, h6 {

    code {
      font-size: 0.8em;
    }
  }

  ul {
    margin-top: $spacing-xxs;
    padding-left: 24px;

    li {

      &:before {
        content: "•";
        position: absolute;
        left: 0;
        top: -5px;
        color: $color-blue;
        font-size: 1.4em;
      }
    }

    ol,
    ul {
      margin-top: 0;
    }
  }

  ol {
    margin-top: $spacing-xxs;
    padding-left: 24px;
    counter-reset: order-list;

    li {

      &:before {
        position: absolute;
        left: 0;
        top: 0;
        color: $color-blue;
        font-size: 1em;
        font-weight: bold;
      }
    }

    ol,
    ul {
      margin-top: 0;
    }
  }

  ol > li {
    &:before {
      content: counter(order-list) ".";
      counter-increment: order-list;
    }
  }

  li {
    @include body-s;
    padding-left: 24px;
    position: relative;

    pre {
      margin: $spacing-xxs 0;

      @include mq(large) {
        margin: $spacing-xs 0;
      }
    }
  }

  blockquote {
    margin: $spacing-xxs 0;
    border-left: 2px solid $color-blue;
    margin-left: 0;
    padding-left: $spacing-xxs;

    > p {
      color: $color-grey-1;
    }
  }

  table {
    border-collapse: collapse;
    border: 1px solid $color-border-1;
    margin: $spacing-xs auto;

    @include mq($until: xlarge) {
      width: 100%;
    }

    @include mq($until: small) {
      overflow: auto;
      table-layout: fixed;
    }

    @include mq(xlarge) {
      width: 120%;
      margin-left: -10%;
    }

    @include mq(xxlarge) {
      width: 140%;
      margin-left: -20%;
    }

    th {
      @include eyebrow-s;
      background-color: $color-grey-3;
    }

    tr {
      text-align: left;
      margin: 0;
      vertical-align: baseline;

      &:nth-of-type(even) {
        background-color: rgba($color-grey-4, 0.5);
      }
    }

    th,
    td {
      padding: 10px 20px 10px 10px;
      margin: 0;
      vertical-align: baseline;
      border-right: 1px solid $color-border-1;
      overflow: auto;
    }

    td {
      border-top: 1px solid $color-border-1;
    }
  }
}


// -----------------------------------------------------------------------------
// Adjacent Spacing
// -----------------------------------------------------------------------------

.rich-text {

  * + p {
    margin-top: $spacing-xxs;
  }


  * + h1,
  * + h2 {
    margin-top: $spacing-s;
  }

  * + h3,
  * + h4,
  * + h5,
  * + h6 {
    margin-top: $spacing-xs;
  }

  * + pre {
    margin-top: $spacing-xxs;

    @include mq(large) {
      margin-top: $spacing-xs;
    }
  }

  pre + p {
    margin-top: $spacing-xxs;

    @include mq(large) {
      margin-top: $spacing-xs;
    }
  }
}


// -----------------------------------------------------------------------------
// Limit Headings on Partner Profiles
// -----------------------------------------------------------------------------

.rich-text--partners {

  h1, h2, h3, h4, h5, h6 {
    @include heading-xs;
  }

  * + h1,
  * + h2 {
    margin-top: $spacing-xs;
  }
}
