@use 'sass:math';

// =============================================================================
// News Blurb
// =============================================================================
//
// The common type lockups used throughout the site
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.news-blurb {
  display: flex;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

  &:hover {

    .news-blurb__img {
      
      img {
        transform: scale(1) translate3d(0,0,0);
      }

      &:after {
        opacity: 1;
      }
    }

    .news-blurb__img-inner {
      transform: scale(1.05) translate3d(0,0,0);
    }

    .news-blurb__link {
      transform: translateY(0) translate3d(0,0,0);
      opacity: 1;
      transition: transform $ease-out-expo 0.5s 0.15s, opacity $ease-out-expo 0.5s 0.15s;
    }
  }
}

.news-blurb__content {
  width: 100%;
}

.news-blurb__img {
  @include aspect-ratio(5,3);
  width: 100%;
  margin-bottom: $spacing-xs;

  &:after {
    content: '';
    position: absolute;
    left: 5px;
    right: 5px;
    top: 15px;
    bottom: 0px;
    background-color: white;
    z-index: 1;
    box-shadow: $shadow-l;
    opacity: 0;
    transition: transform 1s $ease-out-expo, opacity 1s $ease-out-expo;
    transform: translate3d(0,0,0);
    -webkit-backface-visibility: hidden;
  }
}

.news-blurb__img-inner {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 1s $ease-out-expo;
  transform: translate3d(0,0,0);
  -webkit-backface-visibility: hidden;
  z-index: 2;
  background-color: $color-white;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    position: relative;
    z-index: 2;
    transition: transform 1s $ease-out-expo, opacity linear 0.3s;
    transform: translate3d(0,0,0) scale(1.15);
    -webkit-backface-visibility: hidden;
    opacity: 0;

    &.lazyloaded {
      opacity: 1;
    }
  }
}

.news-blurb__loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left:  0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}


.news-blurb__heading {
  @include heading-s;
  width: 100%;
}

.news-blurb__category-top {
  @include eyebrow-s;
  display: inline-block;
  color: $color-grey-1;
  margin-bottom: $spacing-xxxs;
  transition: color 0.5s $ease-out-expo;

  &:hover {
    color: $color-blue;
  }
}

.news-blurb__byline {
  @include body-xs;
  display: flex;
  width: 100%;
  align-items: center;
  align-content: center;
  margin-top: $spacing-xxxs;
}

.news-blurb__byline--multiple-authors {
  display: block;
}

.news-blurb__author {
  display: flex;
  align-items: center;
  & + .news-blurb__author {
    margin-top: $spacing-xxxs;
  }
}

.news-blurb__author-photo {
  width: 36px;
  height: 36px;
  border-radius: 100%;
  overflow: hidden;
  display: inline-block;
  margin-right: 8px;
  img {
    width: 100%;
  }
}

.news-blurb__author-photo--small {
  width: 24px;
  height: 24px;  
  margin-right: 6px;
}

.news-blurb__author-name {
  color: $color-black;
  margin-right: 0.25em;
  & + .news-blurb__byline-text {
    margin-left: -0.25em;
  }    
}

.news-blurb__excerpt {
  @include formatting;
  @include body-xs;
  margin-top: $spacing-xxxs;
  max-width: 470px;
}

.news-blurb__link {
  margin-top: $spacing-xxs;

  @include mq(medium) {
    transform: translateY(10px);
    opacity: 0;
    transition: transform $ease-out-expo 0.5s 0s, opacity $ease-out-expo 0.5s 0s;
  }
}



// -----------------------------------------------------------------------------
// L
// -----------------------------------------------------------------------------

.news-blurb--l {
  width: 100%;

  .news-blurb__content {
    width: 100%;

    @include mq(blogL) {
      width: 50%;
      order: 1;
      padding-right: $spacing-s;
    }
  }

  .news-blurb__img {
    width: 100%;

    &:after {
      box-shadow: $shadow-xl;
    }

    @include mq(blogL) {
      width: calc(50% - #{math.div($gutter-blog, 2)});
      margin-left: #{math.div($gutter-blog, 2)};
      order: 2;
      margin-bottom: 0;
    }
  }

  @include mq(blogM) {
    .news-blurb__category-top {
      @include eyebrow-l;
    }

    .news-blurb__heading {
      @include heading-m;
    }

    .news-blurb__byline {
      @include body-s;
      margin-top: 14px;
    }

    .news-blurb__author-photo {
      height: 50px;
      width: 50px;
      margin-right: 10px;
    }

    .news-blurb__excerpt {
      @include body-s;
      margin-top: 14px;
    }
  }

}


// -----------------------------------------------------------------------------
// Space Below
// -----------------------------------------------------------------------------

.news-blurb--space-below {
  margin-bottom: $spacing-s;
}
