// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.table {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: 100%;
  max-width: 720px;

  th {
    padding: 8px;
  }

  td {
    padding: 8px;
    font-size: 18px;
  }
}