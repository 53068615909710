// =============================================================================
// Site Footer
// =============================================================================
/*
   Main footer for the site that contains navigation, newsletter, and contact.
*/


.site-footer {
  width: 100%;
  padding-top: 30px;
  padding-bottom: $spacing-xs;
  padding-top: $spacing-s;
  border-top: 4px solid $color-grey-4;

  @include mq($until: footerFull) {
    padding-bottom: $spacing-xxs;
  }
}

.site-footer__inner {
  width: 85%;
  margin: auto;
  max-width: $site-max-width-inner;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  align-content: flex-start;

  @include mq($until: footerFull) {
    justify-content: space-between;
  }

  @include mq(medium) {
    width: 90%;
  }
}

.site-footer__logo {
  width: 100%;
  display: block;
  text-align: left;
  margin-bottom: $spacing-xs;

  a {
    display: inline-block;
  }

  > svg {
    width: 100%;
    max-width: 140px;
    height: auto;
  }
}

.site-footer__nav {
  font-size: 16px;
  column-count: 2;
  width: 100%;

  @include mq($from: footerS, $until: footerM) {
    column-count: 3;
  }

  @include mq($from: footerM, $until: footerFull) {
    width: 50%;
    padding-right: $spacing-xs;
  }

  @include mq($from: footerFull) {
    width: 66.666%;
    column-count: 3;
  }
}

.site-footer__nav-item {
  display: block;
  font-weight: bold;
  break-inside: avoid;
  margin-bottom: 35px;
  
  > a {
    display: inline-block;
    padding: 8px 0;

    &:hover {
      color: $color-blue;
      text-decoration: underline;
    }
  }
}

.site-footer__subnav-item {
  width: 100%;
  display: block;
  font-weight: 300;
  color: $color-text;
  
  > a {
    display: inline-block;
    padding: 8px 0;

    &:hover {
      color: $color-blue;
      
      & > span:not(.tw-no-underline) {
        text-decoration: underline;
      }
    }
  }
}

.site-footer__subnav-item-icon {
  max-height: 18px;
  width: 21px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 4px;
  transform: translateY(-2px);
}

.site-footer__right {
  width: 100%;
  
  @include mq($until: footerM) {
    margin-top: $spacing-s;
  }

  @include mq($from: footerM) {
    width: 50%;
    text-align: right;
  }

  @include mq($from: footerFull) {
    width: 33.333%;
  }
}

.site-footer__phone {
  margin-top: $spacing-xxxs;
  color: $color-text-light;

  > a {
    color: $color-black;
  }
}

.site-footer__newsletter {
  margin-top: $spacing-s;
}

.site-footer__newsletter-etc {
  color: $color-grey-1;
  margin-top: 15px;
}

.site-footer__copyright {
  @include body-xxs;
  margin-top: $spacing-m;

  @include mq($from: footerM) {
    width: 50%;
  }
  
  > a {
    color: $color-blue-dark;
    text-decoration: underline;
    margin-right: 15px;
    display: inline-block;

    &:first-of-type {
      margin-left: 15px;
    }
  }
}

.site-footer__credits {
  @include body-xxs;
  color: $color-grey-1;
  text-align: right;
  margin-top: $spacing-xs;
  vertical-align: top;

  @include mq($from: footerM) {
    width: 50%;
    margin-top: $spacing-m;
  }

  > a > img,
  > img {
    display: inline-block;
    vertical-align: top;
    width: 180px;
    transform: translateY(-16px);
  }
}


// -----------------------------------------------------------------------------
// No Border
// -----------------------------------------------------------------------------

.site-footer--no-border {
  border-top: 0;
}
