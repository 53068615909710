//===============================================================
// Helper Classes
//===============================================================

html.scroll-lock, 
body.scroll-lock {
  overflow: hidden;
}

// Spacing
// ——————————————————————————————————————————————————————————————

.h-spacing-mt-xxxs {
  margin-top: $spacing-xxxs;
}

.h-spacing-mt-xxs {
  margin-top: $spacing-xxs;
}

.h-spacing-mt-xs {
  margin-top: $spacing-xs;
}

.h-spacing-mt-s {
  margin-top: $spacing-s;
}

.h-spacing-mt-m {
  margin-top: $spacing-m;
}


// Colors
// ——————————————————————————————————————————————————————————————
.h-color-black {
  color: $color-black;
}

.h-color-blue {
  color: $color-blue;
}


// Visibility
// ——————————————————————————————————————————————————————————————

.h-hide-visually { 
  @include hide-visually;
}


.h-hide-until-small {

  @include mq($until: small) {
    display: none;
  }
}

.h-hide-until-large {

  @include mq($until: large) {
    display: none;
  }
}


// Basic Link Formatting
// ——————————————————————————————————————————————————————————————

.h-basic-link {
  color: $color-blue;
  transition: border $ease-out-expo 0.3s;
  border-bottom: 1px solid transparent;
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid $color-blue;
  }
}
