// =============================================================================
// Sections
// =============================================================================

.l-section {
  margin-top: $spacing-m;
  margin-bottom: $spacing-m;

  @include mq(large) {
    margin-top: $spacing-l;
    margin-bottom: $spacing-l;
  }

  @include mq(xxlarge) {
    margin-top: $spacing-xl;
    margin-bottom: $spacing-xl;
  }
}

.l-section__intro {
  margin-top: $spacing-s;
  margin-bottom: $spacing-xs;
  text-align: center;
}

.l-section__heading {
  max-width: 70vw;
  margin: auto;
}

.l-section__footer {
  margin-top: $spacing-s;
  text-align: center;
}


// -----------------------------------------------------------------------------
// Border
// -----------------------------------------------------------------------------

.l-section--border {
  border-top: 2px solid $color-grey-4;
  padding-top: $spacing-m;

  @include mq(xlarge) {
    padding-top: $spacing-l;
  }

  @include mq(xxlarge) {
    padding-top: $spacing-xl;
  }
}

// -----------------------------------------------------------------------------
// Background
// -----------------------------------------------------------------------------

.l-section--background {
  background: $color-grey-4;
  padding-top: $spacing-s;
  padding-bottom: $spacing-s;

  @include mq(large) {
    padding-top: $spacing-m;
    padding-bottom: $spacing-m;
  }

  @include mq(xxlarge) {
    padding-top: $spacing-l;
    padding-bottom: $spacing-l;
  }
}


// -----------------------------------------------------------------------------
// Less spacing (for sections that should bleed together)
// -----------------------------------------------------------------------------

.l-section--less-spacing {
  margin-top: $spacing-s;
  margin-bottom: $spacing-s;

  @include mq(xlarge) {
    margin-top: #{$spacing-m * 0.75};
    margin-bottom: #{$spacing-m * 0.75};
  }
}

.l-section--less-spacing-above {
  margin-top: $spacing-s;

  @include mq(xlarge) {
    margin-top: #{$spacing-m * 0.75};
  }
}


// -----------------------------------------------------------------------------
// No Spacing Top
// -----------------------------------------------------------------------------

.l-section--no-spacing-top {
  margin-top: 0;
}


// -----------------------------------------------------------------------------
// More Spacing Bottom
// -----------------------------------------------------------------------------

.l-section--more-spacing-below {
  margin-bottom: $spacing-l;

  @include mq(large) {
    margin-bottom: $spacing-xl;
  }

  @include mq(xlarge) {
    margin-bottom: $spacing-xxl;
  }
}
