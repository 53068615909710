//===============================================================
// Hide Visually
//===============================================================
/*
  Hides content from screen but still accessible to screenreaders
*/

@mixin hide-visually { 
  border: 0; 
  clip: rect(0 0 0 0); 
  height: 1px; 
  margin: -1px; 
  overflow: hidden; 
  padding: 0; 
  position: absolute; 
  width: 1px; 
}
