@use 'sass:math';

// =============================================================================
// Schedule
// =============================================================================
//
// Schedule
//
.schedule {
  margin: $spacing-xs auto $spacing-m auto;
}

.schedule[data-track-total="1"] {
  max-width: 700px;
}

.schedule__session,
.schedule__session-title,
.schedule__time-slot {
  font-size: 1.2rem;
  line-height: 1.35;
  margin-top: 0;
  margin-bottom: $spacing-xxxs;
}

.schedule__session {
  font-size: 1rem;
  line-height: 1.5;
}

.schedule__time-slot {
  grid-column: times;
  margin-top: $spacing-xxxs;
}

.schedule__session-time,
.schedule__time-slot {
  font-feature-settings: "case", "lnum";
}

@supports (display: grid) {
  @media screen and (min-width: 375px) {
    .schedule__track-slot {
      display: block;
      padding: 10px 5px 5px;
      position: sticky;
      top: 0;
      z-index: 98; /* .site-header--sticky z-index minus 1 */
      background-color: rgba(255, 255, 255, 0.9);
    }
  }
}

.schedule__session {
  padding: $spacing-xxxs;
  background-color: $color-grey-3;
  border-radius: 4px;
}

.schedule__session.track-even {
  // Intended to sit alongside $color-grey-3
  background-color: #F7EFEF;
}

.schedule__session.schedule__session--break {
  background-color: $color-grey-5;
}

.schedule__time-slot,
.schedule__session {
  margin-bottom: $spacing-xxxs;
  @media (min-width: 375px) {
    margin-bottom: 0;
  }
}

.schedule__track-slot {
  display: none;

  @media (min-width: 375px) {
    display: block;
  }
}

@media (min-width: 375px) {
  .schedule__session-track {
    display: none;
  }
}

@media (min-width: 375px) and (max-width: 700px) {
  :root {
    --schedule-time-column-width: 3rem;
  }

  .schedule {
    margin-left: -20px;
    margin-right: -20px;
    grid-gap: 0.25em !important;
  }

  .schedule__session {
    padding: #{math.div($spacing-xxxs, 2)};
  }

  .schedule__session-title {
    font-size: 1rem;
    line-height: 1.3;
  }

  .schedule__time-slot,
  .schedule__session {
    font-size: 0.85rem;
    line-height: 1.2;
  }
}
