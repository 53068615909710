@use 'sass:math';

// =============================================================================
// KB Grid
// =============================================================================

.l-kb-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  margin-top: $spacing-m;

  .card-a,
  .card-a__inner {
    height: 100%;
  }
}

.l-kb-grid__block {
  width: 100%;

  @include mq($until: kbGridS) {
    display: flex;
    justify-content: center;

    &:nth-of-type(n+2) {
      margin-top: $gutter-l;
    }
  }

  @include mq($from: kbGridS, $until: kbGridM) {
    width: calc(50% - #{$gutter-l - math.div($gutter-l, 2)});
    margin-left: $gutter-l;

    &:nth-of-type(2n+1) {
      margin-left: 0;
    }

    &:nth-of-type(n+3) {
      margin-top: $gutter-l;
    }
  }

  @include mq($from: kbGridM, $until: kbGridL) {
    width: calc(33.333% - #{$gutter-l - math.div($gutter-l, 3)});
    margin-left: $gutter-l;

    &:nth-of-type(3n+1) {
      margin-left: 0;
    }

    &:nth-of-type(n+4) {
      margin-top: $gutter-l;
    }
  }

  @include mq(kbGridL) {
    width: calc(25% - #{$gutter-l - math.div($gutter-l, 4)});
    margin-left: $gutter-l;

    &:nth-of-type(4n+1) {
      margin-left: 0;
    }

    &:nth-of-type(n+5) {
      margin-top: $gutter-l;
    }
  }
}
