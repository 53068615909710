// =============================================================================
// Image with Caption
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.img-w-caption {
  width: 100%;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}

.img-w-caption__img {
  width: 100%;
  position: relative;

  > img {
    max-width: 100%;
    margin: auto;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

.img-w-caption__caption {
  @include body-xs;
  color: $color-grey-1;
  margin-top: $spacing-xxs;
  padding-top: 14px;
  border-top: 1px solid $color-grey-3;
  width: 100%;

  a {
    border-bottom: 1px solid $color-grey-1;
    transition: border 0.5s $ease-out-expo;

    &:hover {
      border-bottom-color: transparent;
    }
  }
}

.img-w-caption__img-loading {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left:  0;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Hide loading icon when image is loaded
img.lazyloaded + .img-w-caption__img-loading {
  opacity: 0;
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.img-w-caption--2-up {

  align-items: flex-start;

  // Individual images have $spacing-xxs of spacing, and 2-up image
  // should be closer together than two separate.
  .img-w-caption__img + .img-w-caption__img {
    margin-top: $spacing-xxxs;
  }

  @include mq(medium) {
    .img-w-caption__img {
      width: 48%;

      & + .img-w-caption__img {
        margin-top: 0;
        margin-left: 4%;
      }
    }
  }

  .img-w-caption__caption {
    max-width: 780px;
  }
}

.img-w-caption__img--w-browser {
  box-shadow: $shadow-screenshot;
  border-radius: 6px;

  // When 2-up images are stacked, we still want the shadow below the images
  img {
    z-index: 1;
    border-radius: 6px;
  }
}


.img-w-caption--1-up .img-w-caption__img > img {
  width: 100%;
}

@include mq(medium) {
  .img-w-caption--2-up .img-w-caption__img--w-browser { 
    box-shadow: $shadow-screenshot-2-up;
    border-radius: 4px;
    
    img {
      border-radius: 4px;
    }
  }
}
