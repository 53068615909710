// =============================================================================
// Form
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.form {

}


// -----------------------------------------------------------------------------
// Remove Required Field Note
// -----------------------------------------------------------------------------

.form--no-details {

  .l-form__details {
    @include hide-visually;
  }

  .l-form__header {
    margin-top: 0;
  }
}
