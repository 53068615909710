@use 'sass:math';

// =============================================================================
// Grid for News Index
// =============================================================================

.l-post-grid {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.l-post-grid__header {
  text-align: center;
  color: $color-text-light;
  width: 100%;
}

.l-post-grid__featured-post {
  width: 100%;
  padding-bottom: $spacing-s;
  border-bottom: 1px solid $color-border-2;
}

.l-post-grid__featured-post + .l-post-grid__featured-post {
  padding-top: $spacing-s;
}

.l-post-grid__block {
  width: 100%;

  @include mq($until: blogM) {

    &:nth-of-type(n+2) {
      margin-top: $spacing-s;
    }
  }

  @include mq(blogM) {
    width: calc(50% - #{$gutter-blog - math.div($gutter-blog, 2)});
    margin-left: $gutter-blog;
    margin-top: $spacing-s;

    &:nth-of-type(odd) {
      margin-left: 0;
    }

    &:nth-of-type(n+3) {
      border-top: 1px solid $color-border-2;
      padding-top: $spacing-s;
    }
  }
}

.l-post-grid.l-post-grid--lg-thirds .l-post-grid__block {
  @include mq(blogL) {
    width: calc(33.333% - #{$gutter-blog - math.div($gutter-blog, 3)});
    margin-left: $gutter-blog;
    margin-top: $spacing-s;

    &:nth-of-type(n+3) {
      border-top: 0;
      padding-top: 0;
    }
  
    &:nth-of-type(3n+1) {
      margin-left: 0;
    }
  
    &:nth-of-type(n+4) {
      border-top: 1px solid $color-border-2;
      padding-top: $spacing-s;
    }
  }
}

.l-post-grid__block--third {
  width: calc(33.333% - #{$gutter-blog - math.div($gutter-blog, 3)});
  margin-left: $gutter-blog;
  margin-top: $spacing-s;

  &:nth-of-type(3n+1) {
    margin-left: 0;
  }

  &:nth-of-type(n+4) {
    border-top: 1px solid $color-border-2;
    padding-top: $spacing-s;
  }
}
