// =============================================================================
// Columns
// =============================================================================

// Defaults to two columns
.l-columns {
  
  @include mq(medium) {
    column-count: 2;
    column-width: 50%;
    column-gap: $spacing-xs;
  }
}