/* TODO - Move to layout */

.pricing-table {
  margin-top: $spacing-m;
}

$pricing-table-border-color: $color-grey-2;

/* Reset */

table.pricing-table, .pricing-table table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

.pricing-table td,
.pricing-table th {
  border: 1px solid $pricing-table-border-color;
}

// .pricing-table .pricing-table__faux-th {
//   border-right: 1px solid $color-grey-4;
// }

.pricing-table th,
.pricing-table .pricing-table__faux-th {
  font-weight: 500;
}

.pricing-table__faux-th-subtitle {
  font-weight: normal;
  @media (min-width: 325px) and (max-width: 500px) {
    font-size: 9px !important;
    line-height: 1.2;
    }
}



/* Pricing Table */

.pricing-table {
  position: relative;
}

.pricing-table__header {
  position: sticky;
  top: 0;
  width: 100%;
  background: #FFF;
  z-index: 1;
  transition: transform $ease-out-expo 0.5s;
  // border-bottom: 1px solid $pricing-table-border-color;

  // Fix clip path issue with tw-sr-only
  // Screen reader only on faux headers underneath
  @media (min-width: 1440px) {
    margin-left: -1px;
    margin-right: -1px;
    width: calc(100% + 2px);
  }
}

.pricing-table__header .edition-logo {
  justify-content: start;
}

.pricing-table__header::after {
  content: '';
  width: 100%;
  height: 18px;
  background: linear-gradient(rgba(74, 124, 246, 0.12), rgba(74, 124, 246, 0));;

  display: block;
  transform: translateY(0);
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.1s;
}

.pricing-table__header--sticky::after {
  opacity: 1;
}

.pricing-table__header .pricing-table__faux-thead {
  transform: translateY(100%);
  transition: transform $ease-out-expo 0.5s;
}

.pricing-table__header.pricing-table__header--sticky .pricing-table__faux-thead {
  transform: translateY(0);
}

.pricing-table__header:not(.pricing-table__header--sticky) .pricing-table__section-heading-title {
  opacity: 0;
  transition: opacity ease-in-out 0.1s;
}

.pricing-table__header.pricing-table__header--sticky .pricing-table__section-heading-title {
  opacity: 1;
  transition: opacity ease-in-out 0.1s;
}

@media (min-width: 767.9px) {

  .pricing-table__header + .pricing-table__section > .pricing-table__section-heading {
    transition: opacity ease-in-out 0.1s;
    will-change: opacity transform;
  }

  .pricing-table__header.pricing-table__header--sticky + .pricing-table__section > .pricing-table__section-heading {
    opacity: 0;
    transition: opacity ease-in-out 0.1s;
  }

}

.pricing-table__section-heading-description {
  margin-top: 5px;
}

.pricing-table th {
  text-align: left;
}

.pricing-table__section {
  margin-bottom: 3rem;
}


.pricing-table__section-heading {

}

.pricing-table__section-heading-title {
  margin: 0;
}

.pricing-table__faux-thead > .pricing-table__faux-tr {
  display: flex;
}

.pricing-table__faux-thead,
.pricing-table__info-table {
  width: 100%;
}

/* Hide tier headings in sections */
.pricing-table__section .pricing-table__faux-thead > .pricing-table__faux-tr > div {
  display: none;
}

/* Show section heading in section */
.pricing-table__section .pricing-table__faux-thead > .pricing-table__faux-tr > :first-child {
  display: block;
}

.pricing-table__row-heading-icon {

}

.pricing-table__row-heading-icon {
  display: inline-block;
  max-width: 17px;
}

.pricing-table__row-heading-icon-inner {
  display: inline-block;
  transform: translate(2px, 4px);
}


/* Small Screens */

@media (max-width: 767.9px) {

  .pricing-table__header,
  .pricing-table__section {
    margin-bottom: 4rem;
  }

  .pricing-table td,
  .pricing-table th {
    border-bottom: none;
  }

  .pricing-table tbody th {
    border-top: none;
    border-left: none;
  }

  .pricing-table th,
  .pricing-table td {
    border-right: none;
  }

  .pricing-table th:last-child,
  .pricing-table td:last-child {
    border-right: 1px solid $pricing-table-border-color;;
  }

  .pricing-table__info-table > thead > tr,
  .pricing-table__info-table > tbody > tr {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $spacing-xxxs;
    border-bottom: 1px solid $pricing-table-border-color;
  }

  .pricing-table thead > tr,
  .pricing-table__faux-thead > .pricing-table__faux-tr {
    flex-wrap: wrap;
  }

  .pricing-table__faux-thead {
    transform: unset;
  }

  /* Hide main heading in header */
  .pricing-table__header .pricing-table__faux-thead > .pricing-table__faux-tr > :first-child {
    display: none;
  }

  .pricing-table__header .edition-logo {
    justify-content: center;
  }

  .pricing-table__section-heading-title {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
  }

  .pricing-table thead > tr > div,
  .pricing-table__faux-thead > .pricing-table__faux-tr > div,
  .pricing-table__info-table tbody > tr > td,
  .pricing-table__info-table tbody > tr > th,
  .pricing-table__info-table thead > tr > th {
    width: var(--pricing-table-td-width-sm, 25%);;
    padding: $spacing-xxxs;
    display: block;
  }

  .pricing-table__faux-thead > .pricing-table__faux-tr > :first-child,
  .pricing-table__info-table :first-child {
    width: 100%;
  } 
}

// Very small screens
@media (max-width: 324.9px) {
  .pricing-table {
    width: 100%;
    overflow-x: scroll;
  }

  // Hide sticky header, since it can’t be sticky within `overflow-x: scroll`
  .pricing-table__header.pricing-table__header--sticky {
    display: none;
  }

  .pricing-table__inner-container {
    min-width: 750px;
  }

  .pricing-table__info-table thead {
    display: block !important;
  }

  .pricing-table__info-table thead > tr > :first-child {
    display: none;
  }
}

/* Large Screens - The CSS custom property is determined in Twig (pricing-table) */

@media (min-width: 768px) {
  .pricing-table__faux-thead > .pricing-table__faux-tr > div,
  .pricing-table__info-table tbody > tr > td,
  .pricing-table__info-table tbody > tr > th {
    width: var(--pricing-table-td-width-lg, 16.5%);
    padding: $spacing-xxs;
  }

  .pricing-table__faux-thead > .pricing-table__faux-tr > div:first-child,
  .pricing-table__info-table tbody > tr > td:first-child,
  .pricing-table__info-table tbody > tr > th:first-child {
    width: var(--pricing-table-th-width-lg, 34%);
  }
}

/* Handle double-sticky header – This isn’t quite right, but is close */

.site-header--sticky.is-flickable.is-visible ~ main .pricing-table__header {
  transform: translate(0, 86px);
}
