// =============================================================================
// Mockup: Mobile
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.mockup-mobile {
  position: absolute;
  top: 0;

  &:before {
    content: "";
    position: absolute;
    top: 20px;
    left: 10px;
    right: 10px;
    bottom: 0px;
    box-shadow: $shadow-m;
    z-index: -1;
    transition: box-shadow 1s $ease-out-expo 0.1s;
    border-radius: 20px;
  }
}

.mockup-mobile__browser-top {
  display: block;
  width: 100%;

  img,
  svg {
    width: 100%;
  }
}

.mockup-mobile__browser-bottom {
  display: block;
  width: 100%;

  img,
  svg {
    width: 100%;
  }
}



// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------

.is-selected .mockup-mobile {

  &:before {
    box-shadow: $shadow-mockup;
  }
}