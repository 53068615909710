// =============================================================================
// Side By Side Layout
// =============================================================================
//
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.l-sbs {
  margin: $spacing-s auto $spacing-l;
  max-width: $site-max-width-outer;

  @include mq(large) {
    margin-top: $spacing-m;
    margin-bottom: $spacing-l;
  }

  @include mq(xlarge) {
    margin-top: $spacing-l;
    margin-bottom: $spacing-xl;
  }
}

.l-sbs__inner {
  margin: auto;
  width: 90%;
  max-width: $site-max-width-inner;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}


.l-sbs__img {
  width: 100%;
  max-width: 760px;
  margin: auto;

  @include mq(large) {
    width: 70%;
    padding-right: 5%;
    max-width: 1200px;
  }
}

.l-sbs__text {
  width: 100%;
  margin-top: $spacing-xs;

  @include mq(large) {
    width: 30%;
    margin-top: 0;
  }

  .blurb-list {
    margin: auto;
  }
}


// -----------------------------------------------------------------------------
// Reverse
// -----------------------------------------------------------------------------

.l-sbs--reverse {

  .l-sbs__inner {
    flex-direction: row-reverse;
  }

  .l-sbs__img {

    @include mq(large) {
      padding-right: 0;
      padding-left: 5%;
    }
  }
}