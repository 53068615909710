// =============================================================================
// Article List
// =============================================================================
//
// List of links to support articles within a guides category page
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.article-list {
  margin: 0;
  padding: 0;

  & + .article-list {
    margin-top: $spacing-s;
  }
}

.article-list__list {
  margin: 0;
  padding: 0;
}

.article-list__heading {
  @include heading-xs;
  color: $color-text-light;
  padding-bottom: 18px;
  border-bottom: 2px solid $color-border-2;
  margin-bottom: $spacing-xxs;
}

.article-list__article {
  display: block;
  border-bottom: 1px solid $color-border-2;
}

.article-list__link {
  @include heading-xs;
  padding: $spacing-xxs 0;
  display: block;
  position: relative;
  transition: color 0.5s $ease-out-expo;
  padding-right: 52px;

  &:after {
    content: "";
    height: 12px;
    width: 12px;
    position: absolute;
    right: 12px;
    top: 32px;
    display: block;
    margin-left: 2px;
    border-right: 2px solid $color-blue;
    border-bottom: 2px solid $color-blue;
    transform: rotate(-45deg);
    transition: transform 0.5s $ease-out-expo;
  }

  &:hover {
    color: $color-blue;

    &:after {
      transform: rotate(-45deg) translate3d(4px, 4px, 0);
    }
  }
}
