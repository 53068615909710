// =============================================================================
// Logo lists
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.logo-list {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: $spacing-xs auto 0;
  padding: 0;
  width: 100%;
}

.logo-list__item {
  display: inline-block;
  padding:  8px 0px 8px 35px;
  position: relative;
}

.logo-list__logo {
  display: block;
  height: 45px;
  text-align: center;

  > img,
  > svg {
    display: inline-block;
    height: 100%;
    width: auto;
    max-width: 130px;
  }

  & + .logo-list__logo {
    margin-left: $spacing-xxs;
  }

  @include mq($until: small) {
    height: 28px;
  }
}


// -----------------------------------------------------------------------------
// Top Brands
// -----------------------------------------------------------------------------

.logo-list--top-brands {
  flex-wrap: wrap;
  justify-content: center;

  .logo-list__item {
    padding-left: 2rem;
    padding-right: 2rem;

    @include mq($until: medium) {
      width: 50%;
      max-width: 150px;
    }

    @include mq(medium, 1100px) {
      width: 25%;
      max-width: 180px;
    }

    @include mq(xxlarge) {
      padding-left: 2.3rem;
      padding-right: 2.3rem;
    }
  }

  .logo-list__logo {
    height: 35px;

    @include mq(xxlarge) {
      height: 40px;
    }

    svg {
      fill: rgba($color-grey-1, 0.45);
      max-width: 100%;
    }
  }
}
