// =============================================================================
// Why Graphics
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.why-graphic {
  position: relative;
}

.why-graphic__main {
  width: 100%;
}

.why-graphic__overlay {
  position: absolute;
  opacity: 0;
  transition: opacity 1s $ease-out-expo, transform 1s $ease-out-expo;
}


// -----------------------------------------------------------------------------
// State
// -----------------------------------------------------------------------------

.why-graphic.is-visible {

  .why-graphic__overlay {
    opacity: 1;
    transform: translateY(0%);
  }
}


// -----------------------------------------------------------------------------
// One-Offs
// -----------------------------------------------------------------------------

.why-graphic--commerce-1 {

  .why-graphic__overlay--1 {
    right: 60px;
    top: 10%;
    width: 33.333%;
    transform: translateY(10%);
  }
}

.why-graphic--commerce-2 {
  width: 90%;
  margin-left: 5%;
  margin-bottom: $spacing-xs;

  @include mq(large) {
    margin-bottom: 0;
  }

  .why-graphic__main {
    width: 90%;
    margin-left: 5%;
  }

  .why-graphic__overlay--1 {
    width: 100%;
    margin-left: -10%;
    bottom: -16%;
    transform: translateY(10%);
  }

  .why-graphic__overlay--2 {
    top: -10%;
    right: -8%;
    width: 110%;
    transform: translateY(10%);
    transition-delay: 0.05s;
  }
}

.why-graphic--commerce-3 {
  margin-bottom: $spacing-xs;

  @include mq(large) {
    margin-bottom: 0;
  }
}

.why-graphic--developers-2 {
  display: flex;
  justify-content: space-around;
}

.why-graphic--organization-3 {
  padding: 0 5vw $spacing-s;

  @include mq(large) {
    padding: $spacing-l 10vw $spacing-l $spacing-s;
  }
}


// -----------------------------------------------------------------------------
// Commerce Plugins Blocks
// -----------------------------------------------------------------------------

.why-graphic__commerce-plugin {
  position: absolute;
  width: 20%;
  max-width: 140px;
  transition: transform 1s $ease-out-expo;

  .plugin-block {
    opacity: 0;
  }

  &.is-visible {
    
    .plugin-block {
      opacity: 1;
    }
  }
}

.why-graphic__commerce-plugin--1 {
  left: 15%;
  top: 5%;
}

.why-graphic__commerce-plugin--2 {
  left: 5%;
  top: 60%;
}

.why-graphic__commerce-plugin--3 {
  left: 40%;
  top: 40%;
}

.why-graphic__commerce-plugin--4 {
  left: 70%;
  top: 10%;
}

.why-graphic__commerce-plugin--5 {
  left: 75%;
  top: 70%;
}