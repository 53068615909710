// =============================================================================
// Pullquotes
// =============================================================================


.pullquote {
  display: block;
  max-width: 720px;
  position: relative;
  margin: auto;

  &:before {
    content: "";
    width: 45px;
    height: 40px;
    background: url('../images/icon-quote.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    position: absolute;
    top: -6px;
    left: -65px;
  }

  @include mq($until: large) {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-top: 60px;

    &:before {
      top: 0;
      left: 50%;
      transform: translateX(-50%)
    }

    .pullquote__byline {
      justify-content: center;
    }
  }
}

.pullquote__quote {
  @include heading-m-alt;
}

.pullquote__byline {
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: $spacing-xxs;

  @include mq($until: large) {
    flex-direction: column;
  }
}

.pullquote__img {
  height: 60px;
  width: 60px;
  border-radius: 60px;
  overflow: hidden;
  margin-right: 18px;

  @include mq(large) {
    height: 80px;
    width: 80px;
    border-radius: 80px;
  }
}

.pullquote__author,
.pullquote__title {
  @include body-xs;
}

.pullquote__author {
  color: $color-black;
}

.pullquote__title {
  color: $color-text-light;
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.pullquote--centered {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;

  &:before {
    top: 0;
    left: 50%;
    transform: translateX(-50%)
  }

  .pullquote__byline {
    justify-content: center;
  }
}

.pullquote--s {

  .pullquote__quote {
    @include heading-s;
  }
}
