//===============================================================
// Unbutton
//===============================================================
/*
  Removes default button styling
*/

@mixin unbutton() {
  padding: 0;
  background-color: transparent;
  appearance: none;
  border: 0;
  box-shadow: none;

  // &:focus {
  //   outline: 0;
  // }
}
