@use "sass:map";
@use "sass:math";

$l-cloud-max-width: 2000px;

// Get the global medium breakpoint value, so we can use
// it in a height media query, which Sass mq() doesn’t support
$l-cloud-breakpoint-medium: map.get($mq-breakpoints, 'medium');

.l-cloud-landing {
  // margin-top: 60px;
}

.l-cloud-landing .l-section[id] {
  scroll-margin-top: $spacing-s;
}

.l-cloud-landing figure {
  margin: 0;
}

// TODO Update icons to be within standard size—the Cloud icons are
// all within 74px square, which was the largest side of the multi-site
// icon, but the existing icons are one height and various widths.
//   In the feature section they were 48px tall, so 74/48 
.l-cloud-landing .blurb__icon {
  transform: scale(math.div(74, 48));
}

// Remove lazyload opacity styling
.l-cloud-landing .mockup-desktop__inner img {
  opacity: unset;
}

.l-cloud-landing .l-section__heading {
  margin-bottom: $spacing-s;
}

.l-cloud-landing img {
  max-width: 100%;
  display: block;
  margin: 0;
}

.l-cloud-landing [data-scroll-container] {
  position: relative;
}

.l-cloud-landing__header-btn-group {
  margin: $spacing-xxs (-1 * $spacing-xxxs);
  display: flex;
  align-items: center;

  @include mq(medium) {
    // Out-dent the button a bit, once we have room
    transform: translateX(-1 * ($spacing-xxs/2));
  }
}

@include mq(large) {
  .l-cloud-landing__header .l-cloud-landing__split-text {
    .blurb__body {
      max-width: 600px;
    }

    .blurb__heading {
      max-width: 500px;
    }  
  }
}

.l-cloud-landing__header-btn-group > .btn,
.l-cloud-landing__header-btn-group > .link {
  margin-left: $spacing-xxxs;
  margin-right: $spacing-xxxs;
}

.l-cloud-landing__header {
  background: linear-gradient(rgba(229, 237, 253, 0), rgba(229, 237, 253, 1));
}

.l-cloud-landing__section {
  background: $color-grey-4;
}

.l-cloud-landing__header,
.l-cloud-landing__section {
  border-bottom: 1px solid $color-grey-2;
}

.l-cloud-landing__section-wrapper-outer {
  max-width: $l-cloud-max-width;
  margin: 0 auto;
}

.l-cloud-landing__section-wrapper-inner {
  padding: min(5vh, 100px) 5%;
  width: 100%;
  @media (min-width: $l-cloud-breakpoint-medium) {
    padding: 0 5%;
    min-height: min(75vh, 1250px);
  }
}


.l-cloud-landing .sticky-outer {
  display: none;
}

@include mq(medium) {
  .l-cloud-landing .sticky-outer {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: visible;
  }
  
  .l-cloud-landing .sticky {
    position: sticky;
    top: 0;
  }
}

.l-cloud-landing__feature-image img {
  border-radius: 4px;
  overflow: hidden;

  @include mq(medium) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
  }
}

.l-cloud-landing__split {
  @include mq(medium) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;  
  }
}

.l-cloud-landing__split--reverse {
  flex-direction: row-reverse;
}

@include mq(medium) {
  .l-cloud-landing__split .l-cloud-landing__split-text {
    width: 40%;

    &:first-child {
      padding-right: $spacing-xxs;
    }
  }

  .l-cloud-landing__split .l-cloud-landing__split-image {
    width: 60%;
  }
}

@include mq(large) {
  .l-cloud-landing__split .l-cloud-landing__split-text {
    &:first-child {
      padding-right: $spacing-xxs + 5px;
    }
  }
}


.l-cloud-landing__feature-image {
  @include mq(medium) {
    max-height: min(65vh, 1150px);

    // Screenshot size + vertical room for browser chrome
    aspect-ratio: 2880 / 1890;    
  }
}

.l-cloud-landing [data-scroll-step-image] {
  margin-top: $spacing-xs;
  margin-bottom: $spacing-xs;

  @include mq(medium) {
    display: none;
  }

  img {
    margin: 0;

  // background-color: #E5EDFD;
  // padding: 3rem 1rem 1rem 1rem;
  border-radius: 3px;
  // overflow: hidden;

  /* $shadow-screenshot */
  box-shadow: 0 0 2px rgba(6, 67, 117, 0.3), 0 10px 58px rgba(6, 67, 117, 0.2);

  }
}

// TODO Restore

.l-cloud-landing .cross-fade {
  position: relative;
  width: 100%;
}

.l-cloud-landing .cross-fade__top {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;

  .mockup-desktop {
    &:after {
      box-shadow: unset;
    }
  }
}

// Metrics
// -------

.l-cloud-landing-metrics,
.l-cloud-landing-metrics__item {
  margin: 0;
  padding: 0;
  list-style: none;
}

.l-cloud-landing-metrics {
  margin: 0 auto;

  @include mq(small) {
    // Flexbox: Unequal columns, which kind of looks better with uneven values
    // display: flex;
    // justify-content: center;

    // CSS Grid: Equal columns, even if the values are different lengths
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  @include mq(medium) {
    max-width: 80%;
  }
}

.l-cloud-landing-metrics__item {
  text-align: center;
  margin-bottom: $spacing-xs;

  @include mq(small) {
    margin-bottom: 0;
  }
}

.l-cloud-landing-metrics__value {
  font-size: #{clamp(24px, 10vw, 80px)};
  font-weight: 400;
  line-height: 1.1;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.l-cloud-landing-metrics__unit {
  font-weight: 500;
}

.l-cloud-landing-metrics__label {
}
