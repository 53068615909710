// =============================================================================
// Tooltips
// =============================================================================
//
// IMPORTANT: We're positioning these tooltips with CSS only, which means you
// must put them inside a tooltip wrapper object.
//

// -----------------------------------------------------------------------------
// For cases where it makes sense to simply output the tooltip icon inline
// -----------------------------------------------------------------------------

.tooltip-container {
  display: inline-block;
  position: relative;
  cursor: pointer;
  transform: translateY(2px);
}


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.tooltip {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include mq($until: large) {
    width: 100%;
    height: 100%;
  }
}

.tooltip__box {
  position: absolute;
  background: $gradient-blue-to-purple;
  padding: $spacing-xxxs;
  color: $color-white;
  border-radius: 2px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0;
  transform: translateZ(0) translateY(10px);
  transition: transform $ease-out-expo 0.5s, opacity $ease-out-expo 0.5s;
  z-index: $z-index-overlay;
  height: auto;
  pointer-events: none;
  width: 320px;
  text-align: left;

  ::selection {
    background: rgba($color-white, 0.1); /* WebKit/Blink Browsers */
    color: $color-white;
  }
  ::-moz-selection {
    background: rgba($color-white, 0.1); /* Gecko Browsers */
    color: $color-white;
  }
}

.tooltip:hover .tooltip__box {
  opacity: 1;
  transform: translateZ(0);
}

.tooltip__content {
  font-size: 16px;
  line-height: 1.4;
  font-weight: 400;
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.tooltip--one-line {

  .tooltip__box {
    width: auto;
  }

  .tooltip__content {
    display: block;
    width: auto;
    white-space: nowrap;
  }
}

.tooltip--check {

  .tooltip__box {
    padding-left: 42px;
    width: auto;
    
    &:before {
      content: '';
      background: url('../images/icon-circle-check-small.svg');
      background-size: 25px 25px;
      height: 25px;
      width: 25px;
      left: $spacing-xxxs;
      top: $spacing-xxxs;
      position: absolute;
    }
  }
}

.tooltip--external-link {

  .tooltip__box {
    padding-right: 33px;
    width: auto;
    white-space: nowrap;
    
    &:before {
      content: '';
      background: url('../images/icon-external-white.svg');
      background-size: 15px 15px;
      height: 15px;
      width: 15px;
      right: $spacing-xxxs;
      top: 15px;
      position: absolute;
    }
  }
}

.tooltip--rounded-focus {
  border-radius: 999px;
}
