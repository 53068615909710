@use 'sass:math';

// =============================================================================
// Uneven Column Layouts
// =============================================================================

.l-uneven-cols {
  display: flex;
  justify-content: center;
}


.l-uneven-cols__col {
  margin-right: $gutter-xl;

  &:last-of-type {
    margin-right: 0;
  }
}


// -----------------------------------------------------------------------------
// Column Widths
// -----------------------------------------------------------------------------

.l-uneven-cols__col--third {
  width: calc(40% - #{$gutter-l - math.div($gutter-l, 3)});
  // width: 50%;
}

.l-uneven-cols__col--two-thirds {
  width: calc(60% - #{$gutter-l - math.div($gutter-l, 1.5)});
  // width: 50%;
}


// -----------------------------------------------------------------------------
// Vertically Center
// -----------------------------------------------------------------------------

.l-uneven-cols--vertically-center {
  align-items: center;
}


// -----------------------------------------------------------------------------
// Match Heights
// -----------------------------------------------------------------------------

.l-uneven-cols--match-heights {
  align-items: stretch;
}
