// =============================================================================
// Check lists
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.check-list {
  display: block;
  margin: 0;
  padding: 0;
  text-align: left;
}

.check-list--center {
  text-align: center;

  .check-list__item-text {
    margin-left: -28px;
  }
}

.check-list__items {
  margin: auto;
}

.check-list__item {
  @include body-xs;
  display: block;
  padding:  8px 0px;
  position: relative;
  width: auto;
}

.check-list__item-text {
  @include basicLinks;
  display: inline-block;
  position: relative;
  padding-left: 28px;

  &:before {
    content: "";
    display: block;
    height: 15px;
    width: 20px;
    position: absolute;
    left: 0;
    top: 2px;
    background: url('../images/icon-check-small.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.check-list:not(.check-list--hosting):not(.check-list--partners):not(.check-list--heading-l) {
  .check-list__heading {
    margin-bottom: $spacing-xxxs;
    color: $color-grey-1;

    h6 {
      @include eyebrow-s;
    }
  }
}


// -----------------------------------------------------------------------------
// Large
// -----------------------------------------------------------------------------

.check-list--l {

  .check-list__item {
    @include body-s;
  }

  .check-list__item-text:before {
    top: 5px;
  }
}


// -----------------------------------------------------------------------------
// Larger Heading
// -----------------------------------------------------------------------------

.check-list--heading-l {

  .check-list__heading {
    h6 {
      @include heading-s;
      margin-bottom: $spacing-xxxs;
    }
  }
}


// -----------------------------------------------------------------------------
// Pricing
// -----------------------------------------------------------------------------

.check-list--pricing {
  padding: $spacing-xxs $spacing-xxs;
  width: 100%;

  .check-list__items {
    max-width: 360px;
  }

  @include mq(xlarge) {
    padding: $spacing-xxs $spacing-xs;
  }
}


// -----------------------------------------------------------------------------
// Hosting Providers
// -----------------------------------------------------------------------------

.check-list--hosting,
.check-list--partners {
  margin-top: $spacing-s;

  .check-list__heading {
    h6 {
      @include heading-s;
      margin-bottom: $spacing-xxs;
      text-align: center;
    }
  }

  .check-list__items {

    @include mq($until: large) {
      text-align: center;

      .check-list__item-text {
        padding-left: 0;

        &:before {
          display: inline-block;
          position: relative;
          top: 0px;
          margin-left: -20px;
        }
      }
    }

    @include mq(large) {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      width: 90%;
      margin: auto;
      text-align: left;
    }
  }

  .check-list__item {

    @include mq(large) {
      width: 50%;
      padding-right: 25px;
    }
  }
}

// -----------------------------------------------------------------------------
// Craft Partners
// -----------------------------------------------------------------------------

.check-list--partners {
  margin-top: 0;

  .check-list__items {
    text-align: center;

    .check-list__item-text {
      padding-left: 0;

      &:before {
        display: inline-block;
        position: relative;
        top: 0px;
        margin-left: -20px;
      }
    }
  }

  .check-list__item {

    @include mq(large) {
      width: 100%;
      padding-right: 0px;
    }
  }
}

// -----------------------------------------------------------------------------
// With Spacing
// -----------------------------------------------------------------------------

.check-list--w-spacing {
  margin-top: $spacing-s;
}

// -----------------------------------------------------------------------------
// Numbered
// -----------------------------------------------------------------------------

.check-list--numbered {

  .check-list__items {
    counter-reset: order-list;
  }

  .check-list__item-text {

    &:before {
      content: counter(order-list) ".";
      counter-increment: order-list;
      position: absolute;
      left: 0;
      top: 0.22em;
      color: $color-blue;
      font-size: 1em;
      height: auto;
      line-height: 1;
      font-weight: bold;
      background: none;
    }
  }
}
