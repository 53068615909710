@use 'sass:math';

//===============================================================
// Intrinstic Ratios
//===============================================================
/*
  Sets up ratio to images to load without a content shift.
*/

@mixin aspect-ratio($width, $height) {
  position: relative;
  
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: math.div($height, $width) * 100%;
  }
}
