// =============================================================================
// Links
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.link {
  display: inline-block;
  color: $color-blue;
  font-size: 21px;
  position: relative;
  padding-bottom: 8px;

  &:before {
    content: '';
    position: absolute;
    bottom: 2px;
    height: 2px;
    left: 50%;
    width: 30px;
    background: $color-blue;
    transform: translateX(-50%);
    transition: width $ease-out-expo 0.5s;
    transform-origin: center;
  }

  &:hover {

    &:before {
      width: 100%;
    }
  }
}

.link__icon {
  display: inline-block;
}


// -----------------------------------------------------------------------------
// Small
// -----------------------------------------------------------------------------

.link--s,
.link--xs,
.link--w-icon {
  font-size: 18px;
  border-bottom: 1px solid transparent;
  transition: border 0.5s $ease-out-expo;
  display: inline;
  padding-bottom: 0;
  line-height: 1.5;

  &:before {
    display: none;
  }

  &:hover {
    border-bottom: 1px solid $color-blue;
  }
}

// -----------------------------------------------------------------------------
// XS - Matches .btn--xs
// -----------------------------------------------------------------------------

.link--xs {
  font-size: 15px;
  line-height: 1.2;
  @include mq(xxlarge) {
    font-size: 16px;
  }
}


// -----------------------------------------------------------------------------
// Medium
// -----------------------------------------------------------------------------

.link--m {
  font-size: 21px;
  color: $color-black;

  &:before {
    width: 24px;
    background: $gradient-blue-to-purple;
  }

  @include mq(large) {
    font-size: 24px;
  }
}


// -----------------------------------------------------------------------------
// Large
// -----------------------------------------------------------------------------

.link--l {
  font-size: 24px;
  color: $color-black;
  padding-bottom: 14px;
  white-space: nowrap;

  &:before {
    width: 30px;
    background: $gradient-blue-to-purple;
  }

  @include mq(medium) {
    font-size: 28px;
  }

  @include mq(large) {
    font-size: 32px;
  }
}


// -----------------------------------------------------------------------------
// Icon Special Cases
// -----------------------------------------------------------------------------

.link--alert {

  .link__icon {
    transform: translateY(3px);
  }
}
