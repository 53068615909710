// =============================================================================
// Page Footer
// =============================================================================

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.l-page-footer {
  padding: $spacing-s 0;
}

.l-page-footer__inner {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  width: 85%;

  @include mq(medium) {
    width: 100%;
  }

  @include mq(large) {
    width: 75%;
    max-width: $site-max-width-inner;
  }
}

.l-page-footer__heading {
  text-align: center;
  width: 100%;
  margin-bottom: $spacing-xs;
}

.l-page-footer__col {
  padding: $spacing-xs 0;
  width: 100%;

  @include mq(medium) {
    padding: $spacing-xs $spacing-s;
    width: 50%;

    & + .l-page-footer__col {
      border-left: 1px solid $color-border-2;
    }
  }
}



// -----------------------------------------------------------------------------
// Background
// -----------------------------------------------------------------------------

.l-page-footer--bg {
  background-color: $color-grey-4;
  margin-top: $spacing-l;

  .l-page-footer__col {

    @include mq(medium) {
      & + .l-page-footer__col {
        border-left: 1px solid $color-border-1;
      }
    }
  }
}