@use 'sass:math';

// =============================================================================
// In the Field Grid
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.l-itf-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 900px;
  margin: auto;
  transition: opacity 0.5s $ease-out-expo;

  &.is-loading {
    opacity: 0.5;
  }
}

.l-itf-grid__block {
  width: 100%;

  @include mq($until: itfGridL) {
    
    &:nth-of-type(n+2) {
      margin-top: $spacing-xs;
    }    
  }

  @include mq(itfGridL) {
    width: calc(50% - #{math.div($gutter-l, 2)});

    &:nth-of-type(n+3) {
      margin-top: $spacing-xs;
    }    
  }
}

.l-itf-grid__msg {
  @include heading-xs;
  color: $color-text-light;

  @include mq($until: itfGridL) {
    text-align: center;
    width: 100%;
  }
}
