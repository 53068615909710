// =============================================================================
// Errors
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.freeform-errors,
.freeform-form-errors,
.errors {
  margin: 18px 0 0 0;
  padding: 0;

  p,
  li {
    @include body-xxs;
    color: $color-error;
  }
}

.g-recaptcha ~ .errors {
  width: 100%;
}
