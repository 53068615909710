// =============================================================================
// Related Lists
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.related-list {
  margin: 0;
  margin-top: $spacing-xs;
}

.related-list__heading {
  @include heading-xxs;
  color: $color-text-light;

  > a {
    color: $color-black;
  }
}

.related-list__item {
  @include heading-xxs;
  margin-top: $spacing-xxxs;
  display: block;

  .nowrap {
    white-space: nowrap;
  }
}