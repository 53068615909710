// =============================================================================
// Filter Bar
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.filter-bar {
  border-bottom: 1px solid $color-border-2;
  margin-bottom: $gutter-partners;
}

.filter-bar__inner {
  width: 85%;
  max-width: $site-max-width-inner;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  padding: $spacing-xs 0;
  
  @include mq($until: filterBarL) {
    opacity: 0;
    position: absolute;
    right: -100vw;
    transition: opacity 0.5s $ease-out-expo;
    padding-top: 20px;

    &.is-visible {
      opacity: 1;
      position: relative;
      right: initial;
    }
  }

  @include mq(medium) {
    width: 90%;
  }
}

.filter-bar__filter {

  .select__input {
    margin-top: 0;
  }

  @include mq($until: filterBarL) {
    width: 100%;

    & + .filter-bar__filter {
      margin-top: 20px;
    }

    .select__input {
      width: 100%;
    }
  }

  @include mq(filterBarL) {
    margin-right: 30px;
  }
}

.filter-bar__action {
  @include unbutton;
  @include body-xs;
  color: $color-text-light;
  cursor: pointer;
  transition: transform 0.4s $ease-in-out-expo, opacity 0.4s $ease-in-out-expo;
  text-transform: capitalize;

  @include mq($until: filterBarL) {
    margin-top: 40px;
    width: 100%;
    text-align: center;
  }
}

.filter-bar__mobile-toggle {
  @include unbutton;
  @include eyebrow-l;
  color: $color-text;
  cursor: pointer;
  padding: 20px 7.5%;
  position: relative;

  @include mq(filterBarL) {
    display: none;
  }

  &:after {
    content: "";
    display: block;
    height: 16px;
    width: 16px;
    right: 7.5%;
    top: 20px;
    position: absolute;
    background-image: url('../images/icon-select-arrow-down.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 12px;
  }

  &.is-toggled {

    &:after {
      transform: rotate(180deg);
    }
  }
}


// -----------------------------------------------------------------------------
// STATES
// -----------------------------------------------------------------------------

.filter-bar__action {
  
  &.is-hidden {
    opacity: 0;
    pointer-events: none;
    transform: translateY(-5px);
    position: absolute;
    top: -100vw;
  }
}
