// =============================================================================
// Provider Tile
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.provider-section-wrap {
  background-color: $color-grey-4;
  padding-top: 100px;
  padding-bottom: 100px;
}

.provider-tile-wrap {
  @include mq(small) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 40px $spacing-xxs 15px $spacing-xxs;
  }

  @include mq(medium) {
    padding: 40px $spacing-xs 15px $spacing-xs;
  }
}

.provider-tile {
  @include card-outer();
  background: $color-white;
  border-radius: 4px;
  margin: 25px;

  @include mq(small) {
    width: 60%;
  }

  @include mq(medium) {
    width: 40%;
  }

  @include mq(large) {
    width: 25%;
  }

  .provider-tile__inner {
    @include card-inner();
    height: 100%;
  }

  .provider-tile__content {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 40px $spacing-xxs 15px $spacing-xxs;

    @include mq(large) {
      padding: 40px $spacing-xs 15px $spacing-xs;
    }
  }

  .provider-tile__logo {
    width: 140px;
    height: 90px;
    margin: 16px auto 8px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .provider-tile__name {
    font-size: 28px;
    margin-bottom: 8px;
  }

  .provider-tile__summary {
    color: $color-text-light;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    @include mq(medium) {
      font-size: 21px;
    }
  }

  .provider-tile__link {
    margin-top: 5px;
    margin-bottom: 15px;

    @include mq(large) {
      display: inline-block;
      transform: translateY(10px);
      opacity: 0;
      transition: transform $ease-out-expo 0.8s 0s, opacity $ease-out-expo 0.8s 0s;
      margin-top: 15px;
      margin-bottom: 0;
    }
  }
  
  &.external {
    .provider-tile__link {
      position: relative;

      &::after {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        right: 0;
        top: 0;
        background-image: url('../images/icon-external.svg');
        background-repeat: no-repeat;
        background-size: 12px 12px;
        z-index: 1;
      }
    }
  }

  @include mq(large) {
    &:hover {
      .provider-tile__link {
        transform: translateY(0);
        opacity: 1;
        transition: transform $ease-out-expo 0.8s 0.15s, opacity $ease-out-expo 0.8s 0.15s;
      }
    }
  }
}

/* This shouldn’t be here. */
.resource-cards {
  .wrap {
    margin-left: -15px;
    margin-right: -15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card-b {
    margin: 15px;
  }

  .card-b__inner {
    height: 100%;
  }
}
