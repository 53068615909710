// =============================================================================
// Site Header
// =============================================================================
/*
   Main header for the site that contains the primary navigation.
*/


.site-notice {
  width: 100%;
  padding: 15px 30px;
  position: relative;
  text-align: center;

  &:before {
    content: " ";
    background: $gradient-blue-to-red;
    position: absolute;
    width: 300%;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 0;
  }

  p {
    position: relative;
    z-index: 1;
    color: $color-white;
    line-height: 1.14;

    a {
      text-decoration: underline;
    }
  }
}

.site-header {
  width: 100%;
  text-align: center;
  padding-top: 60px;
  transition: transform $ease-out-expo 0.5s, opacity $ease-out-expo 0s 0.5s;
  will-change: transform;
  position: relative;
  z-index: 6;

  @include mq($until: mobileNavSearch) {
    padding-top: 20px;
  }
}

.site-header__inner {
  display: flex;
  margin: auto;
  max-width: $site-max-width-inner;
  width: 90%;
  align-items: stretch;
  align-content: center;
}

.site-header__logo {
  width: 33.333%;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;

  > svg {
    width: 170px;
    height: auto;
    transform: translateY(1px);
  }

  @include mq($until: 499px) {
    width: 50%;

    > svg {
      width: 100%;
    }
  }

  @include mq($until: mobileNavSearch) {
    > svg {
      // width: 185px;
    }
  }

  @include mq(navFull) {
    font-size: 16px;
    width: 22%;
    justify-content: flex-start;

    > svg {
      // width: 218px;
      // transform: translateY(1px);
    }
  }

  @include mq(navXL) {
    width: 25%;

    > svg {
      // width: 222px;
      // transform: translateY(1px);
    }
  }
}

.site-header__nav {

  @include mq($until: navFull) {
    display: none;
  }

  @include mq(navFull) {
    font-size: 16px;
    width: 55%;
    padding: 18px 0;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: flex-end;
    font-size: 18px;
    padding-right: 20px;
  }

  @include mq(navXL) {
    width: 50%;
    justify-content: center;
    padding-right: 0;
  }

  @include mq(xxlarge) {
    font-size: 18px;
  }
}


.site-header__right {
  width: 33.333%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  @include mq(navFull) {
    width: 28%;
  }

  @include mq(navXL) {
    width: 25%;
  }
}

.site-header__nav-item {
  position: relative;

  @include mq(navFull) {
    font-size: 16px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: flex-end;
    transition: color $ease-out-expo 0.5s;
    z-index: 6;

    &:hover,
    &:focus {
      color: $color-blue;
      text-decoration: underline;

      .site-header__subnav-icon > svg * {
        stroke: $color-blue !important;
      }
    }
  }
  @include mq(navXL) {
    font-size: 18px;
  }

  & + .site-header__nav-item {

    @include mq(navFull) {
      margin-left: 28px;
    }

    @include mq(navXL) {
      margin-left: 36px;
    }
  }

  &.is-active {

    > a {
      position: relative;
      z-index: 0;

      &:before {
        content: "";
        position: absolute;
        left: -16px;
        right: -16px;
        top: -8px;
        bottom: -8px;
        background-color: $color-grey-4;
        z-index: -1;
        border-radius: 20px;
      }
    }
  }
}

.site-header__nav-item:not(:hover):not(:focus):not(:focus-within) {
  .site-header__subnav {
    visibility: hidden;
    pointer-events: none;
  }
}

.site-header__subnav {
  position: absolute;
  z-index: 1;
  top: 100%;
  padding-top: 21px;
  right: -52px;
  transform: translateY(20px);
  opacity: 0;
  width: 180px;
  transition: opacity $ease-out-expo 0.5s, transform $ease-out-expo 0.5s;//, right linear 0s 0.5s;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 30px;
    left: 8px;
    right: 8px;
    bottom: 0px;
    box-shadow: $shadow-basic;
  }

  &:after {
    content: "";
    height: 12px;
    width: 12px;
    background: $color-white;
    box-shadow: -2px -2px 4px rgba($color-shadow, 0.05);
    position: absolute;
    right: 48px;
    top: 10px;
    transform: rotate(45deg) translateY(50%);
  }
}

.site-header__subnav-icon {
  width: 12px;
  height: 12px;
  display: inline-block;
  margin-left: 4px;
  vertical-align: bottom;
}

.site-header__subnav-inner {
  background-color: $color-white;
  position: relative;
  text-align: left;
  padding: 16px;
  border-radius: 4px;
}

.site-header__subnav-item {
  width: 100%;
  display: block;
  color: $color-black;
  transition: color $ease-out-expo 0.5s;
  padding: 6px 0;
  position: relative;
  z-index: 1;

  &:hover {
    color: $color-blue;
    text-decoration: underline;
  }
}


.site-header__try-craft {
  position: relative;
  @include mq($until: mobileNavSearch) {
    display: none;
  }
}

.site-header__search-btn {
  @include unbutton;
  // border: 1px solid $color-border-1;
  height: 56px;
  line-height: 56px;
  text-align: center;
  width: 56px;
  position: relative;
  margin-left: 12px;
  border-radius: 4px;
  z-index: 0;
  cursor: pointer;
  background-color: transparent;

  > svg {
    display: inline-block;
    margin-right: 3px;
    margin-bottom: 3px;
    vertical-align: middle;
  }

  &:hover {

    @include mq(medium) {
      transition: background $ease-out-expo 0.5s;
      background: $color-grey-4;
    }
  }

  @include mq($until: mobileNavSearch) {
    border: none;
  }
}

.site-header__mobile-nav-toggle {
  @include unbutton();
  display: none;

  @include mq($until: navFull) {
    @include eyebrow-l;
    display: block;
    cursor: pointer;
    width: 33.33%;
    display: flex;
    align-items: center;
    align-content: center;

    // Toggle Icon
    > span {
      height: 10px;
      width: 21px;
      display: inline-block;
      vertical-align: middle;
      position: relative;
      margin-left: 6px;

      &:after,
      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        background-color: $color-black;
      }

      &:before {
        top: 0px;
      }

      &:after {
        bottom: 0px;
      }
    }
  }
}


// -----------------------------------------------------------------------------
// Displaying Subnavs
// -----------------------------------------------------------------------------

.site-header__nav-item {
  &:focus-within,
  &:hover,
  &:focus {
    .site-header__subnav {
      // right: -52px;
      transform: translateY(0);
      opacity: 1;
      transition: opacity $ease-out-expo 0.5s, transform $ease-out-expo 0.5s;
    }
  }
}

.site-header__subnav-item--external {
  background: url('../images/icon-external.svg');
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: 100% 8px;
}


// -----------------------------------------------------------------------------
// Sticky
// -----------------------------------------------------------------------------

.js-header-flick {
  position: absolute;
  top: 80vh;
}

.site-header--sticky {
  position: fixed;
  top: 0;
  z-index: $z-index-header;
  background-color: $color-white;
  transform: translateY(-100%);
  box-shadow: $shadow-s;
  opacity: 0;
  padding: 15px 0;
  visibility: hidden;

  @include mq(navFull) {
    .site-header__logo {

      > svg {
        width: 170px;
        // transform: translateY(1px);
      }
    }
  }
}

.site-header--sticky.is-flickable {
  visibility: visible;
  opacity: 1;
  transition: transform $ease-out-expo 0.5s;

  &.is-visible {
    transform: translateY(0);
  }
}
