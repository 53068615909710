// =============================================================================
// Card Offering
// =============================================================================
//
// Card that displays a featured service offering.
//


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.card-offering {
  @include card-outer();
  display: block;

  &:hover {
    z-index: 1;
  }
}

.card-offering__inner {
  @include card-inner();
}

.card-offering__content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  position: relative;
  z-index: 2;
  padding: $spacing-xxs $spacing-xxxs;

  @include mq(offeringCardsMedium) {
    flex-direction: row;
    align-items: flex-start;
    text-align: left;
  }

  @include mq(offeringCardsFull) {
    flex-direction: row;
    padding: $spacing-m $spacing-xs;
  }
}

.card-offering__icon {
  width: 6rem;

  @include mq(offeringCardsMedium) {
    width: 25%;
    padding-right: $spacing-xxs;
  }

  @include mq(offeringCardsFull) {
    width: 15%;
  }

  img {
    width: 100%;
    max-width: 60px;
    max-height: 60px;
    margin: 0 auto;
  }
}

.card-offering__overview {
  width: 75%;

  @include mq(offeringCardsFull) {
    width: 50%;
    padding-right: $spacing-xs;
  }
}

.card-offering__heading {
  @include heading-m;

  @include mq($until: offeringCardsMedium) {
    margin-top: $spacing-xxs;
  }
}

.card-offering__body {
  @include body-s;
  margin-top: $spacing-xxs;
}

.card-offering__btn {
  margin-top: $spacing-xs;
}


.card-offering__list {
  margin-top: $spacing-xxs;

  @include mq($until: offeringCardsMedium) {
    min-width: 85vw;
  }

  @include mq(offeringCardsMedium) {
    width: 75%;
    margin-left: 25%;
  }

  @include mq(offeringCardsFull) {
    width: 35%;
    margin: 0;
  }
}
