// =============================================================================
// Partners Verification Tiers Table
// =============================================================================
//
//


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.partners-table {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: $spacing-s;
  border-bottom: 1px solid $color-border-2;

  @include mq($until: medium) {
    width: 110%;
    margin-left: -5%;
  }
}

.partners-table__tiers-header {
  display: none;

  @include mq(large) {
    display: block;
    width: 66%;
    margin-left: 34%;
    text-align: center;
    padding-bottom: $spacing-xxs;
    color: $color-text-light;
    border-bottom: 1px solid $color-border-2;
  }
}

.partners-table__requirements-col-l {
  display: none;
  
  @include mq(large) {
    display: block;
    width: 34%;
  }
}

.partners-table__requirements-col-l-header {
  color: $color-text-light;

  @include mq(large) {
    padding-left: 20px;
  }
}

.partners-table__requirements-col-l-header,
.partners-table__tier-header {
  height: 150px;
  padding-bottom: 30px;
  display: flex;
  align-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;

  @include mq($until: small) {
    height: auto;
  }
}

.partners-table__tier {
  width: 100%;
  text-align: center;
  background-color: $color-white;
  transition: box-shadow 0.5s $ease-out-expo;
  border-top: 1px solid $color-border-2;
  padding: 40px;

  @include mq($from: small, $until: medium) {
    width: 50%;
    padding-top: 0;
    
    &:nth-of-type(even) {
      border-left: 1px solid $color-border-2;
    }
  }

  @include mq(medium) {
    width: 25%;
    padding: 0;
    border-left: 1px solid $color-border-2;

    &:last-of-type {
      border-right: 1px solid $color-border-2;
    }
  }

  @include mq(large) {
    width: 16.5%;
    border-top: 0px;

    &:hover {
      background-color: rgba($color-blue, 0.01);
      box-shadow: $shadow-m;
      z-index: 1;
    }
  }
}

.partners-table__tier-header {
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
}

.partners-table__tier-label {
  width: 100%;
  font-size: 16px;
  margin-top: 18px;

  @include mq(xlarge) {
    font-size: 18px;
  }
}

.partners-table__tier-row,
.partners-table__requirements-row {

  @include mq(medium) {
    padding-top: 20px;
    border-top: 1px solid $color-border-2;

    &:nth-of-type(even) {
      background-color: rgba($color-grey-4, 0.5);
    }
  }

  @include mq(large) {
    padding: 0;
    height: 60px;
    line-height: 60px;
  }
}

.partners-table__requirements-row {

  @include mq(large) {
    border-left: 1px solid $color-border-2;
    padding-left: 20px;
  }
}

.partners-table__mobile-row-header {
  font-size: 13px;
  padding: 12px 0 20px;
  color: $color-text-lightest;

  @include mq(large) {
    display: none;
  }
}

.partners-table__requirements-row-requirement {
  font-size: 15px;

  @include mq(xlarge) {
    font-size: 16px;
  }
}

.partners-table__row-value {
  color: $color-text;
}