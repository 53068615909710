// =============================================================================
// Site Wide Variables
// =============================================================================

/*
  Colors and spacing at the top so they're available to other variables
  below. All other categories are listed in alphabetical order.
*/


// -----------------------------------------------------------------------------
// Colors & Gradients
// -----------------------------------------------------------------------------

// Primary Brand Colors
$color-red: #E5422B;
$color-blue: #4A7CF6;
$color-blue-dark: #4370de;

// Secondary Colors
$color-blue-electric: #184CEF;

// Background "Grays"
// Numbers increase with the white value tint of the color
$color-grey-1: #637599;
$color-grey-2: #D4DCEC;
$color-grey-3: #E5EDFD;
$color-grey-4: #F1F5FD;
$color-grey-5: #FAFBFE;

// Border Colors
// Numbers increase with the white value tint of the color
$color-border-1: $color-grey-2;
$color-border-2: $color-grey-3;
$color-border-3: $color-grey-4;

// Gradients
$gradient-blue-to-red: linear-gradient(to right, #184cef 0%, #e5422b 100%);
$gradient-blue-to-purple: linear-gradient(to right, #184cef 0%, #734AB5 100%);

// "Black" and "White"
$color-black: #0F0F0F;
$color-white: #FFFFFF;

// Shadows
$color-shadow: #064375;

// Text
$color-text: #4F4F4F;
$color-text-lighter: $color-grey-1;
$color-text-light: #9DA9C0;
$color-text-lightest: rgba($color-text-light, 0.7);

// Validations
$color-success: #48B36B;
$color-error: #E32C2C;
$color-warning: #F9B547;


// -----------------------------------------------------------------------------
// Spacing
// -----------------------------------------------------------------------------

$spacing-xxxl: 250px;
$spacing-xxl: 200px;
$spacing-xl: 180px;
$spacing-l: 150px;
$spacing-m: 100px;
$spacing-s: 80px;
$spacing-xs: 40px;
$spacing-xxs: 25px;
$spacing-xxxs: 10px;


// -----------------------------------------------------------------------------
// Easing
// -----------------------------------------------------------------------------

$ease-linear: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease-out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$ease-out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$ease-out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ease-in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$ease-in-out-quad: cubic-bezier(0.455, 0.03, 0.515, 0.955);


// -----------------------------------------------------------------------------
// Fonts
// -----------------------------------------------------------------------------

$font-primary: 'GT Eesti', 'Futura Std', 'Futura', 'Helvetica', sans-serif;
$font-secondary: 'Cambon Light', 'Times New Roman', serif;
$font-mono: 'Gintronic', consolas, monospace;


// -----------------------------------------------------------------------------
// Gutters
// -----------------------------------------------------------------------------

$gutter-xl: $spacing-xs;
$gutter-l: $spacing-xxs;
$gutter-itf: 15px;
$gutter-partners: 30px;
$gutter-blog: 40px;
$gutter-about: 10%;
$gutter-team-grid: 30px;


// -----------------------------------------------------------------------------
// Sass MQ
// -----------------------------------------------------------------------------

$mq-breakpoints: (
  small: 501px,
  medium: 769px,
  large: 1025px,
  xlarge: 1281px,
  xxlarge: 1441px,

  mobileNavSearch: 774px,
  navFull: 1014px,
  navXL: 1200px,

  footerS: 540px,
  footerM: 720px,
  footerFull: 930px,

  flexRowHalf: 680px,

  kbGridS: 620px,
  kbGridM: 960px,
  kbGridL: 1335px,

  blogM: 650px,
  blogL: 860px,

  productSlideshowFull: 880px,

  homeFeatureSlideshowNav: 920px,

  aboutHeroS: 960px,
  aboutHeroM: 1020px,
  aboutHeroL: 1220px,
  aboutGridS: 510px,
  aboutGridL: 810px,

  itfGridL: 620px,

  tryDropdownL: 1130px,

  pricingFull: 1150px,

  filterBarL: 670px,

  partnersGridM: 800px,
  partnersGridL: 1200px,

  partner2col: 960px,

  offeringCardsMedium: 500px,
  offeringCardsFull: 860px
);


// -----------------------------------------------------------------------------
// Shadows
// -----------------------------------------------------------------------------

$shadow-basic: 0 0 28px rgba($color-shadow, 0.4);
$shadow-s: 0 0 18px rgba($color-blue, 0.12);
$shadow-m: 0 0 48px rgba($color-blue, 0.2);
$shadow-l: 0 0 58px rgba($color-shadow, 0.4);
$shadow-xl: 0 0 78px rgba($color-shadow, 0.8);
$shadow-mockup: 0 0 58px rgba($color-shadow, 0.3);
$shadow-bottom-only: 0 10px 22px -6px rgba($color-shadow, 0.3);
$shadow-screenshot: 0 0 2px rgba($color-shadow, 0.3), 0 10px 58px rgba($color-shadow, 0.2);
$shadow-screenshot-2-up: 0 0 2px rgba($color-shadow, 0.3), 0 6px 33px rgba($color-shadow, 0.2);

// -----------------------------------------------------------------------------
// Site Containers
// -----------------------------------------------------------------------------

$site-max-width-outer: 1440px;
$site-max-width-inner: 1240px;
$site-max-width-narrow: 1100px;
$site-max-width-narrower: 960px;
$site-max-width-narrowest: 720px;


// -----------------------------------------------------------------------------
// Z-index
// -----------------------------------------------------------------------------

$z-index-header: 99;
$z-index-overlay: 999;
$z-index-mobile-nav: 1000;
$z-index-modal: 9999;
$z-index-cookie-consent: 10000;
