// =============================================================================
// About Page Layout
// =============================================================================


.l-offering-cards {
  margin-top: $spacing-xxs;
  padding-left: 0;

  @include mq(offeringCardsMedium) {
    margin-top: $spacing-s;
  }
}

.l-offering-cards__offering {
  & + & {
    margin-top: $spacing-s;

    @include mq(offeringCardsFull) {
      margin-top: $spacing-xxxs;
    }
  }
}
