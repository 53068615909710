// =============================================================================
// Card Roadmap
// =============================================================================
//
// Card that displays a product feature on the Roadmap page.
//

.roadmap-section-wrap {
  margin-top: 8rem;
}

.roadmap-heading {
  margin-bottom: 1.5rem;
  padding: 0 15px;
}

.roadmap-heading h2 {
  border-bottom: 1px solid #f1f5fd;
  padding-bottom: 1.375rem;
}

.roadmap-product-icon {
  display: inline-block;
  max-width: 21px;
  height: auto;
  margin-right: 0.25rem;
  position: relative;
  top: 2px;
}

.roadmap-cards-wrap {
  padding-top: 0;
  padding-bottom: 25px;
  margin-bottom: 50px;
}

.roadmap-card-columns {
  display: block;

  @include mq(medium) {
    display: flex;
  }
}

.roadmap-cards-column {
  padding: 0 15px;
  width: 100%;
  margin-bottom: 3rem;

  @include mq(medium) {
    margin-bottom: 0;
  }
}

.roadmap-state-heading {
  margin-bottom: 1rem;
  padding-bottom: 0.25rem;
  font-weight: bold;
}

.card-roadmap {
  margin: 8px 0;
  padding: 12px 16px;
  background: #f3f7fc;
  border-radius: 4px;
  position: relative;
  display: none;
  border: 1px solid rgba(205, 216, 228, 0.6);
  box-shadow: 0 2px 8px rgba($color-blue, 0.1);

  &.is-visible {
    display: block;
  }
}

.card-roadmap__title {
  max-width: calc(100% - 34px);
  display: inline-block;
  position: relative;
  top: 1px;
}

// Match inline code x-height to title manually
.card-roadmap__title code {
  font-size: 0.875em;
}

.card-roadmap__version {
  font-weight: normal;
  font-size: 12px;
  color: #637599;
  position: absolute;
  top: 13px;
  right: 14px;
  border: 1px solid rgba(99, 117, 153, 0.5);
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 2px;
  line-height: 1.25;
  text-align: center;
  height: 16px;
}

.card-roadmap__tooltip {
  display: inline-block;
  vertical-align: middle;
  transform: translateY(-2px);
  position: relative;
  z-index: 1;
}

.card-roadmap__meta {
  ul {
    margin: 0;
    padding: 0;
  }
}

.card-roadmap__attachments {
  margin-bottom: -2px;

  li {
    display: inline-block;
  }

  a {
    color: #4370de;
    font-size: 13px;
    padding: 0 2px 0 0;
  }

  a:hover {
    text-decoration: underline;
  }
}

.roadmap-data-notice {
  opacity: 0.75;
  color: #637599;
  margin-top: 2rem;

  a {
    text-decoration: underline;

    .icon {
      display: inline-block;
      margin-left: 4px;
      margin-right: 2px;
      width: 18px;
      height: 18px;
      position: relative;
      top: 6px;

      svg {
        width: 16px;
        height: auto;
      }
    }
  }
}
