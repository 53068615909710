// =============================================================================
// Basic Slideshow
// =============================================================================
//
// Basic Slideshow
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.basic-slideshow {
  position: relative;
  margin: $spacing-xs 0 $spacing-m;

  * {
    outline: none;
  }

  .flickity-viewport,
  .flickity-slider {
    width: 100%;
    height: 100%;
  }
}

.basic-slideshow__slide {
  width: 50%;
  margin: 0 5%;
  text-align: center;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  @include mq($until: medium) {
    width: 66.666%;
  }

  &.is-selected {

    .basic-slideshow__slide-img {
      transform: scale(1);
    }

    .basic-slideshow__slide-info {
      opacity: 1;
      transform: translateY(0);
      transition: transform 0.5s $ease-out-expo 0.35s, opacity 0.5s $ease-out-expo 0.35s;
    }
  }

  &:active { 
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.basic-slideshow__slide-img {
  transform: scale(0.9);
  transition: transform 0.5s $ease-out-expo;
}

.basic-slideshow__slide-body {
  margin-top: 4px;
}

.basic-slideshow__slide-link {
  margin-top: $spacing-xxxs;
}

.basic-slideshow__slide-info {
  margin: $spacing-xxs auto 0;
  max-width: 640px;
  opacity: 0;
  transform: translateY(10px);
  transition: transform 0.5s $ease-out-expo, opacity 0.5s $ease-out-expo;
}

.basic-slideshow__slide-layer-01
.basic-slideshow__slide-layer-02, {
  height: 100%;
  width: auto;
}

.basic-slideshow__btns {
  margin: $spacing-xs auto;
  max-width: 1360px;
}
