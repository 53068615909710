/**
 * We turned off all styles for the One Cookie Consent plugin and we have total control of the style in this file.
 * Refer to craft/plugins/onecookieconsent/resources/styles/vendor/cookieconsent for all style options
 */

//--------------------------------------------------------------
//-------------------------- animation.scss --------------------
//--------------------------------------------------------------

.cc-window {
  transition: transform $ease-out-expo 0.6s;
  transform: translateY(0);
}

.cc-window.cc-invisible {
  transform: translateY(100%);
}


//--------------------------------------------------------------
//-------------------------- base.scss -------------------------
//--------------------------------------------------------------

/* the popup window */
.cc-window {
  @include body-xxs;
  position: fixed;
  box-sizing: border-box; /* exclude padding when dealing with width */
  z-index: $z-index-cookie-consent;
  background: $color-white;
  box-shadow: $shadow-s;
  display: flex;
  bottom: 0;

  @include mq($until: medium) {
    flex-direction: column;
    padding: 18px;
  }

  @include mq($from: medium) {
    flex-wrap: nowrap;
    flex-direction: row;
  }
}

.cc-window.cc-static {
  position: static;
}

.cc-window.cc-banner {
  padding: 1.5em $gutter-l;
  width: 100%;
}

.cc-compliance {
  display: flex;
  align-items: center;

  @include mq($until: medium) {
    margin-top: 12px;
    width: 100%;
  }

  @include mq($from: medium) {
    justify-content: flex-end;
    min-width: 380px;
    width: auto;
  }
}

.cc-message {

  @include mq($until: medium) {
    width: 100%;
  }
}

/* clickable things */
.cc-btn,
.cc-link {
  cursor: pointer;

  .btn__content {
    /* keep Safari from hiding “Allow cookies” text */
    transform: translateZ(0);
  }
}

.cc-link,
.cc-btn-as-link {
  @include unbutton;
  font-family: $font-primary;
  display: inline-block;
  font-size: 15px;
  padding: 0.2em;
  text-decoration: underline;
  color: $color-blue;
  border: none;

  @include mq(xlarge) {
    font-size: 16px;
  }

  @include mq(xxlarge) {
    font-size: 18px;
  }

  &:hover {
    text-decoration: none;
    background: transparent;
    color: $color-blue;
  }
}


//--------------------------------------------------------------
//-------------------------- layout.scss -----------------------
//--------------------------------------------------------------

/* This file should contain CSS that modifies the popup layout. */
/* By layout, we mean the physical position of the elements on the popup window, and the margin / padding around those elements. */

.cc-window.cc-banner {
  align-items: center;
}

.cc-banner.cc-top {
  left: 0; right: 0; top: 0;
}
.cc-banner.cc-bottom {
  left: 0; right: 0; bottom: 0;
}

.cc-banner .cc-message {
  
  @include mq($until: medium) {
    display: block;
  }

  @include mq($from: medium) {
    margin-right: 10px;
    flex: 1;
  }
}

/* COMPLIANCE BOX */

.cc-compliance {

  & * + * {
    margin-left: 1em;
  }

  @include mq($from: medium) {
    display: flex;
    align-items: center;
  }
}


//--------------------------------------------------------------
//-------------------------- media.scss ------------------------
//--------------------------------------------------------------

@media print {
  .cc-window {
    display: none;
  }
}