// =============================================================================
// About Hero
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------


.about-hero {
  position: relative;
  max-width: $site-max-width-outer; 
  margin: auto;

  @include mq(aboutHeroS) {
    padding-top: $spacing-m;
  }
}



// -----------------------------------------------------------------------------
// Parallax Photos
// -----------------------------------------------------------------------------


.about-hero__photo {
  position: absolute;
  width: 140px;
  pointer-events: none;
  transition: transform 1.2s $ease-out-expo;
}

.about-hero__photo-1 {
  left: 15vw;
  top: -4vh;

  @include mq($until: aboutHeroS) {
    display: none;
  }

  @include mq(aboutHeroM) {
    left: 20vw; 
  }
}

.about-hero__photo-2 {
  left: 2.5vw;
  top: 33vh;

  @include mq($until: aboutHeroS) {
    display: none;
  }

  @include mq(aboutHeroM) {
    left: 5vw;
  }
}

.about-hero__photo-3 {
  left: 1vw;
  top: 75vh;

  @include mq($until: aboutHeroS) {
    display: none;
  }

  @include mq(aboutHeroM) {
    left: 8vw;
  }

  @include mq(aboutHeroL) {
    left: 10vw;
  }
}

.about-hero__photo-4 {
  right: 15vw;
  top: 5vh;

  @include mq($until: aboutHeroS) {
    display: none;
  }

  @include mq(aboutHeroM) {
    right: 20vw;
  }
}

.about-hero__photo-5 {
  right: 0vw;
  top: 42vh;

  @include mq($until: aboutHeroS) {
    display: none;
  }

  @include mq(aboutHeroM) {
    right: 3vw;
  }
}

.about-hero__photo-6 {
  right: -2vw;
  top: 70vh;

  @include mq($until: aboutHeroS) {
    display: none;
  }

  @include mq(aboutHeroM) {
    right: 2vw;
    top: 85vh;
  }

  @include mq(aboutHeroL) {
    right: 10vw;
  }
}