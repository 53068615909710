@use 'sass:math';

// =============================================================================
// Features Main Layout
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.l-features {
  display: flex;
  margin: $spacing-m 0;
  flex-wrap: wrap;

  & + .l-features {
    margin-top: $spacing-m;
  }

  @include mq(medium) {
    margin: $spacing-l 0;
  }
}

.l-features__aside {
  width: 100%;
  margin-bottom: $spacing-s;

  @include mq($until: large) {
    display: flex;
    justify-content: center;

    .blurb {
      text-align: center;
    }
  }

  @include mq(large) {
    width: 25%;
    margin-right: 5%;
    margin-bottom: 0;
  }
}

.l-features__main {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  @include mq(large) {
    width: 70%;
  }

  .card-b, .card-b__inner {
    height: 100%;
  }
}

.l-features__feature-block {
  width: 100%;

  @include mq($until: medium) {
    display: flex;
    justify-content: center;
    
    &:nth-of-type(n+2) {
      margin-top: $gutter-l;
    }
  }


  @include mq(medium) {
    width: calc(50% - #{$gutter-l - math.div($gutter-l, 2)});
    margin-left: $gutter-l;

    &:nth-of-type(odd) {
      margin-left: 0;
    }

    &:nth-of-type(n+3) {
      margin-top: $gutter-l;
    }
  }
}
