// =============================================================================
// Footer CTA
// =============================================================================

.l-footer-cta {
  margin: $spacing-m 0;

  @include mq(xlarge) {
    margin-top: $spacing-l;
    margin-bottom: $spacing-l;
  }

  @include mq(xxlarge) {
    margin-top: $spacing-xl;
  }
}

.l-footer-cta__heading {
  margin-bottom: $spacing-s;
}