// =============================================================================
// Stat lists
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.stat-list {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: $spacing-xs auto 0;
  padding: 0;
  width: 100%;
}

.stat-list__stat {
  display: block;
  text-align: center;
  width: 33.333%;
  position: relative;

  & + .stat-list__stat {
    margin-left: $spacing-xxxs;
  }

  a {
    display: block;

    &:hover {
      margin: #{-$spacing-xxs} #{-$spacing-xxxs};
      width: calc(100% + #{$spacing-xxxs * 2});
      padding: #{$spacing-xxs} #{$spacing-xxxs};
      border-radius: 8px;
      background-color: $color-grey-4;

      &,
      & .body-micro {
        color: $color-blue;
      }
    }

    &.stat-list__stat--external:hover {
      background-image: url('../images/icon-external.svg');
      background-repeat: no-repeat;
      background-size: 14px 14px;
      background-position: calc(100% - 8px) 8px;
    }
  }
}

.stat-list__stat-img {
  height: 40px;
  text-align: center;
  margin-bottom: $spacing-xxxs;

  > img,
  > svg {
    display: inline-block;
    height: 100%;
    width: auto;
  }
}

.stat-list__stat-number {
  @include heading-m;
  text-align: center;

  span {
    font-family: "Helvetica", "Arial", sans-serif;
    font-size: 0.8em;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $color-blue;
    display: inline-block;
    transform: translateY(-0.2em);
    margin-left: 0.1em;
  }
}
