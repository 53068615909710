// =============================================================================
// Mockup: Desktop
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.mockup-desktop {
  position: absolute;
  top: 0;
  width: 100%;

  &:after {
    content: "";
    position: absolute;
    top: 20px;
    left: 10px;
    right: 10px;
    bottom: 0px;
    box-shadow: $shadow-m;
    z-index: -1;
    transition: box-shadow 1s $ease-out-expo 0.1s;
  }
}

.mockup-desktop__browser-top {
  display: block;
  width: 100%;

  img,
  svg {
    width: 100%;
  }
}

.mockup-desktop__inner {
  
  img {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    transition: opacity 0.2s linear;
    opacity: 0;

    &.lazyloaded {
      opacity: 1;
    }
  }
}



// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------

.is-selected .mockup-desktop::before {
  box-shadow: $shadow-mockup;
}
