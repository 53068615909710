// =============================================================================
// Button Navigation
// =============================================================================
//
// Slideshow interactive button navigation
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------


.btn-nav {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.btn-nav__heading {
  @include heading-s;
  color: $color-text-light;
  width: 100%;
  text-align: center;
  margin-bottom: $spacing-xs;
  margin-top: $spacing-m;
  transition: opacity 0.5s $ease-out-expo, transform 0.5s $ease-out-expo;

  @include mq(xxlarge) {
    margin-top: $spacing-l;
  }
}

.btn-nav__btn {
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: transform $ease-out-expo 0.5s;

  @include mq(productSlideshowFull) {
    width: 23%;
  }

  &.is-selected,
  &:hover {

    .btn-nav__btn-title {
      color: $color-black;
    }
  }

  &.is-selected {
    transform: translateY(-12px);

    &:after {
      transform: translateX(-50%);
      opacity: 1;
      transition: opacity $ease-out-expo 0.5s 0.2s, transform $ease-out-expo 0.5s 0.2s;
    }

    .btn-nav__btn-img {

      img {

        &:nth-of-type(1) {
          opacity: 1;
        }
      }
    }
  }

  &:after {
    content: "";
    height: 12px;
    width: 12px;
    background: $gradient-blue-to-purple;
    border-radius: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) translateY(10px);
    bottom: -25px;
    opacity: 0;
    transition: opacity $ease-out-expo 0.5s, transform $ease-out-expo 0.5s;
  }
}

.btn-nav__btn-img {
  width: 100%;
  margin: auto;
  position: relative;
  text-align: center;

  img {
    max-width: 250px;
    width: 100%;
    display: inline-block;

    &:nth-of-type(1) {
      position: absolute;
      transform: translateX(-50%);
      left: 50%;
      top: 0;
      opacity: 0;
      transition: opacity 1.5s $ease-out-expo;
    }
  }
}

.btn-nav__btn-title {
  font-size: 15px;
  margin-top: $spacing-xxs;
  color: $color-grey-1;
  transition: color $ease-out-expo 0.5s;

  @include mq(medium) {
    font-size: 16px;
  }

  @include mq(large) {
    font-size: 18px;
  }

  @include mq(xlarge) {
    font-size: 21px;
  }
}


//-----------------------------------------------------------------------------
// Large
//-----------------------------------------------------------------------------

.btn-nav--l {

  .btn-nav__btn {

    &:after {
      content: "";
      height: 16px;
      width: 16px;
      left: 50%;
      transform: translateX(-50%) translateY(10px);
      bottom: -30px;
    }

    &.is-selected {

      &:after {
        transform: translateX(-50%);
      }
    }
  }

  .btn-nav__btn-title {
    font-size: 16px;
    white-space: nowrap;

    @include mq(medium) {
      font-size: 18px;
    }

    @include mq(large) {
      font-size: 21px;
    }

    @include mq(xlarge) {
      font-size: 24px;
    }
  }
}


//-----------------------------------------------------------------------------
// Bordered
//-----------------------------------------------------------------------------

.btn-nav--border {
  padding-bottom: $spacing-s;
  border-bottom: 1px solid $color-border-2;
}


//-----------------------------------------------------------------------------
// States
//-----------------------------------------------------------------------------

.btn-nav__heading.is-hidden {
  opacity: 0;
  transform: translateY(10px);
}