//===============================================================
// Typography
//===============================================================

/*
  Font-face imports as well as some useful type-related mixins
*/


//---------------------------------------------------------------
// Font-face
//---------------------------------------------------------------

@font-face {
  font-family: 'Cambon Light';
  src: url('../fonts/cambon-light.woff2') format('woff2'),
       url('../fonts/cambon-light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti';
  src: url('../fonts/GT-Eesti-Display-Light.woff2') format('woff2'),
       url('../fonts/GT-Eesti-Display-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}  

@font-face {
  font-family: 'GT Eesti';
  src: url('../fonts/GT-Eesti-Display-Light-Italic.woff2') format('woff2'),
       url('../fonts/GT-Eesti-Display-Light-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'GT Eesti';
  src: url('../fonts/GT-Eesti-Display-Regular.woff2') format('woff2'),
       url('../fonts/GT-Eesti-Display-Regular.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti';
  src: url('../fonts/GT-Eesti-Display-Medium.woff2') format('woff2'),
       url('../fonts/GT-Eesti-Display-Medium.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'GT Eesti';
  src: url('../fonts/GT-Eesti-Display-Medium-Italic.woff2') format('woff2'),
       url('../fonts/GT-Eesti-Display-Medium-Italic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gintronic';
  src: url('../fonts/Gintronic-Light.woff2') format('woff2'),
       url('../fonts/Gintronic-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}


//---------------------------------------------------------------
// Rich Text Mixins
//---------------------------------------------------------------

@mixin formatting {

  strong {
    font-weight: bold;
    color: $color-black;
  }

  i, em {
    font-style: italic;
  }
}

@mixin basicLinks {

  a {
    color: $color-blue;
    transition: border $ease-out-expo 0.3s;
    border-bottom: 1px solid transparent;

    &:hover {
      border-bottom: 1px solid $color-blue;
    }
  }
}


//---------------------------------------------------------------
// Headings
//---------------------------------------------------------------

@mixin heading-xl {
  font-family: $font-secondary;
  font-size: 32px;
  line-height: 1.085;
  letter-spacing: -0.04em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(small) {
    font-size: 7vw;
  }

  @include mq(medium) {
    font-size: 6vw;
  }

  @include mq(large) {
    font-size: 5.5vw;
  }

  @include mq(xlarge) {
    font-size: 5.25vw;
  }

  @include mq(xxlarge) {
    font-size: 78px;
  }
}

.heading-xl { @include heading-xl; };

@mixin heading-l {
  font-family: $font-secondary;
  font-size: 28px;
  line-height: 1.16;
  letter-spacing: -0.03em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(small) {
    font-size: 36px;
  }

  @include mq(medium) {
    font-size: 42px;
  }

  @include mq(large) {
    font-size: 48px;
  }

  @include mq(xlarge) {
    font-size: 54px;
  }

  @include mq(xxlarge) {
    font-size: 58px;
  }
}

.heading-l { @include heading-l; };

@mixin heading-l-alt {
  font-size: 28px;
  line-height: 1.16;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(small) {
    font-size: 36px;
  }

  @include mq(medium) {
    font-size: 42px;
  }

  @include mq(large) {
    font-size: 48px;
  }

  @include mq(xlarge) {
    font-size: 54px;
  }

  @include mq(xxlarge) {
    font-size: 58px;
  }
}

.heading-l-alt { @include heading-l-alt; };

@mixin heading-ml {
  font-family: $font-secondary;
  font-size: 28px;
  line-height: 1.16;
  letter-spacing: -0.03em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(small) {
    font-size: 32px;
  }

  @include mq(medium) {
    font-size: 36px;
  }

  @include mq(large) {
    font-size: 42px;
  }

  @include mq(xlarge) {
    font-size: 48px;
  }

  @include mq(xxlarge) {
    font-size: 52px;
  }
}

.heading-ml { @include heading-ml; };

@mixin heading-m {
  font-family: $font-primary;
  font-size: 24px;
  line-height: 1.167;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(medium) {
    font-size: 28px;
  }

  @include mq(large) {
    font-size: 32px;
  }

  @include mq(xlarge) {
    font-size: 36px;
  }
}

.heading-m { @include heading-m; };

@mixin heading-m-alt {
  font-family: $font-secondary;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(small) {
    font-size: 28px;
  }

  @include mq(medium) {
    font-size: 32px;
  }

  @include mq(large) {
    font-size: 36px;
  }

  @include mq(xlarge) {
    font-size: 42px;
  }
}

.heading-m-alt { @include heading-m-alt; };

@mixin heading-s {
  font-family: $font-primary;
  font-size: 21px;
  line-height: 1.21;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(medium) {
    font-size: 24px;
  }

  @include mq(xlarge) {
    font-size: 28px;
  }
}

.heading-s { @include heading-s; };

@mixin heading-xs {
  font-family: $font-primary;
  font-size: 18px;
  line-height: 1.33;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(medium) {
    font-size: 21px;
  }
}

.heading-xs { @include heading-xs; };

@mixin heading-xxs {
  font-family: $font-primary;
  font-size: 16px;
  line-height: 1.33;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;

  @include mq(medium) {
    font-size: 18px;
  }
}

.heading-xxs { @include heading-xxs; };


//---------------------------------------------------------------
// Eyebrows
//---------------------------------------------------------------

@mixin eyebrow-l {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.154;
  letter-spacing: 2px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(xlarge) {
    font-size: 15px;
  }
}

.eyebrow-l { @include eyebrow-l; };

@mixin eyebrow-s {
  font-size: 11px;
  line-height: 1.154;
  font-weight: bold;
  letter-spacing: 2px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(xlarge) {
    font-size: 13px;
  }
}

.eyebrow-s { @include eyebrow-s; };


//---------------------------------------------------------------
// Body
//---------------------------------------------------------------

@mixin body-l {
  font-size: 24px;
  line-height: 1.375;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(medium) {
    font-size: 28px;
  }

  @include mq(xlarge) {
    font-size: 32px;
  }
}

.body-l { @include body-l; };

@mixin body-m {
  font-size: 21px;
  line-height: 1.38;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(medium) {
    font-size: 24px;
  }

  @include mq(xlarge) {
    font-size: 26px;
  }
}

.body-m { @include body-m; };

@mixin body-s {
  font-size: 16px;
  line-height: 1.7;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(medium) {
    font-size: 18px;
    line-height: 1.5;
  }

  @include mq(xlarge) {
    font-size: 21px;
  }
}

.body-s { @include body-s; };

@mixin body-xs {
  font-size: 16px;
  line-height: 1.44;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(xlarge) {
    font-size: 18px;
  }
}

.body-xs { @include body-xs; };

@mixin body-xxs {
  font-size: 14px;
  line-height: 1.32;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mq(xlarge) {
    font-size: 16px;
  }
}

.body-xxs { @include body-xxs; };

@mixin body-micro {
  font-size: 14px;
  line-height: 18px;
  color: $color-text;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body-micro { @include body-micro; };


//---------------------------------------------------------------
// Helper Classes and Mixins
//---------------------------------------------------------------


// Helper Classes
// ——————————————————————————————————————————————————————————————

.h-include-formatting {
  @include formatting;
}

.h-weight-bold {
  font-weight: bold;
}

.h-mono {
  font-family: $font-mono;
}

.h-text-align-left {
  text-align: left;
}

.h-text-align-center {
  text-align: center;
}

.h-text-align-right {
  text-align: right;
}


// Mixins
// ——————————————————————————————————————————————————————————————

@mixin placeholderColor($color: #CCCAD9) {
  &::-webkit-input-placeholder { color: $color; }
  &::-moz-placeholder {color: $color; }
  &:-ms-input-placeholder { color: $color; } 
  &:-o-input-placeholder { color: $color; } 
}
