// =============================================================================
// Blurb List
// =============================================================================
//
// A blurb with a list
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------


.blurb-list {
  max-width: 540px;
}

.blurb-list__list {
  margin-top: $spacing-xxs;
}

.blurb-list__link {
  margin-top: $spacing-xs;
}

.blurb-list__img {
  height: 250px;
  width: auto;
  text-align: center;

  > img {
    height: 100%;
    width: auto;
  }
}

.blurb-list__body {
  @include body-s;
  margin-top: $spacing-xxxs;
}


// -----------------------------------------------------------------------------
// Centered
// -----------------------------------------------------------------------------

.blurb-list--centered {

  .blurb-list__heading {
    text-align: center;
  }

  .blurb-list__link {
    text-align: center;
  }

  .blurb-list__list {
    margin-left: auto;
    margin-right: auto;
  }
}


// -----------------------------------------------------------------------------
// Centered
// -----------------------------------------------------------------------------

.blurb-list--two-col-list {
  
  .blurb-list__list {
    column-count: 2;
    column-width: 50%;
    column-gap: $spacing-xs;
    max-width: 720px;
  }
}