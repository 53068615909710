// =============================================================================
// Two Column Layout with a Sidebar and Main Column
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.l-main-w-sidebar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  margin-bottom: $spacing-xxxl;

  @include mq(large) {
    margin-top: $spacing-m;
  }
}

.l-main-w-sidebar__main {
  width: 100%;
  

  @include mq(large) {
    width: 75%;
  }
}

.l-main-w-sidebar__sidebar {
  width: 100%;

  @include mq(large) {
    width: 20%;
  }
}


// -----------------------------------------------------------------------------
// In the Field
// -----------------------------------------------------------------------------

.l-main-w-sidebar--itf {
  margin-top: $spacing-s;

  .l-main-w-sidebar__main {
    margin-top: $spacing-s;
  }

  @include mq(large) {

    .l-main-w-sidebar__main {
      width: 66.666%;
      margin-top: 0;
    }

    .l-main-w-sidebar__sidebar {
      width: 30%;
    }
  }
}



// -----------------------------------------------------------------------------
// Contained Objects
// -----------------------------------------------------------------------------

.l-main-w-sidebar__main {

  .l-post:first-of-type {
    margin-top: 0;
  }
}