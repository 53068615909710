// =============================================================================
// Form Layout
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.l-form {
  margin: auto;
  width: 100%;
  max-width: 640px;
}

.l-form__section {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  & + .l-form__section {
    margin-top: $spacing-l;
  }
}

.l-form__header {
  text-align: center;
  width: 100%;
  margin-top: $spacing-s;
}

.l-form__details {
  @include eyebrow-l;
  text-align: center;
  width: 100%;
  margin-top: $spacing-xs;
  color: $color-text-lighter;

  span {
    color: $color-red;
  }
}

.l-form__field {
  width: 100%;
  margin-top: 40px;
  transition: transform $ease-out-expo 0.5s, opacity $ease-out-expo 0.5s;

  .select__input {
    width: 100%;
  }

  @include mq(medium) {
    margin-top: 60px;
  }
}

.l-form__field-title {
  @include heading-m;
  width: 100%;
  margin-bottom: $spacing-xxs;
  text-align: center;
}

// For microcopy below submit — usually "legalese"
.l-form__footer {
  @include basicLinks;
  margin-top: $spacing-s;
  text-align: center;

  p {
    color: $color-text-lighter;
  }
}


// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------

.l-form__field.is-hidden {
  opacity: 0;
  transform: translateY(-50%);
  height: 0;
  overflow: hidden;
  margin: 0;
}

.l-form__field.has-errors {

  .l-form__field-title {
    color: $color-red;
  }
}
