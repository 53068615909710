html,
body {
  min-height: 100vh;
  overflow-x: hidden;
}

/* We’d prefer to use `overflow-x: clip` everywhere, but it’s not supported on
 some relatively recent browsers. Instead, we might be able to remove the use of
 overflow-x on the body, and do it in a few relevant components instead.
 https://github.com/craftcms/craftcom/issues/744
*/
@supports (overflow-x: clip) {
  html,
  body {
    overflow-x: clip;
  }
}

html {
  box-sizing: border-box;
}

html.scroll-behavior-smooth {
  scroll-behavior: smooth;
  @media (prefers-reduced-motion) {
    scroll-behavior: auto;
  }  
}

body {
  font-family: $font-primary;
  font-weight: normal;
  margin: 0; 

  ::selection {
    background: rgba($color-blue, 0.1); /* WebKit/Blink Browsers */
    color: $color-red;
  }
  ::-moz-selection {
    background: rgba($color-blue, 0.1); /* Gecko Browsers */
    color: $color-red;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

img,
svg {
  display: block;
  max-width: 100%;
  height: auto;
}

h1,h2,h3,h4,h5,h6, p {
  font-weight: normal;
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  margin-top: 0;
  margin-bottom: 0;
}

input, textarea {
  font-family: $font-primary;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul, ol {
  list-style-type: none;
}

code {
  font-family: $font-mono;
}

pre {
  margin: 0;
}

.btn.skip-link {
  position: absolute;
  left: 50%;
  padding: 10px 20px;
  text-align: center;
  color: $color-white;
  background: $gradient-blue-to-red;
  transform: translate3d(-50%, -100%, 0);
  transition: transform 0.3s;

  .btn__icon {
    height: 12px;
    width: 12px;
    vertical-align: bottom;
    margin-left: 0;
    margin-bottom: 1px;
  }
}

.skip-link:focus {
  transform: translate3d(-50%, -2px, 0);
}

dl {
  border-radius: 4px;
  padding: $spacing-xxs $spacing-xs;
  background: $color-grey-5;

  dt {
    margin: $spacing-xxs 0 0 0;
  }

  dd {
    margin: $spacing-xxxs 0 0 0;
  }

  & > dt:first-of-type {
    margin-top: 0;
  }

  dt {
    code {
      background-color: transparent;
      padding-left: 0;
      padding-right: 0;
    }
  }

  code {
    background: $color-grey-4;
  }
}

dt {
  font-weight: 500;
  box-sizing: border-box;
  padding-right: $spacing-xxxs;
}

dd {
  padding-left: $spacing-xxs;

  code {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
  }
}
