// =============================================================================
// Ticker
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

$ticker-width: 18vw;
$ticker-height: 200px;
$ticker-width-mobile: 28vw;
$ticker-height-mobile: 160px;

.ticker {
  width: $ticker-width;
  max-width: 220px;
  height: $ticker-height;
  color: $color-text;
  position: relative;

  @include mq($until: large) {
    width: $ticker-width-mobile;
    height: $ticker-height-mobile;
  }

  &:before {
    content: "";
    height: 1px;
    background: rgba($color-blue, 0.15);
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 5px;
    box-shadow: $shadow-m;
    z-index: -1;
  }
}

.ticker__number {
  width: $ticker-width;
  max-width: 220px;
  height: $ticker-height;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-perspective: 1000px; /* Safari 4-8 */
  perspective: 1000px;

  @include mq($until: large) {
    width: $ticker-width-mobile;
    height: $ticker-height-mobile;
  }
}

.ticker__number-top {
  font-size: 72px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 50%;
  text-align: center;
  background: linear-gradient(to bottom, #F7FBFF 0%, $color-white 100%);
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border: 1px solid $color-grey-4;
  border-bottom: 0;
  transform-origin: center 100%;


  @include mq($until: medium) {
    font-size: 10vw;
  }
}

.ticker__number-top-content {
  position: absolute;
  line-height: $ticker-height;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;

  span {
    font-size: .8em;
    line-height: 1.2em;
    font-weight: bold;
  }

  @include mq($until: large) {
    line-height: $ticker-height-mobile;
  }
}

.ticker__number-bottom {
  font-size: 72px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 50%;
  text-align: center;
  overflow: hidden;
  background: linear-gradient(to bottom, #F7FBFF 0%, $color-white 100%);
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border: 1px solid $color-grey-4;
  border-top: 0;
  transform-origin: center 0%;

  @include mq($until: medium) {
    font-size: 10vw;
  }
}

.ticker__number-bottom-content {
  position: absolute;
  line-height: $ticker-height;
  top: -100%;
  left: 0;
  right: 0;
  bottom: 0;

  span {
    font-size: .8em;
    line-height: 1.2em;
    font-weight: bold;
  }

  @include mq($until: large) {
    line-height: $ticker-height-mobile;
  }
}

.ticker__description {
  font-size: 16px;
  line-height: 18px;
  position: absolute;
  bottom: 38px;
  width: 94%;
  left: 3%;
  text-align: center;
  color: $color-text-light;

  @include mq($until: large) {
    bottom: 14px;
    font-size: 14px;
  }
}

// -----------------------------------------------------------------------------
// Animation
// -----------------------------------------------------------------------------

.ticker__number-top,
.ticker__number-bottom {
  transition: transform 0.25s linear;
}

.ticker__number--2 .ticker__number-bottom {
  transform: rotate3d(1, 0, 0, 90deg);
}

@keyframes tickTop {
  from {
    transform: rotate3d(1, 0, 0, 0);
  }
  to {
    transform: rotate3d(1, 0, 0, -90deg);
  }
}

@keyframes tickBottom {
  from {
    transform: rotate3d(1, 0, 0, 90deg);
  }
  to {
    transform: rotate3d(1, 0, 0, 0);
  }
}

.is-visible .ticker__number--2 .ticker__number-top {
  transform: rotate3d(1, 0, 0, -90deg);
}

.is-visible .ticker__number--2 .ticker__number-bottom {
  transform: rotate3d(1, 0, 0, 0);
}

// Stagger

.ticker:nth-of-type(1) {
  .ticker__number--2 .ticker__number-top {
    transition-delay: 0.25s;
  }

  .ticker__number--2 .ticker__number-bottom {
    transition-delay: 0s;
  }
}

.ticker:nth-of-type(2) {
  .ticker__number--2 .ticker__number-top {
    transition-delay: 0.5s;
  }

  .ticker__number--2 .ticker__number-bottom {
    transition-delay: 0.25s;
  }
}

.ticker:nth-of-type(3) {
  .ticker__number--2 .ticker__number-top {
    transition-delay: 0.75s;
  }

  .ticker__number--2 .ticker__number-bottom {
    transition-delay: 0.5s;
  }
}


// Backwards

.is-visible .ticker:nth-of-type(1) {
  .ticker__number--2 .ticker__number-top {
    transition-delay: 0s;
  }

  .ticker__number--2 .ticker__number-bottom {
    transition-delay: 0.25s;
  }
}

.is-visible .ticker:nth-of-type(2) {
  .ticker__number--2 .ticker__number-top {
    transition-delay: 0.25s;
  }

  .ticker__number--2 .ticker__number-bottom {
    transition-delay: 0.5s;
  }
}

.is-visible .ticker:nth-of-type(3) {
  .ticker__number--2 .ticker__number-top {
    transition-delay: 0.5s;
  }

  .ticker__number--2 .ticker__number-bottom {
    transition-delay: 0.75s;
  }
}
