@use 'sass:math';

// =============================================================================
// Home Feature Overview Layout
// =============================================================================

.l-section.home-feature-overview {
  margin-bottom: 0;
  padding: $spacing-s 0;
  background-color: $color-grey-4;

  @include mq(large) {
    padding: $spacing-m 0;
  }

  .l-wrap__inner {
    @include mq($until: small) {
      width: auto;
    }
  }

  .home-feature-overview__heading {
    margin-bottom: $spacing-s;
  }
}

.home-overview__content-btn {
  margin-top: $spacing-xs;
  text-align: center;

  @include mq(productSlideshowFull) {
    margin-top: $spacing-s;
  }
}


// =============================================================================
// Feature Overview Blurbs Layout
// =============================================================================
//
//
//

.feature-overview {
  width: 100%;
  outline: none;

  // Enable Flickity by default
  &:after {
    content: 'flickity';
    display: none;
  }


  display: grid;
  grid-gap: $gutter-l;
  grid-template-columns: 100%;

  @include mq(small) {
    grid-template-columns: repeat(2, calc(50% - #{math.div($gutter-l, 2)}));

    // Disable Flickity
    &:after {
      content: '';
    }
  }

  @include mq(medium) {
    grid-template-columns: repeat(4, calc(25% - #{math.div($gutter-xl * 3, 4)}));
    grid-gap: $gutter-xl;
  }
}

.feature-overview__item {
  @include mq($until: small) {
    width: 65%;
    padding: 0 5%;
    margin: 0 auto;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }
  }
}
