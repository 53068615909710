@use 'sass:math';

// =============================================================================
// Product Overview Audience Blurbs Layout
// =============================================================================
//
//
//

.l-product-overview {
  width: 100%;
  outline: none;

  @include mq(productSlideshowFull) {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.l-product-overview__col {
  width: 50%;
  margin: 0 $spacing-xs;

  @include mq(productSlideshowFull) {
    width: calc(25% - #{$gutter-l - math.div($gutter-l, 4)});
    margin: 0;
    margin-left: $gutter-l;

    &:nth-of-type(4n+1) {
      margin-left: 0;
    }

    &:nth-of-type(2) .blurb {
      transition-delay: 0.45s !important;
    }
    &:nth-of-type(3) .blurb {
      transition-delay: 0.55s !important;
    }
    &:nth-of-type(4) .blurb {
      transition-delay: 0.65s !important;
    }
  }
}
