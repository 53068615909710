// =============================================================================
// Brand Colors
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.brand-colors {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: $spacing-xs;

  @include mq(medium) {
    width: 120%;
    margin-left: -10%;
  }
}

.brand-colors__color {
  width: 48%;
  padding-bottom: 48%;
  margin-right: 4%;
  margin-bottom: 4%;
  position: relative;

  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }

  @include mq($until: medium) {

    &:nth-of-type(even) {
      margin-right: 0;
    }
  }

  @include mq(medium) {
    width: 31.111%;
    padding-bottom: 31.111%;
    margin-right: 3.333%;
    margin-bottom: 3.333%;

    &:nth-of-type(3n + 3) {
      margin-right: 0;
    }
  }
}

