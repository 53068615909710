// =============================================================================
// Locations Spot Interactive
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.locations-spot {
  @include aspect-ratio(1.476,1);
  margin: auto;
  margin-bottom: $spacing-xxs;
  max-width: 450px;
}

.locations-spot__locations {
  width: 100%;
  max-width: 380px;
  position: absolute;
  top: 0;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.locations-spot__shadow {
  box-shadow: $shadow-l;
  transition: opacity 0.5s $ease-out-expo;
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 0;
  opacity: 0;
}

.locations-spot__location {
  width: 40px;
  position: relative;
  height: 200px;

  &:nth-of-type(odd) {
    margin-top: -30px;
  }

  &:nth-of-type(even) {
    margin-bottom: -30px;
  }

  &:hover {

    .locations-spot__shadow {
      opacity: 1;
    }

    .locations-spot__img-wrapper {
      transform: scale(1.2) translateX(-50%) translateY(-50%);
      z-index: 1;
    }

    @supports(clip-path: polygon(20% 0%, 80% 0%, 80% 100%, 20% 100%)) {
      .locations-spot__img {
        clip-path: polygon(20% 0%, 80% 0%, 80% 100%, 20% 100%);
      }
    }
  }
}

.locations-spot__img-wrapper {
  transition: transform 0.5s $ease-out-expo, width 0.5s $ease-out-expo;
  transform: translateX(-50%) translateY(-50%);
  left: 50%;
  top: 50%;
  position: absolute;
  width: 100%;
  height: 200px;
  background-size: auto 100%;
  background-position: center;
  pointer-events: none;
  overflow: hidden;

  @supports(clip-path: polygon(20% 0%, 80% 0%, 80% 100%, 20% 100%)) {
    overflow: visible;
  }
}

.locations-spot__img {
  height: 100%;
  width: 200px;
  left: 50%;
  max-width: 200px;
  position: absolute;
  transform: translateZ(1px) translateX(-50%);
  transition: clip-path 0.5s $ease-out-expo;

  @supports(clip-path: polygon(20% 0%, 80% 0%, 80% 100%, 20% 100%)) {
    clip-path: polygon(36% 0%, 64% 0%, 64% 100%, 36% 100%);
  }
}