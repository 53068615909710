// =============================================================================
// Checkbox Fields
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.checkbox {
  position: relative;

  & + .checkbox {
    margin-top: $spacing-xxs;
  }

  &:focus-within {
    label,
    .checkbox__styled-label {
      border: 1px solid $color-grey-1;
    }

    .checkbox__label {
      color: $color-grey-1;
    }
  }
}

.checkbox__label {
  @include body-s;
  color: $color-black;
  vertical-align: top;
  margin-top: 5px;
  display: inline-block;
  cursor: pointer;
  width: calc(100% - 60px);

  // Required
  span {
    color: $color-red;
    font-weight: bold;
  }
}

.checkbox > input {
  @include hide-visually;

  &:checked {
    & + label,
    & + .checkbox__styled-label {
      background: $gradient-blue-to-purple;
    }
  }
}

.checkbox__styled-label {
  display: inline-block;
  cursor: pointer;
  margin-right: $spacing-xxxs;
  height: 40px;
  width: 40px;
  border-radius: 4px;
  border: 1px solid $color-grey-2;
  background-color: $color-white;
  transition: background-color $ease-out-expo 0.5s, border $ease-out-expo 0.5s;
  vertical-align: middle;
  position: relative;

  &:after {
    content: "";
    background: url('../images/icon-check-white-small.svg');
    background-size: 18px 18px;
    height: 18px;
    width: 18px;
    top: 10px;
    left: 10px;
    position: absolute;
  }

  &:hover {
    background-color: $color-grey-3;
    border-color: $color-grey-3;
  }
}

.checkbox__input {
  margin-top: $spacing-xxxs;

  > input,
  > textarea {
    @include resetInputStyle;
    @include body-s;
    padding: 18px;
    border-radius: 4px;
    color: $color-black;
    min-width: 420px;
    border: 1px solid $color-grey-2;
    background-color: $color-white;
    transition: background-color $ease-out-expo 0.5s, border $ease-out-expo 0.5s;
    resize: none;

    &:focus {
      background-color: $color-grey-4;
      border-color: $color-grey-4;
    }
  }
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.checkbox--s {
  & + .checkbox--s {
    margin-top: $spacing-xxxs; 
  }

  .checkbox__label {
    @include body-xxs;
    margin-top: 1px;
  }
  
  .checkbox__styled-label {
    margin-right: 5px;
    height: 20px;
    width: 20px;
    border-radius: 3px;
  
    &:after {
      background-size: 9px 9px;
      height: 9px;
      width: 9px;
      top: 5px;
      left: 5px;
    }
  }
}
