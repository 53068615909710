// =============================================================================
// Form Fields Wrapper
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.field-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.field-wrapper__label {
  @include eyebrow-l;
  color: $color-text-lighter;
  width: 100%;
  margin-bottom: $spacing-xxs;

  span {
    color: $color-red;
  }

  &.is-hidden {
    @include hide-visually;

    & + span {
      display: none;
    }
  }
}

.field-wrapper__text,
.field-wrapper__email,
.field-wrapper__phone,
.field-wrapper__textarea {
  @include resetInputStyle;
  @include body-s;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  color: $color-black;
  border: 1px solid $color-grey-2;
  background-color: $color-white;
  transition: background-color $ease-out-expo 0.5s, border $ease-out-expo 0.5s;
  resize: none;

  &:focus {
    background-color: $color-grey-4;
    border-color: $color-grey-4;
  }

  @include mq(medium) {
    min-width: 420px;
    padding: 14px;
  }
}

.field-wrapper__textarea {
  min-height: 300px;
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------
