// =============================================================================
// List
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.list {
  &.large {
    .list__item {
      font-size: 20px;

      @include mq(xxlarge) {
        font-size: 24px;
      }
    }
  }
}

.list__heading {
  margin-bottom: $spacing-xxxs;
  color: $color-grey-1;
}

.list__item {
  padding: 8px 0;
  font-size: 16px;
  display: block;
  position: relative;
  transition: opacity 1s $ease-out-expo;
  transform: translateY(0);

  @include mq(xxlarge) {
    font-size: 18px;
  }
}

.list__link {
  color: $color-black;
  transition: border $ease-out-expo 0.5s;
  border-bottom: 1px solid $color-text-light;

  &:hover { 
    border-bottom: 1px solid transparent;
  }
}

.list__group {
  padding: 0;
  margin: 0;
  break-inside: avoid;

  & + .list__group {

    @include mq(large) {
      margin-top: $spacing-xs;  
    }
  }
}

.list__tooltip {
  display: inline-block;
  margin-left: 2px;
  vertical-align: middle;
  transform: translateY(-1px);

  @include mq($from: large) {
    position: relative;
  }
}

.list__mobile-toggle {
  
  @include mq(large) {
    display: none;
  }
}


//-----------------------------------------------------------------------------
// States
//-----------------------------------------------------------------------------

.list.is-stuck {
  position: fixed;
  top: 100px;
}

.list__item.is-active {
  
  &:after {
    content: "";
    height: 8px;
    width: 8px;
    position: absolute;
    left: -16px;
    top: 13px;
    background: $color-blue-electric;
    border-radius: 100%;
  }
}


.list__group.is-closed {

  .list__heading .inline-icon {
    transform: rotate(180deg);
  }

  .list__item {
    position: absolute;
    left: -100vw;
    opacity: 0;
  }
}


// -----------------------------------------------------------------------------
// Form Element List
// -----------------------------------------------------------------------------

.list--filters {

  .list__heading {
    width: 100%;
  }

  .list__item {
    
    & + .list__item {
      margin-top: 15px;
    }
  }

  @include mq($until: xlarge) {
    display: flex;
    flex-wrap: wrap;

    .list__item {
      width: 250px;
      margin-right: 50px;
    }
  }

  .select--s {
    width: 100%;
  }
}


// -----------------------------------------------------------------------------
// List as Nav
// -----------------------------------------------------------------------------

.list--nav {
  padding-bottom: $spacing-m;

  .list__link {
    border-bottom: 1px solid transparent;

    &:hover { 
      border-bottom: 1px solid $color-text-light;
    }
  }

  @include mq($until: large) {
    padding-bottom: 0;
    margin-bottom: $spacing-s;
    border-bottom: 1px solid $color-border-2;
    width: 100vw;
    margin-left: -7.5vw;
    padding-left: 7.5vw;
    padding-right: 7.5vw;

    @include mq(medium) {
      margin-left: -5vw;
      padding-left: 5vw;
      padding-right: 5vw;      
    }

    .list__mobile-toggle {
      @include eyebrow-l;
      cursor: pointer;
      color: $color-black;
      padding: 28px 0;
      margin-bottom: 0;
      position: relative;

      &:after {
        content: "";
        display: block;
        height: 10px;
        width: 10px;
        position: absolute;
        right: 0;
        top: 28px;
        transform: rotate(45deg);
        border-right: 2px solid $color-black;
        border-bottom: 2px solid $color-black;
      }

      &.is-open {

        &:after {
          transform: rotate(-135deg);
        }
      }
    }

    .list__group {
      display: none;
      padding-bottom: 0;

      &.is-visible {
        display: block;
        padding-bottom: $spacing-xxs;

        &:last-child {
          padding-bottom: $spacing-xs;
        }
      }
    }
  }
}


// -----------------------------------------------------------------------------
// With Toggle-able Sections
// -----------------------------------------------------------------------------

.list--w-toggles {

  .list__heading {
    cursor: pointer;
  }
}


// -----------------------------------------------------------------------------
// Stagger Appearance
// -----------------------------------------------------------------------------

@for $i from 1 to 20 {
  .list__item:nth-child(#{$i}) { transition-delay: $i * 0.015s; }
}


// -----------------------------------------------------------------------------
// In the Field Project Meta Information
// -----------------------------------------------------------------------------

.list--itf {

  @include mq(small) {
    max-width: 400px;
    columns: 2;
    column-gap: $spacing-xs;
  }
}

// -----------------------------------------------------------------------------
// Events, spacer before external links
// -----------------------------------------------------------------------------

.list__item-divider {
  margin-top: $spacing-xs;
}
