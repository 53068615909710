@use 'sass:math';

// =============================================================================
// Partner Layout
// =============================================================================

.l-partner {
  display: flex;
  margin: $spacing-m 0;
  flex-wrap: wrap;

  @include mq($until: medium) {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
}

.l-partner__main {
  width: 100%;

  @include mq(partner2col) {
    padding-right: $spacing-xs;
    width: 70%;
  }
}

.l-partner__aside {
  display: none;

  @include mq(partner2col) {
    width: 30%;
    display: block;
  }
}

.l-partner__header {
  width: 100%;

  @include mq($until: partner2col) {
    text-align: center;
  }
}

.l-partner__mobile-details-toggle {
  text-align: center;
  margin-top: $spacing-xxs;

  &:before {
    content: "More Info";
    @include eyebrow-l;
    color: $color-text;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
  }

  &:after {
    content: "";
    display: inline-block;
    height: 16px;
    width: 16px;
    background-image: url('../images/icon-select-arrow-down.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 14px 12px;
    vertical-align: middle;
    margin-left: 4px;
    margin-top: 1px;
  }

  &.is-toggled {

    &:after {
      transform: rotate(180deg);
    }
  }

  @include mq(partner2col) {
    display: none;
  }
}

.l-partner__mobile-details {
  border-top: 1px solid $color-border-2;
  margin-top: $spacing-xxs;

  @include mq(partner2col) {
    display: none;
  }

  @include mq(small) {
    .check-list__items {
      columns: 2;
    }
  }

  @include mq(medium) {
    .check-list__items {
      columns: 3;
    }
  }

  &.is-hidden {
    display: none;
  }
}

.l-partner__bio {
  margin-top: $spacing-xs;
  max-width: 720px;
  
  @include mq($until: partner2col) {
    margin-left: auto;
    margin-right: auto;
  }
}

.l-partner__projects {
  margin-top: $spacing-s;

  @include mq($until: partner2col) {
    padding-top: $spacing-xs;
    margin-top: $spacing-xs;
    border-top: 1px solid $color-border-2;
  }
}

.l-partner__projects-grid {
  margin-top: $spacing-xs;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;

  .card-b,
  .card-b__inner {
    height: 100%;
  }
}

.l-partner__projects-block {
  
  @include mq($until: medium) {
    width: 100%;
    text-align: center;

    .card-b {
      width: 100%;
      max-width: 450px;
      text-align: left;
    }

    &:nth-of-type(n+2) {
      margin-top: $gutter-partners;
    }
  }

  @include mq(medium) {
    width: calc(50% - #{math.div($gutter-partners, 2)});

    &:nth-of-type(n+3) {
      margin-top: $gutter-partners;
    }

    &:nth-of-type(even) {
      margin-left: $gutter-partners;
    }
  }
}

.l-partner__footer {
  margin-top: $spacing-m;
  padding-top: $spacing-xs;
  border-top: 1px solid $color-border-2;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.l-partner__footer-btns {
  width: 70%;

  @include mq($until: medium) {
    width: 100%;
  }
}

.l-partner__footer-report {
  width: 30%;
  text-align: right;

  @include mq($until: medium) {
    width: 100%;
    text-align: left;
    margin-top: 20px;
  }
}

.l-partner__meta {
  margin-top: $spacing-xxs;
}

.l-partner__expertise {
  margin-top: $spacing-xs;
  padding-top: $spacing-xs;
  border-top: 1px solid $color-border-2;

  @include mq($until: partner2col) {
    margin-top: $spacing-xxs;
    padding-top: $spacing-xs;
    padding-bottom: $spacing-xxs;
  }
}
