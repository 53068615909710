// =============================================================================
// Page Intro
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.l-page-intro {
  margin-top: $spacing-s;

  @include mq(medium) {
    margin-top: $spacing-m;

    .blurb {
      width: 80%;
    }
  }

  @include mq(large) {
    
    .blurb {
      width: 75%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @include mq(xxlarge) {
    margin-top: $spacing-l;
  }
}

.l-page-intro__illustration {
  max-width: 600px;
  width: 75%;
  margin: 0 auto $spacing-xxs;
  position: relative;
  z-index: 1;
}

.l-page-intro__illustration-background {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  z-index: -1;
  opacity: 0;
  transform: translateY(20px) scale(0.98);
  animation: illustrationBackgroundAppear 1s $ease-out-expo 0.5s forwards;
}

.l-page-intro__hero-img {
  @include aspect-ratio(1.5,1);
  width: 100%;

  > img {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    max-height: 100%;
    width: auto;
  }
}


// -----------------------------------------------------------------------------
// Contained in Modal
// -----------------------------------------------------------------------------

.modal {

  .l-page-intro {
    margin-top: 0;
  }
}


// -----------------------------------------------------------------------------
// Adjacent Spacing
// -----------------------------------------------------------------------------

.l-page-intro {

  // Whenever the hero image is following another element, add spacing
  * + .l-page-intro__hero-img {
    margin-top: $spacing-xs;
  }
}


// -----------------------------------------------------------------------------
// Spacing Modifiers
// -----------------------------------------------------------------------------

.l-page-intro--space-below {
  margin-bottom: $spacing-m;

  @include mq(large) {
    margin-bottom: $spacing-l;
  }
}

.l-page-intro--less-spacing {
  margin-top: $spacing-s;
}


// -----------------------------------------------------------------------------
// Search
// -----------------------------------------------------------------------------

.l-page-intro--search {
  margin-bottom: $spacing-s;

  .blurb {
    text-align: left !important;
    margin-left: 0 !important;
  }
}

// -----------------------------------------------------------------------------
// Animations
// -----------------------------------------------------------------------------

@keyframes illustrationBackgroundAppear {
  from { opacity: 0; transform: translateY(10px) scale(0.98); }
  to { opacity: 1; transform: translateY(0px) scale(1); }
}