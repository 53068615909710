//===============================================================
// Card
//===============================================================
/*
  Adds some universal card styling. Apply this to the outer
  element only.
*/

@mixin card-outer($border: false, $hoverScale: true, $shadow: true) {
  position: relative;
  transition: transform $ease-out-expo 0.5s;
  will-change: transform;
  transform: translate3d(0, 0, 0);

  @if $border == true {
    border: 1px solid $color-border-2;
  }

  @include mq(medium) {
    &:before {
      bottom: 16px;
    }

    &:hover {
      @if $shadow == true and $hoverScale {
        transform: translateY(-16px) translate3d(0, 0, 0) scale(1.05, 1.05);

        // Prevent jitter
        &:after {
          position: absolute;
          content: "";
          width: 100%;
          top: 0;
          left: 0;
          bottom: -16px;
          z-index: -1;
        }

        &:before {
          transform: scale(1.05, 1.05) translate3d(0, 0, 0);
          opacity: 1;
          box-shadow: $shadow-l;
          bottom: 16px;
        }
      }
    }
  }

  @if $shadow == true {
    &:before {
      will-change: transform;
      content: "";
      z-index: 0;
      position: absolute;
      top: 20px;
      left: 20px;
      right: 20px;
      bottom: 0;
      box-shadow: none;
      opacity: 0;
      transform: translate3d(0, 0, 0);
      transition: transform $ease-out-expo 0.5s, opacity $ease-out-expo 0.5s,
        box-shadow $ease-out-expo 0.5s bottom $ease-out-expo 0.5s;
    }
  }
}

@mixin card-inner($borderRadius: 4px, $hoverScale: true) {
  position: relative;
  z-index: 2;
  border-radius: $borderRadius;
  will-change: transform;
  transform: translate3d(0, 0, 0);

  &:before {
    // will-change: transform;
    // transform: translate3d(0,0,0);
    content: "";
    background: $color-white;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    transition: transform $ease-out-expo 0.5s;
    border-radius: $borderRadius;
  }

  @if $hoverScale {
    @include mq(medium) {
      &:hover {
        &:before {
          // transform: scale(1.05, 1.05) translate3d(0, 0, 0);
        }
      }
    }
  }
}
