// =============================================================================
// Code Block
// =============================================================================
//
//

// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.l-post pre[class*="language-"],
.styleguide__section pre[class*="language-"] {
  background-color: $color-grey-4;
  padding: $spacing-xxs;
  overflow: auto;

  code {
    font-size: 13px;
    line-height: 2;

    @include mq(large) {
      font-size: 14px;
    }

    @include mq(xxlarge) {
      font-size: 15px;
    }
  }

  .token.tag, .token.selector, .token.keyword { color: $color-red; }
  .token.attr-value, .token.string, .token.atrule, .token.function { color: $color-blue-electric; }
  .token.comment, .token.punctuation { color: $color-grey-1; }
  .token.attr-name, .token.variable { color: $color-success; }
}
