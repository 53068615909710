// =============================================================================
// Button Sets
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.btn-set {
  display: flex;
}

.btn-set__btn {
  flex-grow: 1;

  > .btn {
    width: 100%;
    text-align: center;
  }

  & + & {
    margin-left: $spacing-xxxs;
  }
}

// -----------------------------------------------------------------------------
// 2-Up
// -----------------------------------------------------------------------------

.btn-set--2-up {
  max-width: 380px;
}