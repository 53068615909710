// =============================================================================
// Modal Window
// =============================================================================


// -----------------------------------------------------------------------------
// Default
// -----------------------------------------------------------------------------

.modal {
  width:  100%;
  height: 100vh;
  position: absolute;
  z-index: $z-index-modal;
  background: rgba($color-grey-1, 0.3);
  display: flex;
  align-content: center;
  align-items: flex-start;
  justify-content: center;
  top: -100vh;
  opacity: 0;
  left: 0;
  transition: opacity 0.5s $ease-out-expo;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  pointer-events: none;
}

.modal__close {
  height: 60px;
  width: 60px;
  position: absolute;
  top: $spacing-s;
  border-radius: 100%;
  z-index: 2;
  background-color: $color-white;
  right: -100vw;
  margin-top: -30px;
  margin-right: -30px;
  box-shadow: $shadow-s;
  border: 1px solid $color-border-3;
  cursor: pointer;
  opacity: 0;
  transform: translateY(40px);
  transition: opacity 0.5s $ease-out-expo, transform 0.5s $ease-out-expo;
  transition-delay: 0.2s;

  &:after,
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    height: 20px;
    width: 2px;
    background-color: $color-black;
    margin-top: -10px;
    margin-left: -1px;
  }

  &:after {
    transform: rotate(-45deg);
  }

  &:before {
    transform: rotate(45deg);
  }
}

.modal__overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
}

.modal__window {
  background-color: $color-white;
  top: $spacing-m;
  overflow: hidden;
  border-radius: 10px;
  padding-top: $spacing-s;
  margin: $spacing-s 0 $spacing-xl;
  z-index: 1;
  opacity: 0;
  transform: translateY(70px);
  transition: opacity 0.5s $ease-out-expo, transform 0.5s $ease-out-expo;
  transition-delay: 0.1s;

  @include mq(medium) {
    width: 90vw;
  }
}

.modal__header {
  text-align: center;
}

.modal__success,
.modal__error {
  display: none;
  text-align: center;

  &.is-visible {
    display: block;
  }
}


// -----------------------------------------------------------------------------
// States
// -----------------------------------------------------------------------------

.modal.is-visible {
  position: fixed;
  top: 0;
  opacity: 1;
  pointer-events: initial;

  .modal__window,
  .modal__close {
    opacity: 1;
    transform: none;
  }

  .modal__close {
    position: fixed;
    right: 12vw;
    
    @include mq(small) {
      right: 10vw;
    }

    @include mq(medium) {
      right: 5vw;
    }
  }
}


// -----------------------------------------------------------------------------
// Modifiers
// -----------------------------------------------------------------------------

.modal--hire-partner {

  .modal__window {
    position: relative;
    top: 20px;
    overflow: visible;
    width: 90vw;
    margin-top: 0;
    padding: 60px 30px;

    @include mq(medium) {
      top: 100px;
      max-width: 960px;
      margin-top: 80px;
      padding: $spacing-m $spacing-s;
    }
  }

  .modal__close {
    right: 15px !important;
    top: 15px;
    position: absolute !important;

    @include mq(medium) {
      right: 0 !important;
    }
  }
}


// -----------------------------------------------------------------------------
// Forms in Modals
// -----------------------------------------------------------------------------

.modal__window form {

  &.is-hidden {
    display: none;
  }

  &.is-submitting {
    opacity: 0.5;
  }
}
