// =============================================================================
// Homepage Feature Slide — Developers
// =============================================================================
/*
   Styling for the "Designed for Developers" slide of the Homepage feature slideshow.
*/


.fs-developers {
  @include aspect-ratio(1.5,1);
  background-color: $color-black;
  border-radius: 8px;
  position: relative;

  // Shadow
  &:after {
    content: "";
    position: absolute;
    top: $spacing-xs;
    bottom: 0;
    right: $spacing-xs;
    left: $spacing-xs;
    box-shadow: $shadow-l;
    z-index: -1;
  }
}

.fs-developers__content {
  position: absolute;
  top: 0;
  bottom: 0;
  padding-top: $spacing-xxs;
  padding-bottom: $spacing-xxs;
  right: $spacing-xxs;
  left: $spacing-xxs;
  color: $color-white;
  z-index: 1;
  text-align: left;
  overflow: hidden;

  // Gradient Top
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, $color-black 0%, rgba($color-black, 0) 100%);
    height: 50px; 
    z-index: 1;
  }

  // Gradient Bottom
  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba($color-black, 0) 0%, $color-black 100%);
    height: 50px; 
  }
}

.fs-developers__code-block {
  display: block;
  transition: transform 2s $ease-in-out-quad;
  transition-delay: 0.5s;
}

///////////////////////////////////////////////////////
///// Actual Formatted / Styled Code
///////////////////////////////////////////////////////

.fs-developers__code-line {
  font-family: $font-mono;
  opacity: 0;
  transform: translateY(10px);
  display: block;
  transition: opacity 1s $ease-out-expo, transform 1s $ease-out-expo;
  font-size: 1.2vw;

  &:nth-child(n+2) {
    margin: 8px 0;
  }

  @include mq(medium) {
    font-size: 0.85vw;
  }
  
  .comment {
    color: $color-grey-1;
  }

  .tag {
    color: $color-red;
  }

  .punctuation {
    color: $color-white;
  }

  .attr-name {
    color: $color-blue;
  }

  .attr-value,
  .string {
    color: $color-success;
  }

  .number,
  .boolean {
    color: $color-warning;
  }

  .operator {
    color: $color-blue;
  }
}

.js-feature-slideshow-slide {

  &.is-selected {

    .fs-developers__code-block {
      transform: translateY(-48%);
    }

    .fs-developers__code-line {
      transform: none;
      opacity: 1;
    }
  }
}
